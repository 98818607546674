import { Outlet } from 'react-router-dom';
import './Container.scss';

function DrawerLayout() {
  return (
    <div id="main__container">
      <Outlet />
    </div>
  );
}

export default DrawerLayout;
