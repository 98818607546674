import { checkAndUpdateNextStepsInFormModule, checkAndUpdateNextStepsInFormModuleV2 } from '../../../../compilerDecompiler/utils/replaceNextStepId';

export const changeNextStepToApprove = (module, deletedSteps) => {
  // Create a replacement map where each deleted step maps to 'approve'
  const replacementMap = deletedSteps.reduce((map, step) => ({
    ...map,
    [step]: 'auto_approved',
  }), {});

  if (module.type === 'dynamicForm') {
    const updatedModule = checkAndUpdateNextStepsInFormModule(module, replacementMap);
    return updatedModule;
  } if (module.type === 'dynamicFormV2') {
    const updatedModule = checkAndUpdateNextStepsInFormModuleV2(module, replacementMap);
    return updatedModule;
  } if (replacementMap[module.nextStep]) {
    const updatedModule = { ...module, nextStep: 'auto_approved' };
    return updatedModule;
  }

  return module;
};
const replaceDeletedStepsWithApprove = (workflow, deletedSteps) => {
  const errorData = [];
  if (deletedSteps && deletedSteps.length > 0) {
    errorData.push({
      errorCode: 'deletedNextSteps', // Use an appropriate code here
      errorDetails: deletedSteps.map((step) => ({
        description: `Changed <<${step}>> to <<auto_approved>>`,
      })),
    });
    const updatedModules = workflow.modules.map(
      (module) => changeNextStepToApprove(module, deletedSteps),
    );
    const updatedConditions = Object.keys(workflow.conditions).reduce((acc, key) => {
      const condition = workflow.conditions[key];
      const newCondition = { ...condition };
      deletedSteps.forEach((step) => {
        if (newCondition.if_true === step) {
          newCondition.if_true = 'auto_approved';
        }
        if (newCondition.if_false === step) {
          newCondition.if_false = 'auto_approved';
        }
      });
      acc[key] = newCondition;
      return acc;
    }, {});
    const updatedWorkflow = {
      ...workflow,
      modules: updatedModules,
      conditions: updatedConditions,
    };
    return { workflow: updatedWorkflow, errorData };
  }
  return { workflow, errorData };
};

export default replaceDeletedStepsWithApprove;
