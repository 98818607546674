import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import '../../containers/Container.scss';
import { closeExistingSDK } from '../utils';
import sampleImages from '../../constants/sampleImages';
import { replaceSuperModuleIdsWithActualIds } from '../../containers/uiConfigOperations';
import generateUniqueID, { getNewTransactionId } from '../../utils/generateUniqueId';
import { updateSdkLaunchId } from '../../reducers/builderSDK';

function DisplaySDKScreen(props) {
  const {
    versionedModules,
    selectedWorkflow,
    screenToTeleport,
    uiConfig,
    textConfig,
    moduleToTeleport,
    jwtToken,
    customStyleSheets,
    reloadSdk,
    closeSdk,
    selectedLanguage,
    defaultTextConfig,
    selectedElement,
    workflowId,
    isScreenEditsTab,
    isForm,
  } = props;

  const dispatch = useDispatch();
  const callback = () => {
  };

  useEffect(() => {
    let uniqueLaunchId = '';
    const transactionId = getNewTransactionId();
    // eslint-disable-next-line no-undef
    const hyperKycConfig = new HyperKycConfig(
      jwtToken,
      workflowId,
      transactionId,
      false,
      null,
      true,
      true,
    );
    // eslint-disable-next-line no-undef
    const styles = {
      left: '18%',
      top: '48px',
      width: '54.9%',
      height: '100%',
    };
    const discardPreviousStyles = {
      '.hv-modal-content': {
      },
    };
    hyperKycConfig.setBuilderBrandingStyles(styles);

    if (!closeSdk && Object.keys(selectedWorkflow || {}).length > 0) {
      hyperKycConfig.setInputs({
        builderSampleFace: sampleImages.face,
        builderSampledoc: sampleImages.doc,
      });

      hyperKycConfig.setUserWorkflow(selectedWorkflow);

      if (uiConfig && Object.keys(uiConfig).length > 0) {
        const compiledModuleUiConfig =
      replaceSuperModuleIdsWithActualIds(selectedWorkflow?.modules || [], uiConfig);
        hyperKycConfig.setUiConfig(compiledModuleUiConfig);
        window.loadCustomUICss({ uiConfig: compiledModuleUiConfig });
      }

      if (textConfig
        && Object.keys(textConfig).length > 0) hyperKycConfig.setTextConfig(textConfig);
      else hyperKycConfig.setTextConfig(defaultTextConfig);

      hyperKycConfig.enableEditBranding(true);
      hyperKycConfig.setLanguageUsed(selectedLanguage);
      if (screenToTeleport === 'retake' && selectedElement?.type === 'errorDropdown') hyperKycConfig.SetTeleportScreen(moduleToTeleport, screenToTeleport, selectedElement?.textConfig);
      else hyperKycConfig.SetTeleportScreen(moduleToTeleport, screenToTeleport);

      if (customStyleSheets?.length) {
        customStyleSheets.forEach((customStyleSheet) => {
          hyperKycConfig.setCustomFontStylesheet(customStyleSheet);
        });
      }
      if (selectedWorkflow.properties?.enableServerSideResume
        || selectedWorkflow.properties?.serverSideResume?.enable) {
        // eslint-disable-next-line no-undef
        const uniqueID = HyperKYCModule.createUniqueId();
        hyperKycConfig.setUniqueId(uniqueID);
      }
      // eslint-disable-next-line no-undef
      HyperKYCModule.launch(hyperKycConfig, callback);
      uniqueLaunchId = generateUniqueID();
      dispatch(updateSdkLaunchId({
        id: uniqueLaunchId,
      }));
    }

    return () => {
      hyperKycConfig.setCustomCSSFromBuilder(discardPreviousStyles);
      closeExistingSDK({ launchId: uniqueLaunchId, closeAllSDKs: false });
    };
  }, [
    screenToTeleport,
    moduleToTeleport,
    textConfig,
    reloadSdk,
    closeSdk,
    selectedWorkflow,
    versionedModules,
    uiConfig,
    customStyleSheets,
    selectedLanguage,
    defaultTextConfig,
    selectedElement,
    jwtToken,
    workflowId,
  ]);
  useEffect(() => {
    const transactionId = getNewTransactionId();
    // eslint-disable-next-line no-undef
    const hyperKycConfig = new HyperKycConfig(
      jwtToken,
      workflowId,
      transactionId,
      false,
      null,
      true,
      true,
    );
    if (isForm) {
      const stylesWithoutTransition = {
        '.hv-modal-content': {
          transform: 'translate(-87%,-50%) !important',
        },
      };
      if (isScreenEditsTab) {
        hyperKycConfig.setCustomCSSFromBuilder(stylesWithoutTransition);
      }
    } else {
      const discardPreviousStyles = {
        '.hv-modal-content': {
        },
      };
      hyperKycConfig.setCustomCSSFromBuilder(discardPreviousStyles);
    }
  }, [customStyleSheets]);
  useEffect(() => {
    const transactionId = getNewTransactionId();
    // eslint-disable-next-line no-undef
    const hyperKycConfig = new HyperKycConfig(
      jwtToken,
      workflowId,
      transactionId,
      false,
      null,
      true,
      true,
    );
    if (isForm) {
      const stylesForTransition = {
        '.hv-modal-content': {
          'animation-duration': '0.3s',
          'animation-fill-mode': 'forwards',
          animation: 'slide 0.21s ease-in-out forwards',
          'animation-delay': '0.1s',
        },
      };

      const keyframes = {
        '@keyframes slide': {
          from: {
            opacity: '1',
            transform: 'translate(-50%,-50%)',
          },
          to: {
            opacity: '1',
            transform: 'translate(-87%,-50%)',
          },
        },
      };

      const styles2 = {
        '.hv-modal-content': {
        },
      };

      if (isScreenEditsTab) {
        const combinedStyles = { ...stylesForTransition, ...keyframes };
        hyperKycConfig.setCustomCSSFromBuilder(combinedStyles);
      } else {
        hyperKycConfig.setCustomCSSFromBuilder(styles2);
      }
    }
  }, [isScreenEditsTab]);
  return null;
}

DisplaySDKScreen.propTypes = {
  versionedModules: PropTypes.object.isRequired,
  selectedWorkflow: PropTypes.object.isRequired,
  screenToTeleport: PropTypes.string.isRequired,
  uiConfig: PropTypes.object.isRequired,
  textConfig: PropTypes.object.isRequired,
  moduleToTeleport: PropTypes.string.isRequired,
  jwtToken: PropTypes.string.isRequired,
  customStyleSheets: PropTypes.arrayOf(PropTypes.string).isRequired,
  reloadSdk: PropTypes.bool.isRequired,
  closeSdk: PropTypes.bool.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
  defaultTextConfig: PropTypes.object.isRequired,
  selectedElement: PropTypes.object.isRequired,
  workflowId: PropTypes.string.isRequired,
  isScreenEditsTab: PropTypes.bool.isRequired,
  isForm: PropTypes.bool.isRequired,
};

export default DisplaySDKScreen;
