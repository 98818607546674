import * as Sentry from '@sentry/react';

import logBatchedEvents from '../events/logBatchedEvents';
import { clearEventQueue } from '../reducers/events';
import { fetchAppIdToken } from '../reducers/user';
import { errorCodes } from '../utils/error';

const MAX_BATCH_SIZE = process.env.REACT_APP_EVENT_BATCH_SIZE || 5;
const BATCH_INTERVAL = process.env.REACT_APP_EVENT_BATCH_MAX_INTERVAL || 30000;

const flushEvents = async (store) => {
  const state = store.getState();
  const { eventQueue } = state.events;
  const { appIdKeyToken } = state.user;

  if (eventQueue.length === 0) return;

  try {
    // Push all current events to the backend
    await logBatchedEvents(eventQueue, appIdKeyToken);
    store.dispatch(clearEventQueue()); // Clear the queue after a successful push.
  } catch (error) {
    if (error.errorCode === errorCodes.invalidEventStructure) {
      store.dispatch(clearEventQueue());
      return;
    }
    if (error.errorCode === errorCodes.invalidDemoToken) {
      store.dispatch(fetchAppIdToken());
      return;
    }
    Sentry.captureException(error);
  }
};

const eventBatchingMiddleware = (store) => {
  let timeoutId;
  return (next) => (action) => {
    next(action);
    if (action.type !== 'events/pushEvent') return;

    const state = store.getState();
    const { eventQueue } = state.events;

    // Push immediately if MAX_BATCH_SIZE is reached.
    if (eventQueue.length >= MAX_BATCH_SIZE) {
      clearTimeout(timeoutId); // Clear any existing timer.
      flushEvents(store);
    } else if (!timeoutId) {
      // Set up a timer if not already running.
      timeoutId = setTimeout(() => {
        flushEvents(store);
        timeoutId = null; // Clear the timer reference after execution.
      }, BATCH_INTERVAL);
    }
  };
};

export default eventBatchingMiddleware;
