import Joi from 'joi-browser';
import { cloneDeep, set, unset } from 'lodash';
import {
  setModulePropertyInWorkflow,
  setPreviousStepInWorkflow,
  unsetModulePropertyInWorkflow,
  unsetPreviousStepInWorkflow,
  updateCountriesInWorkflow,
  updateDocumentsInWorkflow,
} from '../components/ViewWorkflow/InputsToModule/utils/updateWorkflow';
import {
  setValueInWorkflow,
  unSetValueInWorkflow,
} from '../components/ViewWorkflow/ConfigurationPanel/helper';
import {
  addNewNodeInWorkflow,
  deleteNodeFromWorkflow,
  updateNodeName,
} from './operations/genericOperations';
import {
  editIfTrueIfFalseReason,
  swapBranchesInCondition,
  updateConditionRule,
  editResumeFrom,
} from './operations/conditionOperations';
import {
  addFormComponentInV2,
  addFormComponentViaClipboardInFormV2,
  cloneFormComponentInV2,
  deleteFormComponentInV2,
  dragComponentInFormV2,
  updateFormV2PropertyInSuperModule,
  updateTagNameOfComponentInFormV2,
} from './operations/formV2Operations';
import {
  addComponentWrapper,
  copyComponentWrapper,
  deleteComponentWrapper,
  dragComponentInsideSuperModuleWrapper,
  dragComponentWrapper,
  updateComponentWrapper,
  updateFormProperty,
  updateFormPropertyInSuperModule,
  updateComponentSubtypeWrapper,
  deleteNodeWrapper,
} from './operations/formOperations';
import setLottie from './operations/fileUpload';
import { pasteInDynamicForm } from '../containers/FormModule/formOperations';
import setModuleBuilderConfigurations, { updateLastModifiedTimestamp } from './operations/moduleBuilderOperations';
import { inputStructureSchema, inputSchemaForModuleConfig } from '../schemas/moduleBuilderSchemas';
import updateWorkflowDetails from './operations/updateWorkflowDetails';
import updateModuleVersionInWorkflow from '../components/ViewWorkflow/ModuleVersionUpdate/utils/updateModuleVersionInWorkflow';
import { errorCodes } from '../utils/error';
import DefaultError from '../error/defaultError';
import { replaceActualModuleIdsWithSuperModuleIds } from '../containers/uiConfigOperations';
import {
  updateFontWeight,
  updateFont,
  updateColor,
  updateLogoInUiConfig,
  updateIconInUiConfig,
  updateColorSchemeInUiConfig,
  updateCustomFontStylesheetUrlsInUiConfig,
} from './uiConfigOperations';
import changeEndState from './operations/changeEndStates';

const processErrorData = (
  errorData,
  errorCode,
  defaultMessage,
  defaultOriginalError,
  extraDebuggingData,
) => {
  // TODO: Fallback can be removed later if bnot required
  if (!errorData) {
    return new DefaultError({
      code: errorCode,
      message: defaultMessage,
      originalError: defaultOriginalError,
      debugInfo: {
        ...extraDebuggingData,
      },
    });
  }
  const modifiedErrorData = errorData;
  if (!modifiedErrorData.code) modifiedErrorData.code = errorCode;
  if (!modifiedErrorData.message) {
    modifiedErrorData.message = defaultMessage;
  }
  modifiedErrorData.debugInfo = {
    ...modifiedErrorData.debugInfo,
    ...extraDebuggingData,
  };
  return modifiedErrorData;
};

export const workflowOperations = {
  DELETE_CONDITION: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      // TODO: Can this parentId be removed?
      parentId: Joi.string().required(),
    }),
    // Controller
    operatingFn: (workflow, actionData) => {
      const { targetNodeId, parentId } = actionData;
      const { workflow: operatedWorkflow, success, errorData } =
        deleteNodeFromWorkflow(targetNodeId, parentId, workflow);
      if (success) return { workflow: operatedWorkflow, success };
      const processedErrorData = processErrorData(
        errorData,
        errorCodes.deleteConditionFailed,
        'Delete condition failed',
        new Error('Delete condition failed'),
        {
          failedOperation: 'DELETE_CONDITION',
          nodeToDelete: targetNodeId,
          parentId,
        },
      );

      return { workflow, success: false, errorData: processedErrorData };
    },
  },
  SET_WORKFLOW_IN_STATE: {
    actionDataSchema: Joi.object({
      workflow: Joi.object().required(),
    }),
    operatingFn: (workflow, actionData, uiConfig) => {
      const { workflow: operatedWorkflow } = actionData;
      return { workflow: operatedWorkflow, uiConfig, success: true };
    },
  },
  DELETE_MODULE: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      // TODO: Can this parentId be removed?
      parentId: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData, uiConfig) => {
      const { targetNodeId, parentId } = actionData;
      const {
        workflow: operatedWorkflow, success, errorData, uiConfig: editedUiConfig,
      } =
        deleteNodeWrapper(targetNodeId, parentId, workflow, uiConfig);
      if (success) return { workflow: operatedWorkflow, uiConfig: editedUiConfig, success };
      const processedErrorData = processErrorData(
        errorData,
        errorCodes.deleteModuleFailed,
        'Delete module failed',
        new Error('Delete module failed'),
        {
          failedOperation: 'DELETE_MODULE',
          nodeToDelete: targetNodeId,
          parentId,
        },
      );
      return {
        workflow, uiConfig, success: false, errorData: processedErrorData,
      };
    },
  },
  ADD_CONDITION: {
    actionDataSchema: Joi.object({
      addNodeBetween: Joi.object({
        parent: Joi.string().required(),
        child: Joi.string().required(),
      }),
      // TODO: Remove dependency on the below two properties.
      nodes: Joi.array(),
      localOrderOfNodes: Joi.array(),
    }),
    operatingFn: (workflow, actionData) => {
      const { addNodeBetween, nodes, localOrderOfNodes } = actionData;
      const {
        workflow: operatedWorkflow,
        newNodeId,
        success,
        errorData,
      } = addNewNodeInWorkflow({
        addNodeBetween, nodes, workflowConfig: workflow, localOrderOfNodes, type: 'condition',
      });
      if (success) return { workflow: operatedWorkflow, extraData: { newNodeId }, success };
      const processedErrorData = processErrorData(
        errorData,
        errorCodes.addNewConditionFailed,
        'Add new condition failed',
        new Error('Add new condition failed'),
        {
          failedOperation: 'ADD_CONDITION',
          addNodeBetween,
        },
      );
      return { workflow, success: false, errorData: processedErrorData };
    },
    maskingConfig: {
      actionDataPathsToMask: ['localOrderOfNodes', 'nodes'],
      responseDataPathsToMask: [],
    },
  },
  ADD_MODULE: {
    actionDataSchema: Joi.object({
      node: Joi.object(),
      addNodeBetween: Joi.object({
        parent: Joi.string().required(),
        child: Joi.string().required(),
      }),
      countryDocMapping: Joi.object(),
      defaultFormSections: Joi.array(),
      // TODO: Remove dependency on the below two properties.
      nodes: Joi.array(),
      localOrderOfNodes: Joi.array(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        node, countryDocMapping,
        defaultFormSections, addNodeBetween,
        nodes, localOrderOfNodes,
      } = actionData;
      const {
        workflow: operatedWorkflow,
        highLevelUiConfig,
        newModule,
        success,
        highLevelTextConfig,
        errorData,
      } =
        addNewNodeInWorkflow({
          node,
          addNodeBetween,
          nodes,
          workflowConfig: workflow,
          countryDocMapping,
          localOrderOfNodes,
          defaultFormSections,
          type: 'module',
        });
      if (success) {
        return {
          workflow: operatedWorkflow,
          extraData: { highLevelUiConfig, newModule, highLevelTextConfig },
          success,
        };
      }
      const processedErrorData = processErrorData(
        errorData,
        errorCodes.addNewModuleFailed,
        'Add new module failed',
        new Error('Add new module failed'),
        {
          failedOperation: 'ADD_MODULE',
          addNodeBetween,
          newNode: node,
        },
      );
      return { workflow, success: false, errorData: processedErrorData };
    },
    maskingConfig: {
      actionDataPathsToMask: ['localOrderOfNodes', 'countryDocMapping', 'defaultFormSections', 'nodes'],
      responseDataPathsToMask: ['extraData.highLevelUiConfig', 'extraData.highLevelTextConfig'],
    },
  },
  SET_WORKFLOW_ATTRIBUTE: {
    actionDataSchema: Joi.object({
      path: Joi.string().required(),
      value: Joi.any().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        path, value,
      } = actionData;
      const clonedWorkflow = cloneDeep(workflow);
      set(clonedWorkflow, path, value);
      return { workflow: clonedWorkflow, success: true };
    },
  },
  UNSET_WORKFLOW_ATTRIBUTE: {
    actionDataSchema: Joi.object({
      path: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        path,
      } = actionData;
      const clonedWorkflow = cloneDeep(workflow);
      unset(clonedWorkflow, path);
      return { workflow: clonedWorkflow, success: true };
    },
  },
  SET_MODULE_PROPERTY: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      workflowKey: Joi.string().required(),
      value: Joi.any().required(),
      moduleConfig: Joi.object().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId,
        workflowKey,
        value,
        moduleConfig,
      } = actionData;
      const updatedWorkflow = setModulePropertyInWorkflow(
        workflow,
        targetNodeId,
        workflowKey,
        value,
        moduleConfig,
      );
      return { workflow: updatedWorkflow, success: true };
    },
  },
  UNSET_MODULE_PROPERTY: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      workflowKey: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId,
        workflowKey,
      } = actionData;
      const updatedWorkflow = unsetModulePropertyInWorkflow(
        workflow,
        targetNodeId,
        workflowKey,
      );
      return { workflow: updatedWorkflow, success: true };
    },
  },
  SWAP_CONDITION_BRANCHES: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId,
      } = actionData;
      const updatedWorkflow = swapBranchesInCondition(
        targetNodeId,
        workflow,
      );
      return { workflow: updatedWorkflow, success: true };
    },
  },
  SET_CONDITION_REASON: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      branch: Joi.string().valid('if_true', 'if_false').required(),
      reason: Joi.string().allow('').required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId, branch, reason,
      } = actionData;
      const updatedWorkflow = editIfTrueIfFalseReason(
        workflow,
        targetNodeId,
        branch,
        reason,
      );
      return { workflow: updatedWorkflow, success: true };
    },
  },
  // TODO handling the failure case in UI
  SET_CONDITION_RESUME_FROM: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      branch: Joi.string().valid('if_true', 'if_false').required(),
      value: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId, branch, value,
      } = actionData;
      const { workflow: updatedWorkflow, success, errorData } = editResumeFrom(
        workflow,
        targetNodeId,
        branch,
        value,
      );
      if (success) return { workflow: updatedWorkflow, success };
      const processedErrorData = processErrorData(
        errorData,
        errorCodes.updateResumeFromFailed,
        'Update resume from in condition failed',
        new Error('Update resume from in condition failed'),
        {
          failedOperation: 'SET_CONDITION_RESUME_FROM',
          targetNodeId,
          branch,
          value,
        },
      );
      return { workflow: updatedWorkflow, success: false, errorData: processedErrorData };
    },
  },
  SET_CONDITION_RULE: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      rule: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId, rule,
      } = actionData;
      const updatedWorkflow = updateConditionRule(
        workflow,
        targetNodeId,
        rule,
      );
      return { workflow: updatedWorkflow, success: true };
    },
  },
  SET_NODE_NAME: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      name: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId, name,
      } = actionData;
      const { workflow: updatedWorkflow, success, errorData } = updateNodeName(
        workflow,
        targetNodeId,
        name,
      );
      if (success) return { workflow: updatedWorkflow, success };
      const processedErrorData = processErrorData(
        errorData,
        errorCodes.updateNodeNameFailed,
        'Update node name failed',
        new Error('Update node name failed'),
        {
          failedOperation: 'SET_NODE_NAME',
          targetNodeId,
          name,
        },
      );
      return { workflow: updatedWorkflow, success: false, errorData: processedErrorData };
    },
  },
  SET_VALUE_IN_WORKFLOW: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      rootWorkflowKey: Joi.string().required(),
      value: Joi.any().required(),
      moduleConfig: Joi.object().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId, rootWorkflowKey, value, moduleConfig,
      } = actionData;
      const updatedWorkflow = setValueInWorkflow(
        workflow,
        value,
        rootWorkflowKey,
        targetNodeId,
        moduleConfig,
      );
      return { workflow: updatedWorkflow, success: true };
    },
  },
  UNSET_VALUE_IN_WORKFLOW: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      rootWorkflowKey: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId, rootWorkflowKey,
      } = actionData;
      const updatedWorkflow = unSetValueInWorkflow(
        workflow,
        rootWorkflowKey,
        targetNodeId,
      );
      return { workflow: updatedWorkflow, success: true };
    },
  },
  ADD_FORM_COMPONENT_V2: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      targetComponentId: Joi.string().min(1).allow(null),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId, targetComponentId,
      } = actionData;
      const { workflow: updatedWorkflow, success, errorData } = addFormComponentInV2(
        workflow,
        targetNodeId,
        targetComponentId,
      );
      if (success) return { workflow: updatedWorkflow, success };
      const processedErrorData = processErrorData(
        errorData,
        errorCodes.addFormV2ComponentFailed,
        'Failed to add form component',
        new Error('Add form V2 component failed'),
        {
          failedOperation: 'ADD_FORM_COMPONENT_V2',
          targetNodeId,
          targetComponentId,
        },
      );
      return { workflow: updatedWorkflow, success: false, errorData: processedErrorData };
    },
  },
  DELETE_FORM_COMPONENT_V2: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      targetComponentId: Joi.string().min(1).allow(null),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId, targetComponentId,
      } = actionData;
      const { workflow: updatedWorkflow, success, errorData } = deleteFormComponentInV2(
        workflow,
        targetNodeId,
        targetComponentId,
      );
      if (success) return { workflow: updatedWorkflow, success };
      const processedErrorData = processErrorData(
        errorData,
        errorCodes.deleteFormV2ComponentFailed,
        'Failed to delete form component',
        new Error('Delete form V2 component failed'),
        {
          failedOperation: 'DELETE_FORM_COMPONENT_V2',
          targetNodeId,
          targetComponentId,
        },
      );
      return { workflow: updatedWorkflow, success: false, errorData: processedErrorData };
    },
  },
  CLONE_FORM_COMPONENT_V2: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      targetComponentId: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId, targetComponentId,
      } = actionData;
      const { workflow: updatedWorkflow, success, errorData } = cloneFormComponentInV2(
        workflow,
        targetNodeId,
        targetComponentId,
      );
      if (success) return { workflow: updatedWorkflow, success };
      const processedErrorData = processErrorData(
        errorData,
        errorCodes.cloneFormV2ComponentFailed,
        'Failed to clone form component',
        new Error('Clone form V2 component failed'),
        {
          failedOperation: 'CLONE_FORM_COMPONENT_V2',
          targetNodeId,
          targetComponentId,
        },
      );
      return { workflow: updatedWorkflow, success: false, errorData: processedErrorData };
    },
  },
  UPDATE_TAG_IN_FORM_COMPONENT_V2: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      targetComponentId: Joi.string().required(),
      tagName: Joi.string().required(),
      libraryUrl: Joi.string().allow('').required(),
      libraryId: Joi.string().allow('').required(),
      formComponentId: Joi.string().allow('').required(),
      libraryName: Joi.string().allow('').required(),
      componentLabel: Joi.string().allow('').required(),
      styleSheetsUrl: Joi.string().allow('').required(),
      defaultAttributes: Joi.object().pattern(
        Joi.string(),
        Joi.string().allow(''),
      ).required(),
      defaultProperties: Joi.object().pattern(
        Joi.string(),
        Joi.alternatives().try(
          Joi.string().allow(''),
          Joi.number(),
          Joi.valid(null),
        ),
      ).required(),
      defaultStyles: Joi.object().pattern(
        Joi.string(),
        Joi.alternatives().try(
          Joi.string().allow(''),
          Joi.number(),
          Joi.valid(null),
        ),
      ).required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId, targetComponentId, tagName, libraryUrl = '', styleSheetsUrl = '',
        libraryId = '', formComponentId = '',
        defaultProperties = {}, defaultAttributes = {}, defaultStyles = {},
        libraryName = '', componentLabel = '',
      } = actionData;
      const { workflow: updatedWorkflow, success, errorData } = updateTagNameOfComponentInFormV2(
        workflow,
        targetNodeId,
        targetComponentId,
        tagName,
        libraryUrl,
        styleSheetsUrl,
        defaultAttributes,
        defaultProperties,
        defaultStyles,
        libraryId,
        formComponentId,
        libraryName,
        componentLabel,
      );
      if (success) return { workflow: updatedWorkflow, success };
      const processedErrorData = processErrorData(
        errorData,
        errorCodes.updateTagNameOfFormV2ComponentFailed,
        'Failed to update tag name of form component',
        new Error('Update tag name of form component failed'),
        {
          failedOperation: 'UPDATE_TAG_IN_FORM_COMPONENT_V2',
          targetNodeId,
          targetComponentId,
        },
      );
      return { workflow: updatedWorkflow, success: false, errorData: processedErrorData };
    },
  },
  DRAG_FORM_COMPONENT_V2: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      pickComponentId: Joi.string().required(),
      dropComponentId: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId, pickComponentId, dropComponentId,
      } = actionData;
      const { workflow: updatedWorkflow, success, errorData } = dragComponentInFormV2(
        workflow,
        targetNodeId,
        pickComponentId,
        dropComponentId,
      );
      if (success) return { workflow: updatedWorkflow, success };
      const processedErrorData = processErrorData(
        errorData,
        errorCodes.dragFormV2ComponentFailed,
        'Failed to drag form component',
        new Error('Drag form component failed'),
        {
          failedOperation: 'DRAG_FORM_COMPONENT_V2',
          targetNodeId,
          pickComponentId,
          dropComponentId,
        },
      );
      return { workflow: updatedWorkflow, success: false, errorData: processedErrorData };
    },
  },
  ADD_FORM_COMPONENT_V2_VIA_CLIPBOARD: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      htmlString: Joi.string().required(),
      componentConfigsFromContextCopy: Joi.object(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId, htmlString, componentConfigsFromContextCopy,
      } = actionData;
      const {
        workflow: updatedWorkflow, success, errorData,
      } = addFormComponentViaClipboardInFormV2(
        workflow,
        targetNodeId,
        htmlString,
        componentConfigsFromContextCopy,
      );
      if (success) return { workflow: updatedWorkflow, success };
      const processedErrorData = processErrorData(
        errorData,
        errorCodes.addFormV2ComponentViaClipboardFailed,
        'Failed to add form component via clipboard',
        new Error('Add form V2 component via clipboard failed'),
        {
          failedOperation: 'ADD_FORM_COMPONENT_V2_VIA_CLIPBOARD',
          targetNodeId,
          htmlString,
        },
      );
      return { workflow: updatedWorkflow, success: false, errorData: processedErrorData };
    },
  },
  SET_PREVIOUS_STEP: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      previousStep: Joi.string().required().allow(''),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId,
        previousStep,
      } = actionData;
      const updatedWorkflow = setPreviousStepInWorkflow(
        workflow,
        targetNodeId,
        previousStep,
      );
      return { workflow: updatedWorkflow, success: true };
    },
  },
  UNSET_PREVIOUS_STEP: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId,
      } = actionData;
      const updatedWorkflow = unsetPreviousStepInWorkflow(
        workflow,
        targetNodeId,
      );
      return { workflow: updatedWorkflow, success: true };
    },
  },

  DRAG_COMPONENT: {
    actionDataSchema: Joi.object({
      moduleId: Joi.string().required(),
      fromComponentObj: Joi.object({
        fromComponentId: Joi.string().required(),
        section: Joi.string().required(),
      }).required(),
      toComponentObj: Joi.object({
        toComponentId: Joi.string().required(),
        section: Joi.string().required(),
      }).required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        moduleId, fromComponentObj, toComponentObj,
      } = actionData;
      const { workflow: updatedWorkflow, success, toPathArray } = dragComponentWrapper(
        workflow,
        moduleId,
        fromComponentObj,
        toComponentObj,
      );
      return { workflow: updatedWorkflow, success, extraData: { toPathArray } };
    },
  },
  DRAG_COMPONENT_IN_SUPER_MODULE: {
    actionDataSchema: Joi.object({
      moduleId: Joi.string().required(),
      fromComponentId: Joi.string().required(),
      toComponentId: Joi.string().required(),
      rootPath: Joi.string().required(),
      compiledWorkflow: Joi.any(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        moduleId, fromComponentId, toComponentId, rootPath, compiledWorkflow,
      } = actionData;

      const { workflow: updatedWorkflow, success } = dragComponentInsideSuperModuleWrapper(
        compiledWorkflow,
        moduleId,
        fromComponentId,
        toComponentId,
        rootPath,
        workflow,
      );
      return { workflow: updatedWorkflow, success };
    },
    maskingConfig: {
      actionDataPathsToMask: ['compiledWorkflow'],
      responseDataPathsToMask: [],
    },
  },
  ADD_COMPONENT: {
    actionDataSchema: Joi.object({
      moduleId: Joi.string().required(),
      defaultConfig: Joi.object().required(),
      componentId: Joi.string().allow(''),
      rootPath: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        moduleId, defaultConfig, componentId, rootPath,
      } = actionData;

      const {
        operatedWorkflow, success, toPathArray, indexToAddInPathArray,
      } = addComponentWrapper(workflow, moduleId, defaultConfig, rootPath, componentId);
      if (success) {
        return {
          workflow: operatedWorkflow, success, extraData: { toPathArray, indexToAddInPathArray },
        };
      }
      return { workflow, success: false };
    },
  },
  UPDATE_FORM_V2_PROPERTY_IN_SUPER_MODULE: {
    actionDataSchema: Joi.object({
      superModuleId: Joi.string().required(),
      mappingId: Joi.string().required(),
      componentId: Joi.string().required(),
      key: Joi.string().required(),
      value: Joi.any().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        superModuleId,
        mappingId,
        componentId,
        key,
        value,
      } = actionData;
      return updateFormV2PropertyInSuperModule(
        workflow,
        superModuleId,
        mappingId,
        componentId,
        key,
        value,
      );
    },
  },
  UPDATE_FORM_PROPERTY_IN_SUPER_MODULE: {
    actionDataSchema: Joi.object({
      superModuleId: Joi.string().required(),
      mappingId: Joi.string().required(),
      componentId: Joi.string().required(),
      key: Joi.string().required(),
      value: Joi.any().required(),
      isUIProperty: Joi.boolean().required(),
    }),
    operatingFn: (workflow, actionData, uiConfig) => {
      const {
        superModuleId,
        mappingId,
        componentId,
        key,
        value,
        isUIProperty,
      } = actionData;
      return updateFormPropertyInSuperModule({
        workflow,
        uiConfig,
        superModuleId,
        mappingId,
        componentId,
        key,
        value,
        isUIProperty,
      });
    },
  },
  UPDATE_FORM_PROPERTY: {
    actionDataSchema: Joi.object({
      moduleId: Joi.string().required(),
      componentId: Joi.string().required(),
      isUIProperty: Joi.boolean().required(),
      key: Joi.string().required(),
      value: Joi.any().required(),
    }),
    operatingFn: (workflow, actionData, uiConfig) => {
      const {
        key,
        value,
        moduleId,
        componentId,
        isUIProperty,
      } = actionData;
      return updateFormProperty({
        workflow,
        uiConfig,
        key,
        value,
        moduleId,
        componentId,
        isUIProperty,
      });
    },
  },
  UPDATE_COMPONENT: {
    actionDataSchema: Joi.object({
      moduleId: Joi.string().required(),
      newComponent: Joi.object().required(),
      pathArray: Joi.array().required(),
      rootPath: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        moduleId, newComponent, pathArray, rootPath,
      } = actionData;
      const { workflow: updatedWorkflow, success } = updateComponentWrapper(
        workflow,
        moduleId,
        newComponent,
        pathArray,
        rootPath,
      );
      if (success) return { workflow: updatedWorkflow, success };
      return { workflow, success: false };
    },
  },
  UPDATE_COMPONENT_SUBTYPE: {
    actionDataSchema: Joi.object({
      pathArray: Joi.array().required(),
      rootPath: Joi.string().required(),
      moduleId: Joi.string().required(),
      newSubtype: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData, uiConfig) => {
      const {
        pathArray,
        rootPath,
        moduleId,
        newSubtype,
      } = actionData;
      const { updatedWorkflow, updatedUiConfig, success } = updateComponentSubtypeWrapper(
        workflow,
        moduleId,
        pathArray,
        rootPath,
        uiConfig,
        newSubtype,
      );
      if (success) return { workflow: updatedWorkflow, uiConfig: updatedUiConfig, success };
      return { workflow, success: false };
    },
  },
  COPY_COMPONENT: {
    actionDataSchema: Joi.object({
      moduleId: Joi.string().required(),
      componentId: Joi.string().allow(''),
      rootPath: Joi.string().required(),
      formComponentList: Joi.array().required(),
    }),
    operatingFn: (workflow, actionData, uiConfig) => {
      const {
        moduleId, componentId, rootPath, formComponentList,
      } = actionData;

      const {
        workflow: updatedWorkflow, success, originalToClonedComponentIdMap, updatedUiConfig,
      } =
      copyComponentWrapper(
        workflow,
        moduleId,
        rootPath,
        componentId,
        formComponentList,
        uiConfig,
      );
      if (success) {
        return {
          workflow: updatedWorkflow,
          uiConfig: updatedUiConfig,
          success,
          extraData: originalToClonedComponentIdMap,
        };
      }
      return { workflow, success: false };
    },
    maskingConfig: {
      actionDataPathsToMask: ['formComponentList'],
      responseDataPathsToMask: [],
    },
  },

  ADD_FORM_COMPONENT_VIA_CLIPBOARD: {
    actionDataSchema: Joi.object({
      moduleId: Joi.string().required(),
      updatedComponent: Joi.object(),
      rootPath: Joi.string().required(),
      originalToClonedComponentIdMap: Joi.object(),
      copiedUiConfig: Joi.object().allow(null),
    }),
    operatingFn: (workflow, actionData, uiConfig) => {
      const {
        moduleId, updatedComponent, rootPath, originalToClonedComponentIdMap, copiedUiConfig,
      } = actionData;
      const { updatedWorkflow, updatedUiConfig, success } = pasteInDynamicForm(
        workflow,
        moduleId,
        updatedComponent,
        rootPath,
        uiConfig,
        originalToClonedComponentIdMap,
        copiedUiConfig,
      );
      if (success) {
        return {
          workflow: updatedWorkflow,
          uiConfig: updatedUiConfig,
          success,
        };
      }
      return { workflow, success: false };
    },
  },

  DELETE_COMPONENT: {
    actionDataSchema: Joi.object({
      componentId: Joi.string().required(),
      rootPath: Joi.string().required(),
      moduleId: Joi.string().required(),
      selectedComponentPathArray: Joi.array(),
    }),
    operatingFn: (workflow, actionData, uiConfig) => {
      const {
        componentId,
        rootPath,
        moduleId,
        selectedComponentPathArray,
      } = actionData;
      const {
        workflow: updatedWorkflow,
        success, extraData, errorData, updatedUiConfig,
      } = deleteComponentWrapper(
        workflow,
        moduleId,
        rootPath,
        componentId,
        uiConfig,
        selectedComponentPathArray,
      );

      if (success) {
        return {
          workflow: updatedWorkflow,
          success,
          extraData,
          uiConfig: updatedUiConfig,
        };
      }
      return { workflow, success: false, errorData };
    },
  },
  SET_MODULE_BUILDER_PROPERTIES: {
    actionDataSchema: Joi.object({
      path: Joi.string().required(),
      builderProperties: Joi.array().items(
        Joi.object({
          id: Joi.string().required(),
          config: inputSchemaForModuleConfig,
          configSectionName: Joi.string().required(),
          uiConfig: inputStructureSchema,
          uiConfigSectionName: Joi.string().required(),
          workflowKey: Joi.string().required(),
        }),
      ).required(),
    }),
    operatingFn: (workflow, actionData) => {
      const { builderProperties, path } = actionData;
      const { updatedWorkflow, success } = setModuleBuilderConfigurations(
        workflow,
        builderProperties,
        path,
      );
      return { workflow: updatedWorkflow, success };
    },
    maskingConfig: {
      actionDataPathsToMask: ['builderProperties'],
      responseDataPathsToMask: [],
    },
  },
  SET_MODULE_BUILDER_INPUTS: {
    actionDataSchema: Joi.object({
      path: Joi.string().required(),
      inputs: Joi.array().items(
        Joi.object({
          id: Joi.string().required(),
          config: inputSchemaForModuleConfig,
          configSectionName: Joi.string().required(),
          uiConfig: inputStructureSchema,
          uiConfigSectionName: Joi.string().required(),
          workflowKey: Joi.string().required(),
        }),
      ).required(),
    }),
    operatingFn: (workflow, actionData) => {
      const { inputs, path } = actionData;
      const { updatedWorkflow, success } = setModuleBuilderConfigurations(workflow, inputs, path);
      return { workflow: updatedWorkflow, success };
    },
    maskingConfig: {
      actionDataPathsToMask: ['inputs'],
      responseDataPathsToMask: [],
    },
  },
  SET_MODULE_BUILDER_OUTPUTS: {
    actionDataSchema: Joi.object({
      path: Joi.string().required(),
      outputs: Joi.object().pattern(
        Joi.string(),
        Joi.object({
          name: Joi.string().required(),
          type: Joi.string().required(),
          path: Joi.string().required(),
          description: Joi.string().allow(''),
          displayName: Joi.string().required().allow(''),
        }),
      ).required(),
    }),
    operatingFn: (workflow, actionData) => {
      const { outputs, path } = actionData;
      const { updatedWorkflow, success } = setModuleBuilderConfigurations(workflow, outputs, path);
      return { workflow: updatedWorkflow, success };
    },
  },
  UPDATE_SELECTED_COUNTRIES: {
    actionDataSchema: Joi.object({
      moduleId: Joi.string().required(),
      countriesSelected: Joi.array().required(),
      globalDocumentList: Joi.array().required(),
      workflowKey: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        moduleId,
        countriesSelected,
        globalDocumentList,
        workflowKey,
      } = actionData;
      const { workflow: updatedWorkflow, isSuccess } = updateCountriesInWorkflow(
        workflow,
        moduleId,
        countriesSelected,
        globalDocumentList,
        workflowKey,
      );
      return { workflow: updatedWorkflow, success: isSuccess };
    },
    maskingConfig: {
      actionDataPathsToMask: ['globalDocumentList'],
      responseDataPathsToMask: [],
    },
  },
  UPDATE_SELECTED_DOCUMENTS: {
    actionDataSchema: Joi.object({
      moduleId: Joi.string().required(),
      documentsSelected: Joi.array().required(),
      moduleConfig: Joi.object().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        moduleId,
        documentsSelected,
        moduleConfig,
      } = actionData;
      const { workflow: updatedWorkflow, isSuccess } = updateDocumentsInWorkflow(
        workflow,
        moduleId,
        documentsSelected,
        moduleConfig,
      );
      return { workflow: updatedWorkflow, success: isSuccess };
    },
  },
  REFRESH_MODULE_UPDATE_TIMESTAMP: {
    actionDataSchema: Joi.object({
      updatedAt: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const { updatedAt } = actionData;
      const {
        workflow: updatedWorkflow,
        success,
      } = updateLastModifiedTimestamp(workflow, updatedAt);
      return { workflow: updatedWorkflow, success };
    },
  },
  UPDATE_LOTTIE: {
    actionDataSchema: Joi.object({
      lottieUrl: Joi.string().allow('').required(),
      lottieType: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData, uiConfig) => {
      const {
        lottieUrl, lottieType,
      } = actionData;
      const { uiConfig: updatedUiConfig, errorData } =
      setLottie({
        lottieUrl,
        lottieType,
        uiConfig,
      });
      if (errorData) {
        return {
          workflow, uiConfig, success: false, errorData,
        };
      }
      return {
        workflow,
        uiConfig: updatedUiConfig,
        success: true,
      };
    },
  },
  UPDATE_WORKFLOW_DETAILS: {
    actionDataSchema: Joi.object({
      name: Joi.string().required(),
      description: Joi.string().required(),
      subType: Joi.string().allow(null),
    }),
    operatingFn: (workflow, actionData) => {
      const { name, description, subType } = actionData;
      const {
        workflow: updatedWorkflow,
        success,
      } = updateWorkflowDetails(workflow, name, description, subType);
      return { workflow: updatedWorkflow, success };
    },
  },
  UPDATE_SUPER_MODULE_VERSION: {
    actionDataSchema: Joi.object({
      node: Joi.object(),
      countryDocMapping: Joi.object(),
      defaultFormSections: Joi.array(),
      localOrderOfNodes: Joi.array(),
      moduleId: Joi.string(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        node,
        countryDocMapping,
        defaultFormSections,
        localOrderOfNodes,
        moduleId,
      } = actionData;
      const { workflow: updatedWorkflow, extraData, success } = updateModuleVersionInWorkflow(
        workflow,
        node,
        countryDocMapping,
        defaultFormSections,
        localOrderOfNodes,
        moduleId,
      );

      return { workflow: updatedWorkflow, extraData, success };
    },
    maskingConfig: {
      actionDataPathsToMask: ['countryDocMapping', 'localOrderOfNodes'],
      responseDataPathsToMask: [],
    },
  },
  CHANGE_END_STATE: {
    actionDataSchema: Joi.object({
      oldEndState: Joi.string().required(),
      newEndState: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const { oldEndState, newEndState } = actionData;
      const { workflow: updatedWorkflow, success } =
      changeEndState(workflow, oldEndState, newEndState);
      return { workflow: updatedWorkflow, success };
    },
  },
  // TODO: create granular actions for uiConfig, we should be creating the uiConfig within these
  // functions and only recieve required data in the props
  OVERWRITE_UI_CONFIG: {
    actionDataSchema: Joi.object({
      uiConfig: Joi.object().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const { uiConfig } = actionData;
      return { workflow, uiConfig, success: true };
    },
  },
  UPDATE_FONT_WEIGHT_IN_UI_CONFIG: {
    actionDataSchema: Joi.object({
      id: Joi.string().required(),
      fontWeight: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData, uiConfig) => {
      const { id, fontWeight } = actionData;
      const { editedUiConfig, editedWorkflow, errorData } =
      updateFontWeight(workflow, uiConfig, id, fontWeight);
      if (errorData) {
        return {
          workflow, uiConfig, success: false, errorData,
        };
      }
      return {
        workflow: editedWorkflow, uiConfig: editedUiConfig, success: true,
      };
    },
  },
  UPDATE_FONT_IN_UI_CONFIG: {
    actionDataSchema: Joi.object({
      id: Joi.string().required(),
      font: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData, uiConfig) => {
      const { id, font } = actionData;
      const { editedUiConfig, editedWorkflow, errorData } =
      updateFont(workflow, uiConfig, id, font);
      if (errorData) {
        return {
          workflow, uiConfig, success: false, errorData,
        };
      }
      return {
        workflow: editedWorkflow, uiConfig: editedUiConfig, success: true,
      };
    },
  },
  UPDATE_COLOR_IN_UI_CONFIG: {
    actionDataSchema: Joi.object({
      key: Joi.string().required(),
      colorValue: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData, uiConfig) => {
      const { key, colorValue } = actionData;
      const { editedUiConfig, editedWorkflow, errorData } =
      updateColor(workflow, uiConfig, key, colorValue);
      if (errorData) {
        return {
          workflow, uiConfig, success: false, errorData,
        };
      }
      return {
        workflow: editedWorkflow, uiConfig: editedUiConfig, success: true,
      };
    },
  },
  UPDATE_LOGO: {
    actionDataSchema: Joi.object({
      logoUrl: Joi.string().allow('').required(),
    }),
    operatingFn: (workflow, actionData, uiConfig) => {
      const { logoUrl } = actionData;
      const { uiConfig: updatedUiConfig, errorData } = updateLogoInUiConfig(uiConfig, logoUrl);
      if (errorData) {
        return {
          workflow, uiConfig, success: false, errorData,
        };
      }
      return { workflow, uiConfig: updatedUiConfig, success: true };
    },
  },
  UPDATE_COLORS_IN_UI_CONFIG: {
    actionDataSchema: Joi.object({
      primaryColor: Joi.string().required(),
      accentColor: Joi.string().required(),
      sdkBackgroundColor: Joi.string().required(),
      primaryColorKeys: Joi.array().required(),
      primaryColorSubsetKeys: Joi.array().items(Joi.string()).required(),
      accentColorDisabledButtonKeys: Joi.array().items(Joi.string()).required(),
      accentColorHoverButtonKeys: Joi.array().items(Joi.string()).required(),
      accentColorKeys: Joi.array().items(Joi.string()).required(),
      sdkBackgroundColorKeys: Joi.array().items(Joi.string()).required(),
    }),
    operatingFn: (workflow, actionData, uiConfig) => {
      const {
        primaryColor,
        accentColor,
        sdkBackgroundColor,
        primaryColorKeys,
        primaryColorSubsetKeys,
        accentColorDisabledButtonKeys,
        accentColorHoverButtonKeys,
        accentColorKeys,
        sdkBackgroundColorKeys,
      } = actionData;

      const colors = {
        primaryColor,
        accentColor,
        sdkBackgroundColor,
      };
      const colorKeys = {
        primaryColorKeys,
        primaryColorSubsetKeys,
        accentColorDisabledButtonKeys,
        accentColorHoverButtonKeys,
        accentColorKeys,
        sdkBackgroundColorKeys,
      };

      const { editedUiConfig, editedWorkflow, errorData } =
      updateColorSchemeInUiConfig(workflow, colorKeys, colors, uiConfig);
      if (errorData) {
        return {
          workflow, uiConfig, success: false, errorData,
        };
      }
      return { workflow: editedWorkflow, uiConfig: editedUiConfig, success: true };
    },
  },
  UPDATE_ICON_IN_UI_CONFIG: {
    actionDataSchema: Joi.object({
      iconUrl: Joi.string().allow('').required(),
      iconKey: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData, uiConfig) => {
      const { iconUrl, iconKey } = actionData;
      const { uiConfig: updatedUiConfig, errorData } =
      updateIconInUiConfig(uiConfig, iconUrl, iconKey);
      if (errorData) {
        return {
          workflow, uiConfig, success: false, errorData,
        };
      }
      return { workflow, uiConfig: updatedUiConfig, success: true };
    },
  },
  UPDATE_CUSTOM_FONTS_STYLESHEET_URLS_IN_UI_CONFIG: {
    actionDataSchema: Joi.object({
      customFontStylesheetUrls: Joi.array().required(),
    }),
    operatingFn: (workflow, actionData, uiConfig) => {
      const { customFontStylesheetUrls } = actionData;
      const { uiConfig: updatedUiConfig, editedWorkflow, errorData } =
      updateCustomFontStylesheetUrlsInUiConfig(workflow, uiConfig, customFontStylesheetUrls);
      if (errorData) {
        return {
          workflow, uiConfig, success: false, errorData,
        };
      }
      return { workflow: editedWorkflow, uiConfig: updatedUiConfig, success: true };
    },
  },
  UPDATE_MODULE_ID_IN_UI_CONFIG: {
    actionDataSchema: Joi.object({
      moduleKeys: Joi.array().required(),
      uiConfig: Joi.object().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const { moduleKeys, uiConfig } = actionData;
      const { uiConfig: editedUiConfig, errorData } =
      replaceActualModuleIdsWithSuperModuleIds(moduleKeys, uiConfig);
      if (errorData) {
        return {
          workflow, uiConfig, success: false, errorData,
        };
      }
      return {
        workflow, uiConfig: editedUiConfig, extraData: { editedUiConfig }, success: true,
      };
    },
  },
};

// Utility function to update the logo in the UI configuration

export const supportedWorkflowOperations = Object.keys(workflowOperations);

export const workflowOperationsObj = supportedWorkflowOperations
  .reduce((acc, curr) => ({ [curr]: curr, ...acc }), { });

export const operateOnWorkflow = (workflow, action, uiConfig) => {
  try {
    const { operation = null, actionData = {} } = action;

    // TODO: success is true because the operation is not yet migrated,
    // Will make it false once all are migrated.
    if (!supportedWorkflowOperations.includes(operation)) return { workflow, success: true };
    const { actionDataSchema, operatingFn } = workflowOperations[operation];
    const { error } = actionDataSchema.validate(actionData);
    if (error) {
      // TODO: Commented out code to be used once all the operations are returning errorData
      return { workflow, success: false };
      // return {
      //   workflow,
      //   success: false,
      //   errorData: new HVError({
      //     code: errorCodes.joiValidationError,
      //     message: 'Update workflow failed due to invalid action data',
      //     originalError: error,
      //     debugInfo: { actionData },
      //     type: 'default',
      //   }),
      // };
    }
    return operatingFn(workflow, actionData, uiConfig);
  } catch (err) {
    // TODO: Commented out code to be used once all the operations are returning errorData
    return { workflow, success: false };
    // return {
    //   workflow,
    //   success: false,
    //   errorData: new HVError({
    //     code: errorCodes.failedWorkflowOperation,
    //     message: 'Failed to update workflow',
    //     originalError: new Error('Failed to update workflow'),
    //     debugInfo: {
    //       failedOperation: action?.operation,
    //       actionData: action?.actionData,
    //     },
    //     type: 'default',
    //   }),
    // };
  }
};
