import checkBuilderPropertiesAddedInModuleVersion from '../components/ViewWorkflow/ModuleVersionUpdate/utils/comparisonFunction/checkBuilderPropertiesAddedInModuleVersion';
import checkBuilderPropertiesRemovedInModuleVersion from '../components/ViewWorkflow/ModuleVersionUpdate/utils/comparisonFunction/checkBuilderPropertiesRemovedInModuleVersion';
import checkInputsAddedInModuleVersion from '../components/ViewWorkflow/ModuleVersionUpdate/utils/comparisonFunction/checkInputsAddedInModuleVersion';
import checkInputsRemovedInModuleVersion from '../components/ViewWorkflow/ModuleVersionUpdate/utils/comparisonFunction/checkInputsRemovedInModuleVersion';
import checkOutputsAddedInModuleVersion from '../components/ViewWorkflow/ModuleVersionUpdate/utils/comparisonFunction/checkOutputsAddedInModuleVersion';
import checkOutputsRemovedInModuleVersion from '../components/ViewWorkflow/ModuleVersionUpdate/utils/comparisonFunction/checkOutputsRemovedInModuleVersion';
import checkSubModuleAddedInModuleVersion from '../components/ViewWorkflow/ModuleVersionUpdate/utils/comparisonFunction/checkSubModuleAddedInModuleVersion';
import checkSubModuleRemovedInModuleVersion from '../components/ViewWorkflow/ModuleVersionUpdate/utils/comparisonFunction/checkSubModuleRemovedInModuleVersion';
import handleBuilderPropertiesRemoved from '../components/ViewWorkflow/ModuleVersionUpdate/utils/actionFunction/handleBuilderPropertiesRemoved';
import handleInputsRemoved from '../components/ViewWorkflow/ModuleVersionUpdate/utils/actionFunction/handleInputsRemoved';
import handleOutputVariableRemoved from '../components/ViewWorkflow/ModuleVersionUpdate/utils/actionFunction/handleOutputVariableRemoved';

const moduleVersionComparisonConfig = [
  {
    comparisonName: 'outputVariableRemoved',
    comparisonFn: checkOutputsRemovedInModuleVersion,
    breakingChange: true,
    actionFn: handleOutputVariableRemoved,
    actionFailureUI: 'dependencyModal',
    description: 'Output variables have been removed or path has been changed.',
  },
  {
    comparisonName: 'outputVariableAdded',
    comparisonFn: checkOutputsAddedInModuleVersion,
    breakingChange: false,
    actionFn: null,
    actionFailureUI: null,
    description: 'New output variables have been added.',
  },
  {
    comparisonName: 'subModuleAdded',
    comparisonFn: checkSubModuleAddedInModuleVersion,
    breakingChange: true,
    actionFn: null,
    actionFailureUI: null,
    description: 'New sub-modules have been added.',
  },
  {
    comparisonName: 'subModuleRemoved',
    comparisonFn: checkSubModuleRemovedInModuleVersion,
    breakingChange: true,
    actionFn: null,
    actionFailureUI: null,
    description: 'Sub-modules have been removed.',
  },
  {
    comparisonName: 'inputsRemoved',
    comparisonFn: checkInputsRemovedInModuleVersion,
    breakingChange: true,
    actionFn: handleInputsRemoved,
    actionFailureUI: null,
    description: 'Inputs to the module have been removed or modified.',
  },
  {
    comparisonName: 'inputsAdded',
    comparisonFn: checkInputsAddedInModuleVersion,
    breakingChange: false,
    actionFn: null,
    actionFailureUI: null,
    description: 'New inputs to module have been added.',
  },
  {
    comparisonName: 'builderPropertiesRemoved',
    comparisonFn: checkBuilderPropertiesRemovedInModuleVersion,
    breakingChange: true,
    actionFn: handleBuilderPropertiesRemoved,
    actionFailureUI: null,
    description: 'Builder properties have been removed or modified.',
  },
  {
    comparisonName: 'builderPropertiesAdded',
    comparisonFn: checkBuilderPropertiesAddedInModuleVersion,
    breakingChange: false,
    actionFn: null,
    actionFailureUI: null,
    description: 'New builder properties have been added.',
  },
];

export default moduleVersionComparisonConfig;
