import PropTypes from 'prop-types';
import DeleteNodeModal from '../DeleteNodeModal';

function DependencyModal({ error, resetError }) {
  return (
    <>
      {
          error?.dependencies && (
            <DeleteNodeModal
              onClose={resetError}
              dependencies={error?.dependencies}
            />
          )
        }

    </>
  );
}

DependencyModal.propTypes = {
  resetError: PropTypes.func.isRequired,
  error: PropTypes.object,
};

DependencyModal.defaultProps = {
  error: null,
};

export default DependencyModal;
