import { cloneDeep, get, set } from 'lodash';
import DefaultError from '../error/defaultError';
import { errorCodes } from '../utils/error';

// Assumption except for the moduleIds we can only below primary keys in the ui config.
export const globalConfigKeys = ['colors', 'font', 'fontWeight', 'fontSize', 'alignment', 'logos', 'dark', 'animation', 'icons', 'customFontStylesheetUrls'];

export const setDefaultUIConfigsForSuperModule =
  (currentConfig, highLevelModuleUiConfig) => {
    let clonnedConfig = cloneDeep(currentConfig || {});
    clonnedConfig = { ...clonnedConfig, ...highLevelModuleUiConfig };
    return clonnedConfig;
  };

export const setUiConfigPropertyInHighLevelUiConfig = ({
  highLevelUiConfig, superModuleId, mappingId, componentId, key, value,
}) => {
  const clonedUiConfig = cloneDeep(highLevelUiConfig);
  const existingConfig = clonedUiConfig[`${superModuleId}[+]${mappingId}`] || {};
  set(existingConfig, `${componentId}.${key}`, value);
  clonedUiConfig[`${superModuleId}[+]${mappingId}`] = existingConfig;
  return clonedUiConfig;
};

export const getHighLevelUiConfig = (superModuleId, defaultSuperModuleUIConfig) => {
  const clonnedDefaultConfig = cloneDeep(defaultSuperModuleUIConfig || {});
  const updatedConfig = {};
  const moduleIds = Object.keys(clonnedDefaultConfig);
  moduleIds.forEach((moduleId) => {
    const moduleUiConfig = defaultSuperModuleUIConfig[moduleId];
    updatedConfig[`${superModuleId}[+]${moduleId}`] = moduleUiConfig;
  });
  return updatedConfig;
};
export const getHighLevelTextConfig = (
  superModuleId,
  defaultSuperModuleTextConfig,
  modules,
) => {
  const moduleMap = modules.reduce((acc, { id, subType }) => {
    acc[`${id}_${subType}`] = subType;
    return acc;
  }, {});
  const updatedConfig = {};
  Object.entries(defaultSuperModuleTextConfig).forEach(([moduleId, moduleTextConfig]) => {
    const subType = moduleMap[moduleId];
    if (subType) {
      const key = `${superModuleId}_${moduleId}_${subType}`;
      updatedConfig[key] = moduleTextConfig;
    }
  });
  return updatedConfig;
};

export const setDefaultTextConfigsForSuperModule =
  (currentConfig, textConfig) => {
    let clonnedConfig = cloneDeep(currentConfig || {});
    clonnedConfig = { ...clonnedConfig, ...textConfig };
    return clonnedConfig;
  };

const isSuperModuleKey = (key) => `${key}`.includes('[+]');

export const replaceSuperModuleIdsWithActualIds = (moduleKeys, currentUiConfig) => {
  const updatedUiConfig = {};
  const keys = Object.keys(currentUiConfig || {});
  const map = {};
  (moduleKeys || []).forEach((module) => {
    const { superModuleId, mappingId, id } = module;
    if (superModuleId && mappingId) map[`${superModuleId}[+]${mappingId}`] = id;
  });
  keys.forEach((key) => {
    if (isSuperModuleKey(key)) {
      if (map[key]) updatedUiConfig[map[key]] = currentUiConfig[key];
    } else updatedUiConfig[key] = currentUiConfig[key];
  });
  return updatedUiConfig;
};

export const replaceActualModuleIdsWithSuperModuleIds = (moduleConfigs, currentUiConfig) => {
  try {
    const updatedUiConfig = {};
    const keys = Object.keys(currentUiConfig || {});
    const map = {};
    (moduleConfigs || []).forEach((module) => {
      const { superModuleId, mappingId, id } = module;
      if (superModuleId && mappingId) map[id] = `${superModuleId}[+]${mappingId}`;
      else map[id] = id;
    });
    keys.forEach((key) => {
      if (map[key]) updatedUiConfig[map[key]] = currentUiConfig[key];
      else if (globalConfigKeys.includes(key)) updatedUiConfig[key] = currentUiConfig[key];
    });
    return { uiConfig: updatedUiConfig };
  } catch (error) {
    const processedError = new DefaultError({
      code: errorCodes.errorInSettingWorkflowInState,
      message: 'Failed to set workflow in state!',
      originalError: error,
      debugInfo: {},
    });
    return { uiConfig: currentUiConfig, errorData: processedError };
  }
};

export const compileUiConfig = (uiConfig, compiledWorkflow) => {
  if (uiConfig && Object.keys(uiConfig).length > 0) {
    const compiledModuleUiConfig =
  replaceSuperModuleIdsWithActualIds(compiledWorkflow?.modules || [], uiConfig);
    return compiledModuleUiConfig;
  }
  return uiConfig;
};

export const extractCustomFontsFromUiConfig = (uiConfig) => {
  if (!uiConfig) return [];

  const fonts = new Set();
  Object.values(uiConfig.font || {}).forEach((font) => fonts.add(font));

  Object.keys(uiConfig).forEach((uiConfigKey) => {
    if (!uiConfigKey.includes('module')) return;
    Object.keys(uiConfig[uiConfigKey]).forEach((componentId) => {
      const font = get(uiConfig, `${uiConfigKey}.${componentId}.font`);
      if (font) fonts.add(font);
    });
  });

  return Array.from(fonts);
};
