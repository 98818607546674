import { addNewNodeInWorkflow, deleteNodeFromWorkflow } from '../../../../workflowOperations/operations/genericOperations';
import convertToNodesEdges from '../../../utils';

const updateModuleVersionInWorkflow = (
  workflow,
  newNodeDetails,
  countryDocMapping,
  defaultFormSections,
  currentOrderOfNodes,
  moduleId,
) => {
  if (!currentOrderOfNodes.some((node) => node.id === moduleId)) {
    return { workflow, success: false };
  }
  const {
    parentId: parent,
    parentPath,
    parentBranch,
  } = currentOrderOfNodes.find((node) => node.id === moduleId);
  const { workflow: operatedWorkflow, success: isModuleDeleted } =
    deleteNodeFromWorkflow(moduleId, parent, workflow, false);

  if (!isModuleDeleted) return { workflow, success: false };

  const { nodes: newOrderOfNodes } = convertToNodesEdges(operatedWorkflow);
  const childNode = newOrderOfNodes.find(
    (node) => node.parentId === parent &&
      node.parentPath === parentPath &&
      node.parentBranch === parentBranch,
  );
  if (!childNode) return { workflow, success: false };

  const {
    workflow: updatedWorkflow, highLevelUiConfig, newModule, success, highLevelTextConfig,
  } =
    addNewNodeInWorkflow({
      node: newNodeDetails,
      addNodeBetween: {
        parent,
        child: childNode.id,
      },
      nodes: newOrderOfNodes,
      workflowConfig: operatedWorkflow,
      countryDocMapping,
      localOrderOfNodes: newOrderOfNodes,
      defaultFormSections,
      type: 'module',
      newModuleId: moduleId,
    });

  if (!success) return { workflow, success: false };

  return {
    workflow: updatedWorkflow,
    extraData: { highLevelUiConfig, newModule, highLevelTextConfig },
    success,
  };
};

export default updateModuleVersionInWorkflow;
