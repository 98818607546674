/* eslint-disable no-param-reassign */

import { useEffect, useContext } from 'react';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as Sentry from '@sentry/react';
import useApiHooks from '../hooks/api/useApiHooks';
import AlertCode from '../constants/alertCodes';
import {
  resetSelectedWorkflow,
  selectIsWorkflowEdited,
  selectSelectedWorkflow,
  selectVersionedModules,
  undoSelectedWorkflow,
  updateVersionedModules,
  resetIsEditedWorkflow,
} from '../reducers/workflow';
import { updateWorkflowInState } from '../workflowOperations/updateWorkflow';
import { workflowOperationsObj } from '../workflowOperations';
import useShowErrorAlert from '../hooks/custom/useCustomAlert';
import {
  resetBrandingScreens,
  selectSelectedLanguage,
  updateCustomTextConfig,
  updateSelectedLanguage,
  updateFontStyleSheets,
} from '../reducers/editBranding';
import ErrorBoundaryWrapper from '../containers/ErrorBoundaryWrapper';
import FallbackComponent from '../components/Common/FallbackComponent';
import { closeExistingSDK } from '../components/utils';
import {
  extractCustomFontsFromUiConfig,
} from '../containers/uiConfigOperations';
import useFetchWorkflow from '../hooks/workflow/fetchWorkflow';
import processModuleBuilderProperties from '../utils/processModuleBuilderproperties';
import ErrorHandlerContext from '../context/ErrorHandlerContext';

function WorkflowRouter() {
  const {
    fetchCustomTextConfig, fetchCustomUiConfig, getDefaultTextConfig,
  } = useApiHooks();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const workflowId = searchParams.get('id');

  const currentAppId = useSelector((state) => state.user.currentAppId);
  const workflowConfig = useSelector(selectSelectedWorkflow);
  const versionedModules = useSelector(selectVersionedModules);
  const isWorkflowEdited = useSelector(selectIsWorkflowEdited);
  const selectedLanguage = useSelector(selectSelectedLanguage);
  const handleError = useContext(ErrorHandlerContext);

  const fetchWorkflow = useFetchWorkflow(currentAppId, workflowConfig, versionedModules);

  const showErrorAlert = useShowErrorAlert();

  const changeCSS = () => {
    const style = document.createElement('style');
    style.innerHTML = '.react-flow__handle.connectable { visibility: hidden;}';
    document.getElementsByTagName('head')[0].appendChild(style);
  };

  // fetching the workflow by workflow id
  useEffect(() => {
    getDefaultTextConfig();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!workflowId) {
      navigate('/');
    } else {
      Sentry.setTag('workflow_id', workflowId);
      (async () => {
        try {
          const {
            workflow: decompiledWorkflow, rawWorkflow, modules,
          } = await fetchWorkflow(workflowId);
          if (decompiledWorkflow && modules) {
            dispatch(updateVersionedModules({ modules }));
            dispatch(resetIsEditedWorkflow());
          }

          // For modules which have already been imported with non empty configs.
          const workflowUpdateActions = [];
          const workflow = processModuleBuilderProperties(decompiledWorkflow);
          workflowUpdateActions.push({
            operation: workflowOperationsObj.SET_WORKFLOW_IN_STATE,
            actionData: {
              workflow,
            },
          });
          changeCSS();
          dispatch(resetBrandingScreens());
          const uiConfig = await fetchCustomUiConfig(workflowId);
          const textConfig = await fetchCustomTextConfig(workflowId, selectedLanguage);
          workflowUpdateActions.push({
            operation: workflowOperationsObj.UPDATE_MODULE_ID_IN_UI_CONFIG,
            actionData: {
              moduleKeys: (rawWorkflow?.modules || []).map(({ superModuleId, mappingId, id }) => ({
                superModuleId,
                mappingId,
                id,
              })),
              uiConfig,
            },
          });
          let extraData;
          try {
            ({ extraData } =
            updateWorkflowInState({}, true, {
              operation: 'WORKFLOW_BULK_UPDATE',
              actionData: {
                workflowUpdateActions,
              },
            }));
          } catch (error) {
            handleError(error);
          }
          const { editedUiConfig: highLevelUiConfig } = extraData.UPDATE_MODULE_ID_IN_UI_CONFIG;
          if (textConfig) {
            dispatch(updateCustomTextConfig({ textConfig, language: selectedLanguage }));
          }
          const customFonts = extractCustomFontsFromUiConfig(highLevelUiConfig);
          const customFontStyleSheets = customFonts.reduce((styleSheets, font) => ({
            ...styleSheets,
            [font]: (process.env.REACT_APP_CUSTOM_FONT_URL || '').replace('<SELECTED_FONT>', font),
          }), {});
          dispatch(updateFontStyleSheets({ fontStyleSheets: customFontStyleSheets }));
        } catch (error) {
          showErrorAlert({ error, message: error?.message || AlertCode.error.fetchWorkflow });
        }
      })();
    }

    return () => {
      updateWorkflowInState({}, true, {
        operation: workflowOperationsObj.OVERWRITE_UI_CONFIG,
        actionData: {
          uiConfig: {},
        },
      });
      dispatch(resetSelectedWorkflow());
      dispatch(updateCustomTextConfig({ textConfig: {} }));
      dispatch(updateSelectedLanguage({ language: 'en' }));
      Sentry.setTag('workflow_id', null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowId]);

  useEffect(() => {
    const beforeUnloadListener = (event) => {
      event.preventDefault();
      // eslint-disable-next-line no-return-assign
      return (event.returnValue = '');
    };
    if (isWorkflowEdited) {
      window.addEventListener('beforeunload', beforeUnloadListener);
      window.addEventListener('pagehide', beforeUnloadListener);
      return () => {
        window.removeEventListener('beforeunload', beforeUnloadListener);
        window.removeEventListener('pagehide', beforeUnloadListener);
      };
    }
    return () => { };
  }, [isWorkflowEdited]);

  return (
    <>
      {Object.keys(workflowConfig || {}).length === 0 ? (
        <div className="reload-loading">loading please wait</div>
      ) :
        <Outlet />}
    </>
  );
}

function WorkflowRouterWrapper() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const workflowId = searchParams.get('id');

  return (
    <ErrorBoundaryWrapper
      FallbackComponent={FallbackComponent}
      resetFunction={() => {
        // reset the state of your app here
        // launchId is not required in this case
        closeExistingSDK({ launchId: null, closeAllSDKs: true });
        dispatch(undoSelectedWorkflow());
        navigate(`/view?id=${workflowId}`);
      }}
    >
      <WorkflowRouter />
    </ErrorBoundaryWrapper>
  );
}

export default WorkflowRouterWrapper;
