import findMissingModuleIds from './utils/findMissingModuleIds';
import convertSuperModulesToSimpleModules from './utils/convertSuperModulesToSimpleModules';
import validateAndUpdateModulesSubtypes from './utils/validateAndUpdateModuleSubtypes';

const fetchAndValidateSuperModules = (currentWorkflow, updatedVersionedModules) => {
  const { workflow: updatedWorkflow, errorData: newErrorData } =
  validateAndUpdateModulesSubtypes(currentWorkflow, updatedVersionedModules);
  const { unfetchedSuperModuleIds, errorData: unfetchedModulesErrorData, isSuccess } =
  findMissingModuleIds(
    updatedWorkflow.modules,
    updatedVersionedModules,
    updatedWorkflow?.properties?.builder?.superModuleMetaData,
  );
  updatedWorkflow.modules =
  convertSuperModulesToSimpleModules(updatedWorkflow.modules, unfetchedSuperModuleIds);
  const { workflow: newWorkflow, errorData: updatedErrorData } =
  validateAndUpdateModulesSubtypes(updatedWorkflow, updatedVersionedModules);
  const errorData = [...updatedErrorData, ...unfetchedModulesErrorData, ...newErrorData];
  return {
    workflow: newWorkflow,
    errorData,
    isSuccess,
  };
};

export default fetchAndValidateSuperModules;
