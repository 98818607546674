import { useState } from 'react';
import PropTypes from 'prop-types';
import generateUniqueID from '../../utils/generateUniqueId';
import chevronRight from '../../assests/icons/newChevronRight.svg';
import chevronDown from '../../assests/icons/newChevronDown.svg';
import { CustomCheckboxWithLabel } from '../ViewWorkflow/CustomCheckboxComponents';
import AlertCode from '../../constants/alertCodes';
import closeIcon from '../../assests/icons/closeIcon.svg';
import './ErrorModalContent.scss';

function ErrorModalContent({
  errorData, onConfirm, isWorkflowValidationSuccessful, onClose,
}) {
  const [expandedSections, setExpandedSections] = useState({});
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const formatDescription = (description) => {
    const segments = description.split(/(<<[^>>]+>>)/g);
    return segments.map((segment) => {
      if (segment.startsWith('<<') && segment.endsWith('>>')) {
        return (
          <span key={generateUniqueID()} className="blue-text">
            {segment.slice(2, -2)}
          </span>
        );
      }
      return segment;
    });
  };

  const toggleSection = (index) => {
    setExpandedSections((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };
  const onConfirmHandler = () => {
    setIsCheckboxChecked(false);
    setExpandedSections({});
    onConfirm();
  };

  const onCloseHandler = () => {
    setIsCheckboxChecked(false);
    setExpandedSections({});
    onClose();
  };

  const handleCheckboxChange = () => {
    setIsCheckboxChecked((prev) => !prev);
  };
  const modalHeaderFirstPart = isWorkflowValidationSuccessful
    ? 'Workflow Imported Successfully'
    : 'Your Workflow Needed Some Tweaks';

  const modalHeaderSecondPart = isWorkflowValidationSuccessful
    ? ''
    : 'The following modifications have been made to ensure builder compatibility';

  return (
    <>
      <div className="error-modal-container">
        <div className="error-modal-sub-container">
          <div className="modal-heading-container">
            <div className="modal-heading">
              <div className="modal-heading__firstPart">
                {modalHeaderFirstPart}
              </div>
              <div className="modal-heading__secondPart">
                {modalHeaderSecondPart}
              </div>
            </div>
            <button type="button" className="modal-close" onClick={onClose}>
              <img src={closeIcon} alt="close" />
            </button>
          </div>
          {!isWorkflowValidationSuccessful && (
          <div className="error-modal-issue-container">
            <div className="error-modal-compatibility-message">
              Import failed due to compatibility issues.
              Contact workflow builder support to learn more.
            </div>
          </div>
          )}
          <div className="error-modal-body">
            <div className="error-modal-content">
              {errorData?.map((section, index) => (
                <div
                  key={generateUniqueID()}
                  className={`error-section${expandedSections[index] ? '__open' : ''}`}
                >
                  <div
                    className={`error-section-summary-container${expandedSections[index] ? '__open' : ''}`}
                    onClick={() => toggleSection(index)}
                    onKeyPress={(e) => { if (e.key === 'Enter') toggleSection(index); }}
                    tabIndex={0}
                    role="button"
                  >
                    <div className="accordion-icon">
                      {expandedSections[index] ? <img src={chevronDown} alt="down" /> : <img src={chevronRight} alt="right" />}
                    </div>
                    <div className="error-section-container">
                      <div className="error-section-summary">{AlertCode.error[section.errorCode] || 'Error'}</div>
                    </div>
                  </div>
                  {expandedSections[index] && (
                  <div className="error-section-content">
                    <ul className="error-section-items">
                      {section?.errorDetails?.map((item) => (
                        <li key={generateUniqueID()} className="error-items">
                          {formatDescription(item.description)}
                        </li>
                      ))}
                    </ul>
                  </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="error-modal-footer">
            {!isWorkflowValidationSuccessful ? (
              <div className="error-modal-save">
                <button type="button" className="error-modal-save__button__active" onClick={onCloseHandler}>
                  Exit Import
                </button>
              </div>
            ) : (
              <>
                <div className="error-modal-footer-confirmation">
                  <CustomCheckboxWithLabel
                    label="I acknowledge all changes made to my workflow"
                    checked={isCheckboxChecked}
                    onChange={handleCheckboxChange}
                  />
                </div>
                <div className="error-modal-save">
                  <button type="button" className="error-modal-save__button" onClick={onClose}>
                    Cancel
                  </button>
                  <button
                    type="button"
                    className={`error-modal-save__button${!isCheckboxChecked ? '__disabled' : '__active'}`}
                    onClick={onConfirmHandler}
                    disabled={!isCheckboxChecked}
                  >
                    Proceed
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

ErrorModalContent.propTypes = {
  errorData: PropTypes.arrayOf(
    PropTypes.shape({
      errorCode: PropTypes.string.isRequired,
      errorDetails: PropTypes.arrayOf(
        PropTypes.shape({
          description: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
  onConfirm: PropTypes.func.isRequired,
  isWorkflowValidationSuccessful: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ErrorModalContent;
