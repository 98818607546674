/* eslint-disable consistent-return */
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import AlertCode from '../../constants/alertCodes';
import useShowErrorAlert from '../custom/useCustomAlert';
import { updateDefaultConfigs, updateSupportedFonts } from '../../reducers/editBranding';
import { updateFormV2Components, updateGlobalConfig } from '../../reducers/workflow';
import { DRAWER_TYPES, endStates } from '../../components/constants';
import { updateDocToken } from '../../reducers/onboard';
import { updateAppIdKeyToken } from '../../reducers/user';
import { persistor } from '../../store';
import generateUniqueID from '../../utils/generateUniqueId';
import HVError from '../../utils/error';

const useApiHooks = () => {
  const currentAppId = useSelector((state) => state.user.currentAppId);
  const email = useSelector((state) => state.user.email);
  const dispatch = useDispatch();
  const showErrorAlert = useShowErrorAlert();

  const publishWorkflow = async (appId, workflowId, selectedWorkflow) => {
    try {
      await axios({
        method: 'PUT',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/workflow`,
        headers: { appId },
        data: { workflowId, workflowConfig: selectedWorkflow },
      });
      return {
        isSuccessful: true,
        error: null,
        message: '',
      };
    } catch (error) {
      return {
        isSuccessful: false,
        error,
        message: AlertCode.error.errorUploadingWorkflow,
      };
    }
  };

  const pushToStaging = async (appIds, workflowId) => {
    try {
      await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/workflow/publish`,
        data: { workflowId, appIds },
      });
      return {
        isSuccessful: true,
        error: null,
        message: '',
      };
    } catch (error) {
      return {
        isSuccessful: false,
        error,
        message: AlertCode.error.errorPublishingWorkflow,
      };
    }
  };

  const addGenericModules = (modules, version = 'v1') => {
    const temp = modules;
    Object.entries(temp).forEach(([key]) => {
      if (version !== 'v1') {
        temp[key].nodeDisplayType = 'new';
        if (key === 'condition_uiConfig') temp[key].DRAWER_TYPE = 'condition';
        else if (key === 'condition') {
          temp[key].DRAWER_TYPE = DRAWER_TYPES.condition;
          temp[key].nodeDisplayType = 'condition';
        } else {
          temp[key].DRAWER_TYPE = 'new';
        }
      } else {
        temp[key].nodeDisplayType = 'default';
        temp[key].DRAWER_TYPE = DRAWER_TYPES[key] || DRAWER_TYPES.api_drawer;
      }
    });

    if (temp.start) {
      temp.start.nodeDisplayType = 'input';
      temp.start.DRAWER_TYPE = DRAWER_TYPES.none;
    }

    Object.entries(endStates).forEach(([key, value]) => {
      temp[key] = { ...value };
      temp[key].nodeDisplayType = 'output';
      temp[key].DRAWER_TYPE = DRAWER_TYPES.output;
    });
    return temp;
  };

  const fetchUser = async () => {
    try {
      const userRes = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/user`,
      });
      return {
        isSuccessful: true,
        error: null,
        data: {
          user: userRes.data?.result,
        },
      };
    } catch (error) {
      showErrorAlert({ error, message: AlertCode.error.fetchUser });
      return {
        isSuccessful: true,
        error,
        message: AlertCode.error.fetchUser,
      };
    }
  };

  const fetchModules = async () => {
    try {
      const dataRes = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/workflow/modules`,
        headers: { appId: currentAppId },
      });
      const modules = addGenericModules(dataRes.data.result.modules);
      return modules;
    } catch (error) {
      showErrorAlert({ error, message: AlertCode.error.fetchModules });
    }
  };
  const fetchFormV2Components = async () => {
    try {
      const dataRes = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_LIBRARY_REFERENCES_URL}`,
        withCredentials: false,
      });
      const libraries = dataRes.data;
      dispatch(updateFormV2Components({ libraries }));
      return libraries;
    } catch (error) {
      showErrorAlert({ error, message: AlertCode.error.fetchFormV2Components });
      return {
        isSuccessful: true,
        error,
        message: AlertCode.error.fetchFormV2Components,
      };
    }
  };

  const fetchGlobalConfig = async () => {
    try {
      const dataRes = await axios({
        method: 'GET',
        url: 'https://hv-central-config.s3.ap-south-1.amazonaws.com/gsdk-country-doc-list/countries.json',
        withCredentials: false,
      });
      const globalConfig = dataRes.data;
      const countryDocMappingTemp = {};
      const countryIdNameMapping = {};
      (globalConfig).forEach((config) => {
        const docs = config.documents.map((d) => d.id);
        const docsNames = {};
        config.documents.forEach((d) => {
          docsNames[d.id] = d.name;
        });
        countryDocMappingTemp[config.id] = { documentIds: docs, documentNames: docsNames };
        countryIdNameMapping[config.id] = config.name;
      });
      dispatch(updateGlobalConfig({
        globalConfig,
        countryDocMapping: countryDocMappingTemp,
        countryIdNameMapping,
      }));
    } catch (error) {
      showErrorAlert({ error, message: AlertCode.error.fetchCountries });
    }
  };

  const fetchDocToken = async () => {
    try {
      const userRes = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/onboard/docToken`,
      });
      dispatch(updateDocToken(userRes.data.result));
    } catch (error) {
      showErrorAlert({ error, message: AlertCode.error.docToken });
    }
  };

  const fetchV2Modules = async () => {
    try {
      const dataRes = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/workflow/modules?version=v2`,
        headers: { appId: currentAppId },
      });
      const modules = addGenericModules(dataRes.data.result.modules, 'v2');
      return modules;
    } catch (error) {
      showErrorAlert({ error, message: AlertCode.error.fetchModules });
    }
  };

  const fetchGoogleFontsList = async () => {
    try {
      const response = await fetch(
        'https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyCwVKi9NNd6s0hTO8bdt5o_lFOA13P7YZM',
      );
      const data = await response.json();
      const fonts = data.items.map((item) => ({
        value: item.family,
        text: item.family,
      }));
      dispatch(updateSupportedFonts({ fonts }));
    } catch (error) {
      showErrorAlert({ error, message: AlertCode.error.fetchGoogleFonts });
    }
  };

  const fetchCustomTextConfig = async (id, language) => {
    try {
      const dataRes = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/workflow/textConfig`,
        headers: { appId: currentAppId },
        data: { workflowId: id, language },
      });
      const customTextConfig = dataRes.data.result;

      return customTextConfig;
    } catch (error) {
      // showErrorAlert({ error, message: AlertCode.error.fetchTextConfig });
    }
  };

  const fetchCustomUiConfig = async (id) => {
    try {
      const dataRes = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/workflow/uiConfig`,
        headers: { appId: currentAppId },
        data: { workflowId: id },
      });
      const customUiConfig = dataRes.data.result;
      return customUiConfig;
    } catch (error) {
      // showErrorAlert({ error, message: AlertCode.error.fetchUiConfig });
    }
  };

  const getDefaultTextConfig = async () => {
    try {
      const dataRes = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/workflow/defaultTextConfigs`,
        headers: { appId: currentAppId },
      });
      const defaultTextConfigs = dataRes.data.result;
      dispatch(updateDefaultConfigs({ configs: defaultTextConfigs }));
      return defaultTextConfigs;
    } catch (error) {
      showErrorAlert({ error, message: AlertCode.error.fetchDefaultConfigs });
    }
  };

  const getLogoUrl = async (id) => {
    try {
      const dataRes = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/workflow/getFileUrl?category=logos&workflowId=${id}`,
        headers: { appId: currentAppId },
      });
      // const defaultTextConfigs = dataRes.data.result;
      // dispatch(updateDefaultConfigs({ configs: defaultTextConfigs }));
      // return defaultTextConfigs;
      const { url } = dataRes.data.result;
      return url;
    } catch (error) {
      showErrorAlert({ error, message: AlertCode.error.fetchDefaultConfigs });
      return null;
    }
  };

  const publishTextConfig = async (workflowId, textConfig, language) => {
    try {
      await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/workflow/uploadTextConfig`,
        headers: {
          appId: currentAppId,
        },
        data: { workflowId, config: textConfig, language },
      });

      return {
        isSuccessful: true,
        error: null,
        message: '',
      };
    } catch (error) {
      return {
        isSuccessful: false,
        error,
        message: AlertCode.error.errorUploadingTextConfig,
      };
    }
  };

  const fetchAppIdToken = async () => {
    try {
      const userRes = await axios({
        method: 'GET',
        headers: { appid: currentAppId || '55a3a6' },
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/user/demoToken`,
      });
      const { token } = userRes.data.result;
      dispatch(updateAppIdKeyToken({ token }));
      return {
        isSuccessful: true,
        error: null,
        message: '',
      };
    } catch (error) {
      showErrorAlert({ error, message: AlertCode.error.fetchUser });
      return {
        isSuccessful: false,
        error,
        message: AlertCode.error.fetchUser,
      };
    }
  };

  const publishUiConfig = async (workflowId, uiConfig) => {
    try {
      await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/workflow/uploadUiConfig`,
        headers: {
          appId: currentAppId,
        },
        data: { workflowId, config: uiConfig },
      });
      return {
        isSuccessful: true,
        error: null,
        message: '',
      };
    } catch (error) {
      return {
        isSuccessful: false,
        error,
        message: AlertCode.error.errorUploadingUiConfig,
      };
    }
  };

  const uploadLogo = async (id, file) => {
    try {
      const data = new FormData();
      data.append('category', 'logos');
      data.append('workflowId', id);
      data.append('file', file, 'logo');

      const response = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/workflow/uploadFile`,
        headers: {
          appId: currentAppId,
          'Content-Type': 'multipart/form-data',
        },
        data,
      });
      return {
        fileUrl: response?.data?.result?.fileUrl,
      };
    } catch (error) {
      showErrorAlert({ error, message: AlertCode.error.fetchDefaultConfigs });
    }
  };

  const uploadIcon = async (id, file) => {
    const uid = generateUniqueID();
    try {
      const data = new FormData();
      data.append('category', 'icons');
      data.append('workflowId', id);
      data.append('file', file, `icon_${uid}`);

      const response = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/workflow/uploadFile`,
        headers: {
          appId: currentAppId,
          'Content-Type': 'multipart/form-data',
        },
        data,
      });
      return {
        fileUrl: response?.data?.result?.fileUrl,
      };
    } catch (error) {
      showErrorAlert({ error, message: AlertCode.error.fetchDefaultConfigs });
    }
  };
  const uploadLottie = async (id, file) => {
    const uid = generateUniqueID();
    try {
      const data = new FormData();
      data.append('category', 'lotties');
      data.append('workflowId', id);
      const fileName = `lottie_${uid}`;
      const fileWithCorrectMimeType = new File([file], fileName, {
        type: 'application/lottie',
      });
      data.append('file', fileWithCorrectMimeType);

      const response = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/workflow/uploadFile`,
        headers: {
          appId: currentAppId,
          'Content-Type': 'multipart/form-data',
        },
        data,
      });
      return {
        fileUrl: response?.data?.result?.fileUrl,
      };
    } catch (error) {
      showErrorAlert({ error, message: AlertCode.error.errorUploadingLottie });
    }
  };

  const uploadImageFile = async (id, file, fileName) => {
    try {
      const data = new FormData();
      data.append('category', 'images');
      data.append('workflowId', id);
      data.append('file', file, fileName);

      const response = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/workflow/uploadFile`,
        headers: {
          appId: currentAppId,
          'Content-Type': 'multipart/form-data',
        },
        data,
      });
      return {
        fileUrl: response?.data?.result?.fileUrl,
      };
    } catch (error) {
      showErrorAlert({ error, message: AlertCode.error.errorUploadingImage });
    }
  };

  const publishDataForTryWorkflowOnMobile = async (
    workflowConfig,
    textConfig,
    uiConfig,
    sdkInputs,
  ) => {
    try {
      const data = {
        workflowConfig,
        uiConfig,
        textConfig,
      };
      if (sdkInputs) data.sdkInputs = sdkInputs;
      const response = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/workflow/try-on-mobile`,
        headers: {
          appId: currentAppId,
        },
        data,
      });

      return {
        isSuccessful: true,
        error: null,
        data: {
          sessionId: response.data?.result?.sessionId,
        },
      };
    } catch (error) {
      return {
        isSuccessful: false,
        error,
        message: AlertCode.error.errorUploadingTryOnMobileData,
      };
    }
  };

  const fetchModuleByVersion = async (moduleName, moduleVersion) => {
    try {
      const dataRes = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/workflow/module/${moduleName}/${moduleVersion}`,
        headers: { appId: currentAppId },
      });
      return dataRes.data.result;
    } catch (error) {
    // Throw an HVError with a descriptive error code and message
      throw new HVError({
        code: 'moduleFetchFailed',
        message: `Failed to fetch ${moduleName} version ${moduleVersion}: ${error.message}`,
        originalError: error,
      });
    }
  };

  const logoutUser = async () => {
    try {
      await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/user/logout`,
      });
      await persistor.purge();
    } catch (error) {
      showErrorAlert({ error, message: AlertCode.SOMETHING_WENT_WRONG });
    }
  };

  const saveModuleDownloadReasonForm = async (reason, name, workflow, workflowId) => {
    try {
      const formConfig = JSON.parse(process.env.REACT_APP_MODULE_DOWNLOAD_REASON_FORM_CONFIG);
      const { properties = {} } = workflow;
      const { moduleBuilder = {}, name: moduleName = '' } = properties;
      const { subType = '' } = moduleBuilder;
      const requestOptions = { method: 'POST', mode: 'no-cors' };
      const params = new URLSearchParams({
        [`entry.${formConfig.fields.downloadReason}`]: reason,
        [`entry.${formConfig.fields.userName}`]: name,
        [`entry.${formConfig.fields.userEmail}`]: email,
        [`entry.${formConfig.fields.moduleSubType}`]: subType,
        [`entry.${formConfig.fields.moduleName}`]: moduleName,
        [`entry.${formConfig.fields.workflowId}`]: workflowId,
        [`entry.${formConfig.fields.appId}`]: currentAppId,
      });
      const response = await fetch(`${formConfig.url}?${params}`, requestOptions);
      if (response.type !== 'opaque') {
        if (!response.ok) {
          throw new Error(`Response status: ${response.status}`);
        }
      }
      return {
        isSuccessful: true,
        error: null,
        data: {},
      };
    } catch (error) {
      return {
        isSuccessful: false,
        error,
        message: AlertCode.error.errorUploadingTryOnMobileData,
      };
    }
  };

  return {
    getLogoUrl,
    uploadLogo,
    uploadIcon,
    uploadLottie,
    fetchModules,
    fetchGlobalConfig,
    fetchDocToken,
    fetchFormV2Components,
    fetchV2Modules,
    fetchGoogleFontsList,
    fetchCustomTextConfig,
    fetchCustomUiConfig,
    getDefaultTextConfig,
    publishTextConfig,
    publishUiConfig,
    fetchAppIdToken,
    publishWorkflow,
    pushToStaging,
    uploadImageFile,
    publishDataForTryWorkflowOnMobile,
    fetchModuleByVersion,
    fetchUser,
    logoutUser,
    saveModuleDownloadReasonForm,
  };
};

export default useApiHooks;
