import { useEffect, useState, useCallback } from 'react';
import * as Sentry from '@sentry/react';
import HVError, { errorCodes, errorMessages } from '../../utils/error';
import DefaultError from '../../error/defaultError';

const useHandleError = () => {
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!error) return;
    if (error.logToSentry) {
      Sentry.withScope((scope) => {
        scope.setContext('error_details', {
          code: error.code,
          message: error.message,
        });
        scope.setContext('debug_info', error.debugInfo);
        Sentry.captureException(error.originalError);
      });
    }
    if (!error.type) {
      setError(null);
    }
  }, [error]);

  const handleError = useCallback((err) => {
    // err schema: Instance of HVError
    // {
    //     code: 'HV_ERROR',
    //     message: 'Message to show users',
    //     originalError: error,
    //     debugInfo: {
    //       failedOperation: 'deleteNodeFromWorkflow',
    //       ...
    //     },
    //    type: 'default', 'warning', 'debug',
    // }
    if (err instanceof HVError) {
      setError(err);
    } else {
      setError(new DefaultError({
        code: errorCodes.somethingWentWrong,
        message: errorMessages.somethingWentWrong,
        originalError: err,
        debugInfo: {},
      }));
    }
  }, []);

  const resetError = useCallback(() => {
    setError(null);
  }, []);

  return { error, handleError, resetError };
};

export default useHandleError;
