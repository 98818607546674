import PropTypes from 'prop-types';
import { useContext, useMemo, useState } from 'react';
import compareModuleVersions from './utils/compareVersions';
import WarningIcon from '../../../assests/icons/warningIcon.svg';
import SearchIcon from '../../../assests/icons/searchIcon.svg';
import './VersionUpdateModalContent.scss';
import DisplayVersionUpdateModules from './DisplayVersionUpdateModules';
import ModuleVersionUpdateConfirmation from './ModuleVersionUpdateConfirmation';
import moduleVersionComparisonConfig from '../../../constants/moduleVersionComparisonConfig';
import UiActionModal from './UiActionModal';
import WorkflowUpdateConfirmationModal from './WorkflowUpdateConfirmationModal';
import DefaultError from '../../../error/defaultError';
import HVError, { errorCodes, errorMessages } from '../../../utils/error';
import ErrorHandlerContext from '../../../context/ErrorHandlerContext';
import pushEvent from '../../../events/pushEvents';

function VersionUpdateModalContent(props) {
  const {
    modulesTobeUpdated,
    versionedModules,
    selectedModuleIndex,
    selectModuleToBeUpdated,
    handleCloseModuleUpdateModal,
    setHasFetchModuleUpdatesError,
    workflow,
    updateWorkflow,
  } = props;

  const [searchQuery, setSearchQuery] = useState('');
  const [updateConsent, setUpdateConsent] = useState(false);
  const [uiActionForModuleUpdateFailure, setUiActionForModuleUpdateFailure] = useState(null);
  const [workflowUpdateLogs, setWorkflowUpdateReason] = useState(null);

  const handleError = useContext(ErrorHandlerContext);

  const moduleUpdateDetails = useMemo(
    () => {
      try {
        return (modulesTobeUpdated || []).map((module) => {
          const versionedModule = versionedModules[module.subType];
          const currentVersion = module.version;
          const latestVersion = versionedModule?.latestVersion || 'v1';

          const moduleComparisonResults = compareModuleVersions(
            currentVersion,
            latestVersion,
            versionedModule,
          );

          return {
            moduleId: module.id,
            moduleName: module.name || '',
            moduleSubType: module.subType,
            currentVersion,
            latestVersion,
            versionChangeDetails: moduleComparisonResults?.comparisonResults || [],
            showBreakingChangeFlag: moduleComparisonResults?.hasBreakingChange ?? false,
          };
        })
          .filter(
            (module) => module.versionChangeDetails.some((detail) => detail?.logs?.length > 0),
          );
      } catch (error) {
        // TODO: to be handled using global error handler
        setHasFetchModuleUpdatesError(true);
        return [];
      }
    },
    [modulesTobeUpdated, versionedModules],
  );

  const filteredModuleUpdateDetails = useMemo(
    () => (moduleUpdateDetails
      .filter((module) => (module?.moduleName || '').toLowerCase().includes(searchQuery.toLowerCase())
      || (module?.moduleId || '').toLowerCase().includes(searchQuery.toLowerCase()))
    || []),
    [moduleUpdateDetails, searchQuery],
  );

  const shouldShowWarning = useMemo(() => {
    if (selectedModuleIndex !== null) {
      return Boolean(moduleUpdateDetails[selectedModuleIndex]?.showBreakingChangeFlag);
    }
    return (filteredModuleUpdateDetails || []).some((module) => module.showBreakingChangeFlag);
  }, [moduleUpdateDetails, filteredModuleUpdateDetails, selectedModuleIndex]);

  const selectedModuleUpdateDetails =
    selectedModuleIndex !== null ?
      moduleUpdateDetails[selectedModuleIndex] :
      null;

  const onModuleUpdateCancel = () => {
    setUpdateConsent(false);
    selectModuleToBeUpdated(null);
    handleCloseModuleUpdateModal();
  };

  const arePatchedPropertiesPresent = (workflowConfig, moduleId) => {
    const module = workflowConfig.modules.find((m) => m.id === moduleId);
    return Object.keys(module?.patchedProperties || {}).length > 0;
  };

  const onModalClose = () => {
    handleCloseModuleUpdateModal();
    setUiActionForModuleUpdateFailure(null);
    setWorkflowUpdateReason(null);
    setUpdateConsent(false);
    selectModuleToBeUpdated(null);
  };

  const onModuleUpdateConfirm = (
    moduleToBeUpdated,
    moduleVersionComparisonConfigurations,
    workflowConfig,
  ) => {
    pushEvent({
      eventType: 'MODULE_VERSION_UPDATE_INITIATED',
      properties: {
        moduleId: moduleToBeUpdated?.moduleId,
        hasBreakingChanges: moduleToBeUpdated?.showBreakingChangeFlag,
        currentVersion: moduleToBeUpdated?.currentVersion,
        latestVersion: moduleToBeUpdated?.latestVersion,
        changes: (moduleToBeUpdated?.versionChangeDetails || [])
          .map((change) => change.comparisonName),
      },
    });

    try {
      const { versionChangeDetails = [] } = moduleToBeUpdated;

      let uiActionOnFailure = {
        actionName: 'failure',
        data: null,
      };
      const updateLogs = [];

      const versionUpdateSuccess = versionChangeDetails
        .every((changeDetail) => {
          const comparisonConfig = moduleVersionComparisonConfigurations
            .find((comparison) => comparison.comparisonName === changeDetail.comparisonName);

          if (!comparisonConfig.actionFn) return true;
          try {
            const actionResponse = comparisonConfig.actionFn(
              moduleToBeUpdated.moduleId,
              changeDetail.extraData,
              workflowConfig,
            );

            const {
              actionPassed,
              workflowUpdateReason,
              dataForActionFunction,
            } = actionResponse || {};

            // if the action has to make a workflow update
            if (workflowUpdateReason) {
              updateLogs.push(...workflowUpdateReason);
            }

            // if the action failed and there is a ui action to be shown
            if (!actionPassed && comparisonConfig?.actionFailureUI) {
              uiActionOnFailure = {
                actionName: comparisonConfig?.actionFailureUI,
                data: dataForActionFunction,
              };
            }
            return actionPassed;
          } catch (error) {
            uiActionOnFailure = {
              actionName: 'failure',
              data: null,
            };
            return false;
          }
        });

      if (!versionUpdateSuccess) {
        // set the ui action in state if action failed
        setUiActionForModuleUpdateFailure(uiActionOnFailure);
        pushEvent({
          eventType: 'MODULE_VERSION_UPDATE_FAILED',
          properties: {
            moduleId: moduleToBeUpdated?.moduleId,
            currentVersion: moduleToBeUpdated?.currentVersion,
            latestVersion: moduleToBeUpdated?.latestVersion,
            changes: (moduleToBeUpdated?.versionChangeDetails || [])
              .map((change) => change?.comparisonName),
            failureReason: uiActionOnFailure?.actionName,
          },
        });
        return;
      }

      if (arePatchedPropertiesPresent(workflowConfig, moduleToBeUpdated.moduleId)) {
        updateLogs.push(
          <span key={moduleToBeUpdated.moduleId}>
            All
            {' '}
            <b>patched properties</b>
            {' '}
            will be
            {' '}
            <b>removed</b>
            .
          </span>,
        );
      }

      // If workflow was updated, show the workflow update confirmation modal
      if (updateLogs.length > 0) {
        setWorkflowUpdateReason(updateLogs);
        return;
      }

      // update the workflow in state with new module version
      updateWorkflow(selectedModuleUpdateDetails);
    } catch (error) {
      pushEvent({
        eventType: 'MODULE_VERSION_UPDATE_FAILED',
        properties: {
          moduleId: moduleUpdateDetails?.moduleId,
          currentVersion: moduleUpdateDetails?.currentVersion,
          latestVersion: moduleUpdateDetails?.latestVersion,
          changes: (moduleUpdateDetails?.versionChangeDetails || [])
            .map((change) => change?.comparisonName),
          failureReason: error?.message,
        },
      });
      if (error instanceof HVError) handleError(error);
      else {
        handleError(new DefaultError({
          code: errorCodes.errorInApplyingModuleVersionUpdate,
          message: errorMessages.errorUpdatingModuleVersion,
          originalError: error,
          debugInfo: {
            moduleUpdateDetails: moduleToBeUpdated,
          },
        }));
      }
    }
    onModalClose();
  };

  return (
    <>
      <div className="version-update">
        {selectedModuleIndex === null && (
        <div className="version-update__search">
          <img className="version-update__search-icon" src={SearchIcon} alt="Search" />
          <input
            type="text"
            placeholder="Search by module name or module ID"
            className="version-update__search-input"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        )}

        {shouldShowWarning && (
        <div className="version-update__warning">
          <img className="version-update__warning-icon" src={WarningIcon} alt="Results API integration warning" />
          <p className="version-update__warning-text">
            Super-module updates where sub-modules are removed/added could lead to
            results API integration failures. Inform your client of the same if
            they are integrated with results API.
          </p>
        </div>
        )}

        { selectedModuleIndex === null ? (
          <DisplayVersionUpdateModules
            filteredModuleUpdateDetails={filteredModuleUpdateDetails}
            selectModuleToBeUpdated={selectModuleToBeUpdated}
            moduleUpdateDetails={moduleUpdateDetails}
          />
        ) : (
          <ModuleVersionUpdateConfirmation
            selectedModule={selectedModuleUpdateDetails}
            onModuleUpdateCancel={onModuleUpdateCancel}
            updateConsent={updateConsent}
            setUpdateConsent={setUpdateConsent}
            onModuleUpdateConfirm={() => onModuleUpdateConfirm(
              selectedModuleUpdateDetails,
              moduleVersionComparisonConfig,
              workflow,
            )}
          />
        )}
      </div>

      {uiActionForModuleUpdateFailure && (
        <UiActionModal
          uiActionForModuleUpdateFailure={uiActionForModuleUpdateFailure}
          onModalClose={() => setUiActionForModuleUpdateFailure(null)}
        />
      )}
      {workflowUpdateLogs && (
        <WorkflowUpdateConfirmationModal
          workflowUpdates={workflowUpdateLogs}
          onWorkflowUpdateModalClose={() => {
            setWorkflowUpdateReason(null);
            pushEvent({
              eventType: 'MODULE_VERSION_UPDATE_FAILED',
              properties: {
                moduleId: selectedModuleUpdateDetails?.moduleId,
                currentVersion: selectedModuleUpdateDetails?.currentVersion,
                latestVersion: selectedModuleUpdateDetails?.latestVersion,
                failureReason: 'Workflow_updates_cancelled',
                changes: (selectedModuleUpdateDetails?.versionChangeDetails || [])
                  .map((change) => change?.comparisonName),
              },
            });
          }}
          onConfirmWorkflowUpdate={() => {
            updateWorkflow(
              selectedModuleUpdateDetails,
            );
            onModalClose();
          }}
        />
      )}
    </>
  );
}

VersionUpdateModalContent.propTypes = {
  modulesTobeUpdated: PropTypes.array.isRequired,
  versionedModules: PropTypes.object.isRequired,
  selectModuleToBeUpdated: PropTypes.func.isRequired,
  selectedModuleIndex: PropTypes.number,
  handleCloseModuleUpdateModal: PropTypes.func.isRequired,
  setHasFetchModuleUpdatesError: PropTypes.func.isRequired,
  workflow: PropTypes.object.isRequired,
  updateWorkflow: PropTypes.func.isRequired,
};

VersionUpdateModalContent.defaultProps = {
  selectedModuleIndex: null,
};

export default VersionUpdateModalContent;
