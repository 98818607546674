import PropTypes from 'prop-types';
import './ErrorModal.scss';
import DependencyErrorModal from './DependencyErrorModal';
import DefaultAlertModal from './DefaultAlertModal';

function ErrorModal(props) {
  const { error, resetError } = props;
  switch (error?.type) {
    case 'dependencyError':
      return (
        <DependencyErrorModal
          error={error}
          resetError={resetError}
        />
      );
    case 'defaultError':
      return (
        <DefaultAlertModal
          error={error}
          resetError={resetError}
        />
      );
    default:
      return null;
  }
}

export default ErrorModal;

ErrorModal.propTypes = {
  error: PropTypes.object,
  resetError: PropTypes.func.isRequired,
};

ErrorModal.defaultProps = {
  error: null,
};
