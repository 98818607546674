import {
  cloneDeep, get, set,
} from 'lodash';
import { eventHandlers } from '../../components/constants';
import { fetchCurrentValueFromWorkflow, getSelectedModule } from '../../components/ViewWorkflow/InputsToModule/utils/updateWorkflow';
import { formComponentsBasePath } from '../../constants/dynamicFormComponents';
import generateUniqueID from '../../utils/generateUniqueId';
import { extractComponentIdsForModule, replaceAll } from '../../utils/helper';
import HVError, { errorCodes, errorMessages } from '../../utils/error';

export const createNewDomId = () => `hv_form_v2_${generateUniqueID()}`;

export const setNodeIdsInHtmlString = (htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  function nodeToArray(node) {
    // eslint-disable-next-line no-param-reassign
    if (!node?.id) node.id = createNewDomId();
    // eslint-disable-next-line no-restricted-syntax
    for (const child of node.children) {
      nodeToArray(child);
    }
  }
  const rootElement = doc.body;
  // eslint-disable-next-line no-restricted-syntax
  for (const child of rootElement.children) {
    nodeToArray(child);
  }
  return rootElement.innerHTML;
};

export const getFormComponentsFromHtmlString = (htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  const nodeToArray = (node) => {
    const id = node?.id ? node.id : createNewDomId();
    const data = {
      id,
      title: `${node.tagName.toLowerCase()} : ${id}`,
      isContainer: node.tagName.toLowerCase() !== 'span',
    };

    const subComponents = Array.from(node.children).map((child) => nodeToArray(child));
    if (subComponents?.length) data.subComponents = subComponents;
    return data;
  };

  const rootElement = doc.body;
  const result = Array.from(rootElement.children).map((child) => nodeToArray(child));
  return result;
};

export const getFormComponents = (module, rootPath = 'components') => get(module?.properties?.sections?.[0], rootPath, []);

export const getFormHtmlStringForV2 = (module) => module?.properties?.content || '';

export const getComponentFromPath = (components, pathArray) => {
  if (!components?.length || !pathArray?.length) return null;
  if (pathArray.length === 1) return components[pathArray[0]] || null;
  const [currentIndex, ...newPathArray] = pathArray;
  const childComponents = components[currentIndex]?.subComponents;
  return getComponentFromPath(childComponents, newPathArray);
};

export const getSelectedComponent = (module, pathArray, rootPath = 'components') => {
  const components = getFormComponents(module, rootPath);
  if (!components?.length) return null;
  const finalComponent = getComponentFromPath(components, pathArray);
  return finalComponent;
};

const allowedOperations = ['add', 'delete', 'insert', 'update'];

export const operateOnFormComponents = (operation, components, pathArray, newComponent = null) => {
  if (
    !allowedOperations.includes(operation) ||
    typeof components?.length !== 'number' ||
    typeof pathArray?.length !== 'number'
  ) {
    return components;
  }

  const clonnedComponents = cloneDeep(components || []);
  if (pathArray.length === 0 && operation === 'add') {
    clonnedComponents.push(newComponent);
    return clonnedComponents;
  }
  if (pathArray.length === 1 && ['delete', 'insert', 'update'].includes(operation)) {
    if (operation === 'insert') clonnedComponents.splice([pathArray[0]], 0, newComponent);
    if (clonnedComponents?.length > pathArray[0]) {
      if (operation === 'delete') clonnedComponents.splice(pathArray[0], 1);
      if (operation === 'update') clonnedComponents[pathArray[0]] = newComponent;
    }
    return clonnedComponents;
  }
  const [currentIndex, ...newPath] = pathArray;
  const currentSubComponents = clonnedComponents[currentIndex]?.subComponents;
  if (!currentSubComponents) return clonnedComponents;
  const newChildSubComponents = operateOnFormComponents(
    operation,
    currentSubComponents,
    newPath,
    newComponent,
  );
  clonnedComponents[currentIndex].subComponents = newChildSubComponents;
  return clonnedComponents;
};

export const setComponentsArrayAtRootPath = (components, rootPath, module) => {
  const clonnedModule = cloneDeep(module);
  set(clonnedModule.properties.sections[0], rootPath, components);
  return clonnedModule;
};

export const performOpOnWorkflow = (
  operation,
  workflow,
  moduleId,
  pathArray,
  rootPath = 'components',
  newComponent = null,
) => {
  if (!allowedOperations.includes(operation)) return workflow;
  const editedWorkflow = cloneDeep(workflow);
  const selectedModule = getSelectedModule(editedWorkflow, moduleId);
  if (!selectedModule) return editedWorkflow;
  const components = get(selectedModule?.properties?.sections[0], rootPath, []);
  const updatedComponents = operateOnFormComponents(operation, components, pathArray, newComponent);
  set(selectedModule.properties.sections[0], rootPath, updatedComponents);
  return editedWorkflow;
};

// TODO: Deprecate ?
export const getTotalBranches = (components) => {
  let totalBranches = 0;
  (components || []).forEach((component) => {
    eventHandlers.forEach((nextStepEvent) => {
      if (component?.[nextStepEvent]?.nextStep) totalBranches += 1;
    });
    if (component?.subComponents?.length) {
      const totalSubBranches = getTotalBranches(component.subComponents || []);
      totalBranches += totalSubBranches;
    }
  });
  return totalBranches;
};

export const getAllFormComponentsObj = (module) => {
  const basePaths = Object.keys(formComponentsBasePath);
  const componentsObj = {};
  basePaths.forEach((basePath) => {
    componentsObj[basePath] = getFormComponents(module, formComponentsBasePath[basePath]);
  });
  return componentsObj;
};

export const getAllFormComponents = (module) => {
  const allComponentsObj = getAllFormComponentsObj(module);
  const allComponents = Object.values(allComponentsObj).flat();
  return allComponents;
};

export const getNextStepForFormV2Component = (
  componentConfig,
  componentId,
  includeDynamicHandlers = false,
) => {
  const nextSteps = [];
  const eventsConfig = componentConfig?.events || {};
  const events = Object.keys(eventsConfig);
  events.forEach((nextStepEvent) => {
    const currentNextStep = eventsConfig[nextStepEvent]?.nextStep;
    if (currentNextStep) {
      nextSteps.push({
        nextStep: currentNextStep,
        path: `${componentId}.events.${nextStepEvent}.nextStep`,
        key: nextStepEvent,
      });
    }
  });
  const eventsV2Config = componentConfig?.eventsV2 || {};
  const eventsV2 = Object.keys(eventsV2Config);
  eventsV2.forEach((nextStepEvent) => {
    const actions = eventsV2Config[nextStepEvent]?.actions || [];
    const currentNextSteps = actions.map((action, index) => ({
      nextStep: action?.operations?.nextStep,
      path: `${componentId}.eventsV2.${nextStepEvent}.actions.[${index}].operations.nextStep`,
      key: nextStepEvent,
    })).filter((nextStepData) => nextStepData?.nextStep);
    nextSteps.push(...currentNextSteps);
  });

  if (includeDynamicHandlers && Array.isArray(componentConfig?.dynamicHandlers?.handlers)) {
    const handlers = componentConfig?.dynamicHandlers?.handlers || [];
    handlers.forEach((handler, index) => {
      const currentNextStep = handler?.nextStep;
      if (currentNextStep) {
        nextSteps.push({
          nextStep: currentNextStep,
          path: `${componentId}.dynamicHandlers.handlers[${index}].nextStep`,
          key: `handler${index}`,
        });
      }
    });
  }
  return nextSteps;
};

export const getNextStepForComponent = (
  component,
  nextStepEvents = [],
  includeDynamicHandlers = false,
) => {
  const nextSteps = [];
  if (includeDynamicHandlers && Array.isArray(component?.dynamicHandlers?.handlers)) {
    component?.dynamicHandlers?.handlers.forEach((handler, index) => {
      if (handler?.nextStep) {
        nextSteps.push({
          nextStepId: handler.nextStep,
          componentId: component?.id,
          nextStepEvent: `dynamicHandlers.handlers[${index}]`,
        });
      }
    });
  }

  nextStepEvents.forEach((nextStepEvent) => {
    if (component?.[nextStepEvent]?.nextStep) {
      nextSteps.push({
        nextStepId: component?.[nextStepEvent]?.nextStep,
        componentId: component?.id,
        nextStepEvent,
      });
    }
  });
  return nextSteps;
};

export const getAllNextSteps = (
  components,
  nextStepEvents = [],
  includeDynamicHandlers = false,
) => {
  const nextSteps = [];
  (components || []).forEach((component) => {
    const nextStepsOfCurrentComponent = getNextStepForComponent(
      component,
      nextStepEvents,
      includeDynamicHandlers,
    );
    nextSteps.push(...nextStepsOfCurrentComponent);
    if (component?.subComponents?.length) {
      const subNextSteps = getAllNextSteps(
        component.subComponents || [],
        nextStepEvents,
        includeDynamicHandlers,
      );
      nextSteps.push(...subNextSteps);
    }
  });
  return nextSteps;
};

export const canDeleteComponent = (module, pathArray, rootPath) => {
  const components = getFormComponents(module, rootPath);
  const componentToBeDeleted = getComponentFromPath(components, pathArray);
  if (componentToBeDeleted?.type === 'button' && componentToBeDeleted?.onClick?.nextStep) {
    const allComponents = getAllFormComponents(module);
    const totalBranches = getTotalBranches(allComponents);
    return totalBranches > 1;
  }
  return true;
};

const generateRuleString = (component) => {
  let ruleString = ' ';
  ruleString += ` ${component?.required || ' '}`;
  ruleString += ` ${component?.visible || ' '}`;
  ruleString += ` ${component?.enabled || ' '}`;
  const rulesValidation = (component?.validation || []).filter(
    (validation) => validation?.type === 'rule',
  );
  ruleString += rulesValidation.reduce(
    (accumulator, currentValue) => accumulator + currentValue.value,
    ' ',
  );
  return ruleString;
};

const extractFieldsForComponent = (component, moduleId) => {
  const ruleString = generateRuleString(component);
  let fields = extractComponentIdsForModule(ruleString, moduleId);
  fields = fields.filter((field) => field !== component.id);
  const validationFields = fields.filter((field) => ruleString.includes(`${field}.isValid`));
  const changeFields = fields.filter((field) => !ruleString.includes(`${field}.isValid`));
  return { validationFields, changeFields };
};

const getReloadInverseDependency = (components, moduleId) => {
  let dependency = {};
  if (!components?.length) return dependency;
  (components || []).forEach((component) => {
    if (component?.type === 'horizontal' || component?.type === 'vertical') {
      const subComponents = component?.subComponents || [];
      if (subComponents?.length) {
        const subComponentDependency = getReloadInverseDependency(subComponents, moduleId);
        dependency = { ...dependency, ...subComponentDependency };
      }
    }
    const { validationFields, changeFields } = extractFieldsForComponent(component, moduleId);
    if (validationFields.length > 0) {
      dependency[component.id] = {
        ...dependency[component.id],
        onValidated: validationFields,
      };
    }
    if (changeFields.length > 0) {
      dependency[component.id] = {
        ...dependency[component.id],
        onChange: changeFields,
      };
    }
  });
  return dependency;
};
const updateReloadDependency = (components, reloadDependency) => {
  if (!components?.length) return [];
  const clonnedComponents = cloneDeep(components);
  (clonnedComponents || []).forEach((component, index) => {
    if (component?.type === 'horizontal' || component?.type === 'vertical') {
      const subComponents = component?.subComponents || [];
      if (subComponents?.length) {
        const updatedSubComponents = updateReloadDependency(subComponents, reloadDependency);
        clonnedComponents[index].subComponents = updatedSubComponents;
      }
    }

    // Clear previous onValidated and onChange

    const reloadDep = reloadDependency[component.id] || {};

    const isFile = clonnedComponents[index].type === 'file';
    const validatedKeys = Object.keys(reloadDep.onValidated || {});
    const onChangeKeys = Object.keys(reloadDep.onChange || {});

    if (isFile) {
      const combinedKeys = [...validatedKeys, ...onChangeKeys];
      if (combinedKeys.length) {
        clonnedComponents[index].onValidated.reloadComponents = combinedKeys;
      }
    } else {
      if (validatedKeys.length) {
        clonnedComponents[index].onValidated = {
          ...clonnedComponents[index].onValidated,
          reloadComponents: validatedKeys,
        };
      }
      if (onChangeKeys.length) {
        clonnedComponents[index].onChange = {
          ...clonnedComponents[index].onChange,
          reloadComponents: onChangeKeys,
        };
      }
    }

    // reload the whole list component
    if (component?.type === 'list') {
      clonnedComponents[index].itemsGenerator.onClick = {
        reload: {
          [component.id]: {},
        },
      };
    }
  });
  return clonnedComponents;
};

const invertDependency = (onReloadInverseDependency) => {
  const onReloadDependency = {};

  Object.keys(onReloadInverseDependency).forEach((dependency) => {
    const depObj = onReloadInverseDependency[dependency];

    // Initialize onValidated and onChange for each dependency
    Object.keys(depObj).forEach((depType) => {
      depObj[depType].forEach((field) => {
        if (!onReloadDependency[field]) {
          onReloadDependency[field] = { onValidated: {}, onChange: {} };
        }

        onReloadDependency[field][depType][dependency] = 'present';
      });
    });
  });

  return onReloadDependency;
};

const getReloadDependency = (components, moduleId) => {
  const onReloadInverseDependency = getReloadInverseDependency(components, moduleId);
  const onReloadDependency = invertDependency(onReloadInverseDependency);
  return onReloadDependency;
};

export const updateDependencyForOnReload = (components, moduleId) => {
  const onReloadDependency = getReloadDependency(components, moduleId);
  const updatedComponents = updateReloadDependency(components, onReloadDependency);
  return updatedComponents;
};

// TODO: Write tests for this
export const updateOnReloadDependencyForModule = (module) => {
  if (module.type !== 'dynamicForm') return module;
  const moduleId = module.id;
  const updatedModule = cloneDeep(module);
  const allComponentsObj = getAllFormComponentsObj(updatedModule);
  const basePaths = Object.keys(allComponentsObj);
  const allComponents = basePaths
    .map((basePath) => allComponentsObj[basePath])
    .reduce((acc, curr) => [...acc, ...curr]);

  const onReloadDependency = getReloadDependency(allComponents, moduleId);

  basePaths.forEach((basePathKey) => {
    const actualPathFromSection = formComponentsBasePath[basePathKey];
    const components = allComponentsObj[basePathKey];
    const updatedComponents = updateReloadDependency(components, onReloadDependency);
    set(updatedModule, `properties.sections[0].${actualPathFromSection}`, updatedComponents);
  });
  return updatedModule;
};

export const getdefaultUIValue = (currUiConfig, defaultUiArray, subType = '') => {
  if (!defaultUiArray?.length) return null;
  const defaultUiObjForSubType = defaultUiArray.find((obj) => obj?.subType === subType);
  if (!defaultUiObjForSubType) return null;
  const { key } = defaultUiObjForSubType;
  let uiValue;
  Object.entries(currUiConfig).forEach(([, sectionValue]) => {
    Object.entries(sectionValue).forEach(([innerKey, innerValue]) => {
      if (innerKey === key) uiValue = innerValue;
    });
  });
  return uiValue;
};

const getKeysAllowedToUpdate = (
  selectedComponentType,
  updateConfig,
) => {
  // All the keys can be updated unless a list is specified
  const allowedAllComponentUpdates = updateConfig?.allowedComponentKeysForSubComponents || null;
  const updateEnabled = updateConfig?.enable === true;
  return {
    keysAllowedForUpdate: allowedAllComponentUpdates?.[selectedComponentType] || [],
    canUpdateAllKeys: (allowedAllComponentUpdates === null && updateEnabled),
  };
};

export const getRootConfig = (editConfigs, basePath) => editConfigs
  .find((config) => config.basePath === basePath)
  ?.allowedOperations?.root || {};

export const getAllowedOperations = (rootConfig) => {
  const {
    update: updateConfig,
    add: addConfig,
    delete: deleteConfig,
    paste: pasteConfig,
  } = rootConfig;
  const { allowTypeEdit = true, allowSubTypeEdit = true } = updateConfig || {};
  return {
    canAddComponent: addConfig?.enable === true,
    canDeleteComponent: deleteConfig?.enable === true,
    canUpdateType: updateConfig?.enable === true && allowTypeEdit,
    canUpdateSubType: updateConfig?.enable === true && allowSubTypeEdit,
    canPasteComponent: pasteConfig?.enable === true,
  };
};

export const getFilteredComponentConfig = (
  allComponentConfigs,
  updateConfig,
  selectedComponentType,
) => {
  const selectedComponentConfig = allComponentConfigs.find(
    (field) => field.type === selectedComponentType,
  );
  const { keysAllowedForUpdate, canUpdateAllKeys } = getKeysAllowedToUpdate(
    selectedComponentType,
    updateConfig,
  );
  const updatedComponentConfig = cloneDeep(selectedComponentConfig);
  if (!canUpdateAllKeys) {
    const updatableKeysHash = keysAllowedForUpdate.map(({ key, value }) => `${key}=${value}`);
    const filteredBrandingKeys = selectedComponentConfig.brandingKeys
      .filter(({ workflowKey, uiKey }) => {
        const hash = `${workflowKey ? 'workflowKey' : 'uiKey'}=${workflowKey || uiKey}`;
        return updatableKeysHash.includes(hash);
      });
    updatedComponentConfig.brandingKeys = filteredBrandingKeys;
  }
  return updatedComponentConfig;
};

export const findFirstGreaterIndex = (fromPathArray, toPathArray) => {
  const minLength = Math.min(fromPathArray.length, toPathArray.length);
  for (let index = 0; index < minLength; index += 1) {
    if (fromPathArray[index] > toPathArray[index]) {
      return index;
    }
    if (fromPathArray[index] < toPathArray[index]) {
      return null;
    }
  }
  return null;
};

export const checkInValidOperation = (fromPathArray, toPathArray) => {
  const minLength = Math.min(fromPathArray.length, toPathArray.length);
  for (let index = 0; index < minLength; index += 1) {
    if (fromPathArray[index] !== toPathArray[index]) {
      return false;
    }
  }
  return true;
};

export const computeFinalDragPath = (components, fromPathArray, toPathArray) => {
  // we need to update the toPathArray in case if we are moving an element from a place
  // on changing which it changes the final position of toPathArray as well. So we used
  // the differing index to find that index
  let updatedPathArray = [...toPathArray];
  const component = getComponentFromPath(components, toPathArray);
  const hasEqualLength = fromPathArray.length === updatedPathArray.length;
  const isLastElementSmaller =
  fromPathArray[fromPathArray.length - 1] < updatedPathArray[updatedPathArray.length - 1];
  const isHorizontalOrVerticalContainer = component.type === 'horizontal' || component.type === 'vertical';
  const draggingIntoContainer = fromPathArray.length < updatedPathArray.length;
  if (draggingIntoContainer) {
    const differingIndex = findFirstGreaterIndex(updatedPathArray, fromPathArray);
    if (differingIndex !== -1 && differingIndex === fromPathArray.length - 1) {
      updatedPathArray[differingIndex] -= 1;
    }
  }
  const isInValidOperation = draggingIntoContainer &&
  checkInValidOperation(fromPathArray, toPathArray);
  if (isInValidOperation) {
    return null;
  }
  if ((hasEqualLength && isLastElementSmaller) && isHorizontalOrVerticalContainer) {
    updatedPathArray[updatedPathArray.length - 1] -= 1;
  }
  if (isHorizontalOrVerticalContainer && component) {
    if (component?.subComponents?.length === 0) {
      updatedPathArray = updatedPathArray.concat(0);
    }
  }

  return updatedPathArray;
};

export const getModuleFromId = (workflow, moduleId) => workflow.modules.find(
  (workflowModule) => workflowModule.id === moduleId,
);

const getNewNode = (tagName, id, nodeProperties) => {
  const element = document.createElement(tagName);
  Object.entries(nodeProperties || {}).forEach(([key, value]) => {
    element[key] = value;
  });
  element.id = id;
  return element;
};

const updateNodeIds = (domNode, map) => {
  if (domNode) {
    const newId = createNewDomId();
    // eslint-disable-next-line no-param-reassign
    map[domNode.id] = newId;
    // eslint-disable-next-line no-param-reassign
    domNode.id = newId;
    // eslint-disable-next-line no-restricted-syntax
    for (const child of domNode.children) {
      updateNodeIds(child, map);
    }
  }
};

const createNodeFromHtmlString = (htmlString, map) => {
  // Create a new HTML node
  const template = document.createElement('template');
  template.innerHTML = htmlString.trim();
  const newNode = template.content.firstChild;
  const clonedNode = newNode.cloneNode(true);
  updateNodeIds(clonedNode, map);
  return clonedNode;
};

export const addNodeFromHtmlString = (
  domId,
  htmlString,
  htmlFragmentString = null,
  newNodeData = {
    nodeType: 'span',
    nodeProperties: {
      textContent: 'text-goes-here',
    },
  },
) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const element = doc.getElementById(domId);
  const rootElement = doc.body;
  let newNode = null;
  const map = {};
  if (htmlFragmentString) {
    newNode = createNodeFromHtmlString(htmlFragmentString, map);
  } else {
    newNode = getNewNode(newNodeData.nodeType, createNewDomId(), newNodeData.nodeProperties);
  }

  if (element) {
    element.appendChild(newNode);
  } else if (domId === null) {
    rootElement.appendChild(newNode);
  }
  return { html: rootElement.innerHTML, map, newNodeId: newNode.id };
};

const getAllChildNodeIds = (element) => {
  const childIds = [element?.id];
  const childNodes = element.getElementsByTagName('*');
  for (let i = 0; i < childNodes.length; i += 1) {
    if (childNodes[i].id) {
      childIds.push(childNodes[i].id);
    }
  }
  return childIds.filter((id) => id?.length);
};

export const deleteNodeFromHtmlString = (domId, htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const element = doc.getElementById(domId);
  let deletedNodeIds = [];
  if (element) {
    deletedNodeIds = getAllChildNodeIds(element);
    element.remove();
  }
  const rootElement = doc.body;
  return { html: rootElement.innerHTML, deletedNodeIds };
};

export const copyNodeFromHtmlString = (domId, htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const element = doc.getElementById(domId);
  const map = {};
  if (element) {
    const clonedNode = element.cloneNode(true);
    updateNodeIds(clonedNode, map);
    element.insertAdjacentElement('afterend', clonedNode);
  }
  const rootElement = doc.body;
  return { html: rootElement.innerHTML, map };
};

export const getComponentConfigsWithRefreshedIds = (componentConfigs, map) => {
  const copiedConfigs = {};
  Object.keys(map).forEach((oldId) => {
    const newId = map[oldId];
    let configStr = JSON.stringify(componentConfigs[oldId] || {});
    configStr = replaceAll(configStr, oldId, newId);
    const copiedConfig = JSON.parse(configStr);
    copiedConfig.events = {};
    copiedConfigs[newId] = copiedConfig;
  });
  return copiedConfigs;
};

export const copyComponentConfigs = (componentConfigs, map) => {
  const copiedConfigs = getComponentConfigsWithRefreshedIds(componentConfigs, map);
  const clonedComponentConfig = cloneDeep(componentConfigs);
  const updatedConfigs = {
    ...(clonedComponentConfig || {}),
    ...(copiedConfigs || {}),
  };
  // replace all the old
  return updatedConfigs;
};

export const getHTMLStringById = (htmlString, id) => {
  const template = document.createElement('template');
  template.innerHTML = htmlString.trim();
  const element = template.content.getElementById(id);
  return element ? element.outerHTML : null;
};

export const getTagNameByIdFromHtmlString = (htmlString, id) => {
  const template = document.createElement('template');
  template.innerHTML = htmlString.trim();
  const element = template.content.getElementById(id);
  const tagName = element?.tagName?.toLowerCase();
  return tagName;
};

export const getTagNameById = (module, componentId) => {
  const htmlContent = getFormHtmlStringForV2(module);
  return getTagNameByIdFromHtmlString(htmlContent, componentId);
};

export const encodeToBase64 = (input) => btoa(input);

export const decodeFromBase64 = (input) => atob(input);

export const copyContentToClipboard = async (text) => {
  try {
    await navigator.clipboard.writeText(text);
  } catch (error) {
    throw new HVError({
      code: errorCodes.copyToClipboard,
      message: errorMessages.copyToClipboard,
      originalError: error,
    });
  }
};

export const pasteContentFromClipboard = async () => {
  try {
    return navigator.clipboard.readText();
  } catch (error) {
    throw new HVError({
      code: errorCodes.pasteFromClipboard,
      message: errorMessages.pasteFromClipboard,
      originalError: error,
    });
  }
};

export const getDataFromClipboard = async (dataType) => {
  try {
    const dataString = await pasteContentFromClipboard();
    const parsedData = JSON.parse(dataString);
    const { data, dataType: currentDataType } = parsedData;
    if (dataType !== currentDataType) {
      throw new HVError({
        code: errorCodes?.invalidClipboardData,
        message: errorMessages?.invalidClipboardData,
      });
    }
    return data;
  } catch (error) {
    if (error instanceof HVError) throw error;
    throw new HVError({
      code: errorCodes.pasteFromClipboard,
      message: errorMessages.pasteFromClipboard,
      originalError: error,
    });
  }
};

export const getDynamicFormComponentFromClipboard = async () => {
  try {
    const data = await getDataFromClipboard('formComponent');
    return data;
  } catch (error) {
    if (error instanceof HVError) throw error;
    throw new HVError({
      code: errorCodes.pasteFromClipboard,
      message: errorMessages.pasteFromClipboard,
      originalError: error,
    });
  }
};

export const getFormV2ComponentFromClipboard = async () => {
  try {
    const data = await getDataFromClipboard('formComponentV2');
    const { html: htmlFragmentStringEncoded, componentConfigs } = data;
    const htmlFragmentString = decodeFromBase64(htmlFragmentStringEncoded);
    return {
      html: htmlFragmentString,
      componentConfigsFromContextCopy: componentConfigs,
    };
  } catch (error) {
    if (error instanceof HVError) throw error;
    throw new HVError({
      code: errorCodes.pasteFormV2Component,
      message: errorMessages.pasteFormV2Component,
      originalError: error,
    });
  }
};

export const saveDataToClipboard = async (dataType, dataObj) => {
  try {
    const dataToCopyToClipboard = JSON.stringify({
      dataType, data: dataObj,
    });
    await copyContentToClipboard(dataToCopyToClipboard);
    return dataToCopyToClipboard;
  } catch (error) {
    if (error instanceof HVError) throw error;
    throw new HVError({
      code: errorCodes.copyToClipboard,
      message: errorMessages.copyToClipboard,
      originalError: error,
    });
  }
};

export const copyFormComponentDataToClipBoardInV2 = async (module, componentId) => {
  try {
    const htmlContent = getFormHtmlStringForV2(module);
    const componentHTML = getHTMLStringById(htmlContent, componentId);
    const componentConfigs = fetchCurrentValueFromWorkflow(module, 'componentConfigs');
    const data = {
      html: encodeToBase64(componentHTML),
      componentConfigs,
    };
    const dataStr = await saveDataToClipboard('formComponentV2', data);
    return dataStr;
  } catch (error) {
    if (error instanceof HVError) throw error;
    throw new HVError({
      code: errorCodes.copyFormV2Component,
      message: errorMessages.copyFormV2Component,
      originalError: error,
    });
  }
};

export const dragNodeFromHtmlString = (fromDomId, toDomId, htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const draggedElement = doc.getElementById(fromDomId);
  const droppedItem = doc.getElementById(toDomId);
  if (draggedElement && droppedItem) {
    const clonedNode = draggedElement.cloneNode(true);
    droppedItem.insertAdjacentElement('beforebegin', clonedNode);
    draggedElement.remove();
  }
  const rootElement = doc.body;
  return rootElement.innerHTML;
};

export const updateTagNameOfNodeInHtmlString = (tagName, domId, htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const element = doc.getElementById(domId);
  if (element && element?.tagName?.toLowerCase() !== tagName) {
    const newNode = getNewNode(tagName, domId);
    while (element.firstChild) {
      const child = element.firstChild;
      if (child.nodeType === Node.TEXT_NODE) {
        element.removeChild(child);
      } else {
        newNode.appendChild(child);
      }
    }
    element.insertAdjacentElement('afterend', newNode);
    element.remove();
  }
  const rootElement = doc.body;
  return rootElement.innerHTML;
};

export const createReplacementMap = (items, valueKey, value) => items.reduce((acc, item) => {
  const key = item[valueKey];
  // Check if the value is a function, if so, call it with the item
  const finalValue = typeof value === 'function' ? value(item) : value;
  return { ...acc, [key]: finalValue };
}, {});

export const getNewComponentNameInFormV2 = (baseName, componentConfigs = {}) => {
  let maxCountOfBaseName = 0;
  Object.values(componentConfigs).forEach((component) => {
    if (component?.name?.toLowerCase().startsWith(baseName.toLowerCase())) {
      const count = parseInt(
        component.name.toLowerCase().split(baseName.toLowerCase())[1].trim(),
        10,
      );
      if (!Number.isNaN(count)) {
        maxCountOfBaseName = Math.max(maxCountOfBaseName, count);
      }
    }
  });
  return `${baseName.charAt(0).toUpperCase()}${baseName.slice(1)} ${maxCountOfBaseName + 1}`;
};
