import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Tooltip } from '@mui/material';
import addImg from '../../assests/icons/addInsideContainer.svg';
import sideIcon from '../../assests/icons/newChevronRightIcon.svg';
import downIcon from '../../assests/icons/newChevronDownIcon.svg';
import Menu from './Menu';

function ListFormModuleComponentsV2(props) {
  const {
    formComponents,
    getComponentName,
    handleOnClick,
    handleOnDelete,
    handleOnAdd,
    handleOnDrag,
    handleOnCopy,
    handleOnClone,
    selectedComponentPath,
    enableCopyButton,
    enableDeleteButton,
    enableAddButton,
    enableDrag,
    dragState,
    onDragStateChange,
    openStates,
    toggleDropdown,
    contextMenu,
    setContextMenu,
    level,
    parentContainerInfo,
  } = props;
  const handleContextMenu = useCallback(
    (event, component) => {
      event.preventDefault();
      event.stopPropagation();
      setContextMenu({
        mouseX: event.clientX + 2,
        mouseY: event.clientY - 6,
        component,
      });
    },
    [],
  );

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const clickHandler = (pathArr) => {
    handleOnClick(pathArr);
  };
  const addComponentHandler = (componentId) => {
    if (!openStates[componentId]) {
      toggleDropdown(componentId);
    }
    handleOnAdd(componentId);
  };
  const copyClickHandler = (pathArr) => {
    handleOnCopy(pathArr);
    handleCloseContextMenu();
  };

  const deleteClickHandler = (pathArr) => {
    handleOnDelete(pathArr);
    handleCloseContextMenu();
  };
  const handleDragEnd = (event) => {
    event.stopPropagation();
    onDragStateChange({ draggedComponentId: null, draggedOverComponentId: null });
  };
  const handleDragStart = (id) => {
    if (openStates[id]) {
      toggleDropdown(id);
    }
    onDragStateChange({
      draggedComponentId: id,
      draggedOverComponentId: null,
      fromSection: null,
    });
  };
  const handleDragOver = (event, id) => {
    event.preventDefault();
    event.stopPropagation();
    const { dataTransfer } = event;
    dataTransfer.dropEffect = 'move';
    onDragStateChange({ draggedOverComponentId: id });
  };

  const handleDrop = (dropId) => {
    handleOnDrag(dragState.draggedComponentId, dropId);
    handleOnClick(dragState.draggedComponentId);
    onDragStateChange(
      { draggedComponentId: null, draggedOverComponentId: null, fromSection: null },
    );
  };
  const isCurrentComponentSelected = (currentSelectedIndex, currentIndex) => {
    if (currentSelectedIndex === currentIndex) return true;
    return false;
  };
  const renderMenu = () => (
    <Menu
      open={contextMenu !== null}
      onClose={handleCloseContextMenu}
      anchorReference="anchorPosition"
      anchorPosition={
        contextMenu !== null
          ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
          : undefined
      }
    >
      <Menu.Item
        onClick={() => copyClickHandler(contextMenu?.component?.id)}
        disabled={!enableCopyButton || contextMenu?.component?.type === 'list'}
      >
        Copy
      </Menu.Item>
      <Menu.Item
        onClick={() => handleOnClone(contextMenu?.component?.id)}
        disabled={!enableCopyButton}
      >
        Duplicate
      </Menu.Item>
      <Menu.Item
        onClick={(event) => {
          event.stopPropagation(); deleteClickHandler(contextMenu?.component?.id);
        }}
        disabled={!enableDeleteButton}
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="component-list-div-children">
      {
   (formComponents || []).map((component) => {
     const { title, id, isContainer } = component;
     const isDropdownOpen = openStates[component.id] ?? false;

     if (component.subComponents?.length || isContainer === true) {
       return (
         <>
           <div
             onDragOver={(event) => { handleDragOver(event, component.id); }}
             onDrop={(event) => { event.stopPropagation(); handleDrop(component.id); }}
             className="component-individual__container"
           >
             {dragState?.draggedOverComponentId === component.id &&
     (
     <div className="component-list__drag-indicator" /> // Blue bar goes outside and above the main component
     )}
             <div
               className="component-list__border"
               style={{
                 opacity: isDropdownOpen ? '1' : '0',
                 height: isDropdownOpen ? 'calc(100% - 34px)' : '0',
                 marginLeft: `${14 + (level * 20)}px`,
               }}
             />
             <div
               key={`${component.id}`}
               role="menuitem"
               tabIndex={0}
               className={`component-list__item-container${isCurrentComponentSelected(selectedComponentPath, component.id) ? '__active' : ''}${isDropdownOpen ? '__open' : ''}`}
               onKeyUp={() => {}} // currently not handling keyboard events
               draggable={enableDrag}
               onContextMenu={(event) => handleContextMenu(event, component)}
               onDragStart={(event) => { event.stopPropagation(); handleDragStart(component.id); }}
               onDrop={(event) => { event.stopPropagation(); handleDrop(component.id); }}
               onClick={
            (event) => {
              event.stopPropagation(); clickHandler(component.id);
            }
          }
               onDragEnd={handleDragEnd}
             >
               <div className={`container-individual${isCurrentComponentSelected(selectedComponentPath, component.id) ? '__active' : ''}${parentContainerInfo?.isContainerActive ? '__containerActive' : ''}`}>
                 <div className="sub-component-heading" style={{ marginLeft: `${(level) * (20)}px` }}>
                   <button type="submit" className="sub-component-list-div__dropdown-icon" onClick={() => toggleDropdown(component.id)}>
                     {isDropdownOpen
                       ? <img src={downIcon} alt="down" />
                       : <img src={sideIcon} alt="side" />}
                   </button>
                   <div
                     className={`sub-component-list-div__heading${isCurrentComponentSelected(selectedComponentPath, component.id) ? '__active' : ''}`}
                   >
                     {getComponentName(id) || id}
                   </div>
                   <button
                     className="sub-component-add-btn"
                     disabled={!enableAddButton}
                     onClick={
                      (event) => { event.stopPropagation(); addComponentHandler(component.id); }
                    }
                     type="button"
                   >
                     <Tooltip
                       title={(
                         <span>
                           Add Element
                         </span>
              )}
                       arrow
                     >
                       <img src={addImg} alt="add" />
                     </Tooltip>
                   </button>
                 </div>
               </div>
               {isDropdownOpen && (
                 <ListFormModuleComponentsV2
                   formComponents={component?.subComponents || []}
                   getComponentName={getComponentName}
                   handleOnClick={clickHandler}
                   handleOnDelete={deleteClickHandler}
                   handleOnAdd={handleOnAdd}
                   handleOnCopy={copyClickHandler}
                   handleOnClone={handleOnClone}
                   selectedComponentPath={selectedComponentPath}
                   handleOnDrag={(fromComponentId, toComponentId) => {
                     handleOnDrag(fromComponentId, toComponentId);
                   }}
                   enableCopyButton={enableCopyButton}
                   enableDeleteButton={enableDeleteButton}
                   enableAddButton={enableAddButton}
                   enableDrag={enableDrag}
                   dragState={dragState}
                   onDragStateChange={onDragStateChange}
                   openStates={openStates}
                   toggleDropdown={toggleDropdown}
                   contextMenu={contextMenu}
                   setContextMenu={setContextMenu}
                   level={level + 1}
                   parentContainerInfo={{
                     isInsideContainer: isContainer,
                     isContainerActive:
                     isCurrentComponentSelected(selectedComponentPath, component.id)
                     || parentContainerInfo?.isContainerActive,
                   }}
                 />
               )}
             </div>
           </div>
         </>
       );
     }

     return (
       <>
         <div
           onDragOver={(event) => { handleDragOver(event, component.id); }}
           onDrop={(event) => { event.stopPropagation(); handleDrop(component.id); }}
           className={`component-individual${isCurrentComponentSelected(selectedComponentPath, component.id) ? '__active' : ''}${parentContainerInfo?.isInsideContainer ? '__inside-container' : ''}${parentContainerInfo?.isContainerActive ? '__active' : ''}`}
         >
           {
          (dragState?.draggedOverComponentId === component.id) ?
            <div className="component-list__drag-indicator" />
            : null
           }
           <div
             key={`${component.id}`}
             role="menuitem"
             tabIndex={0}
             onContextMenu={(event) => handleContextMenu(event, component)}
             onKeyUp={() => {}} // currently not handling keyboard events
             className={`component-name ${dragState?.draggedComponentId === component.id ? 'dragging' : ''}`}
             onClick={(event) => { event.stopPropagation(); clickHandler(component.id); }}
             draggable={enableDrag}
             style={{ marginLeft: `${(level) * 20}px` }}
             onDragStart={(event) => { event.stopPropagation(); handleDragStart(component.id); }}
             onDragEnd={handleDragEnd}
           >
             <div className={`component-name-label${isCurrentComponentSelected(selectedComponentPath, component.id) ? '__active' : ''}`}>
               {getComponentName(id) || title || id}
             </div>
           </div>
         </div>
       </>
     );
   })
}
      {renderMenu()}
    </div>
  );
}

ListFormModuleComponentsV2.defaultProps = {
  enableDrag: true,
};

ListFormModuleComponentsV2.propTypes = {
  formComponents: PropTypes.array.isRequired,
  getComponentName: PropTypes.func.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  handleOnDelete: PropTypes.func.isRequired,
  handleOnAdd: PropTypes.func.isRequired,
  handleOnDrag: PropTypes.func.isRequired,
  handleOnCopy: PropTypes.func.isRequired,
  handleOnClone: PropTypes.func.isRequired,
  selectedComponentPath: PropTypes.string.isRequired,
  enableCopyButton: PropTypes.bool.isRequired,
  enableDeleteButton: PropTypes.bool.isRequired,
  enableAddButton: PropTypes.bool.isRequired,
  enableDrag: PropTypes.bool,
  dragState: PropTypes.object.isRequired,
  onDragStateChange: PropTypes.func.isRequired,
  openStates: PropTypes.object.isRequired,
  toggleDropdown: PropTypes.func.isRequired,
  contextMenu: PropTypes.object.isRequired,
  setContextMenu: PropTypes.func.isRequired,
  level: PropTypes.number.isRequired,
  parentContainerInfo: PropTypes.shape({
    isInsideContainer: PropTypes.bool.isRequired,
    isContainerActive: PropTypes.bool.isRequired,
  }).isRequired,
};

export default ListFormModuleComponentsV2;
