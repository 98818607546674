const handleInputsRemoved = (moduleId, extraData) => {
  const { removedOrChangedModuleInputs } = extraData;

  if (!removedOrChangedModuleInputs || [].length > 0) {
    return {
      actionPassed: true,
      workflowUpdateReason: null,
      dataForActionFunction: null,
    };
  }

  const updateReasons = [];
  removedOrChangedModuleInputs.forEach((input) => {
    updateReasons.push(
      <span key={input.workflowKey}>
        Input
        {' '}
        <b>
          &quot;
          {input.workflowKey}
          &quot;
        </b>
        {' '}
        will be removed.
      </span>,
    );
  });

  return {
    actionPassed: true,
    workflowUpdateReason: updateReasons,
    dataForActionFunction: null,
  };
};

export default handleInputsRemoved;
