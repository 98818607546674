import HVError, { errorCodes } from '../utils/error';

const logBatchedEvents = async (eventQueue, demoToken) => {
  const finalArrayOfEventsToSend = eventQueue.map((event) => ({
    project: process.env.REACT_APP_APOLLO_PROJECT_NAME || 'workflow-builder-events',
    properties: event,
  }));

  const url =
  process.env.REACT_APP_APOLLO_EVENT_TRACKING_URL ||
  'https://ind-thomas.hyperverge.co/v1/apollo-batch-prod-sdk-wrapper';
  const headers = {
    'Content-Type': 'application/json',
    Authorization: demoToken,
  };
  const response = await fetch(url, {
    keepalive: true,
    method: 'POST',
    headers,
    body: JSON.stringify({ data: finalArrayOfEventsToSend }),
  });
  if (!response.ok) {
    const originalError = new Error('Error sending event');
    originalError.response = response;
    if (response.status === 401) {
      throw new HVError({
        errorCode: errorCodes.invalidDemoToken,
        message: 'Unauthorized demo token',
        originalError,
        debugInfo: {
          failedEvents: eventQueue,
        },
      });
    }
    if (response.status !== 400) {
      throw new HVError({
        errorCode: errorCodes.errorLoggingEvent,
        message: 'Error logging event',
        originalError,
        debugInfo: {
          failedEvents: eventQueue,
        },
      });
    }
    throw new HVError({
      errorCode: errorCodes.invalidEventStructure,
      message: 'Invalid event structure',
      originalError,
      debugInfo: {
        failedEvents: eventQueue,
      },
    });
  }
};

export default logBatchedEvents;
