import { cloneDeep, unset, set } from 'lodash';
import { applyColorSchemeToUiConfig } from '../containers/Branding/EditSdkBranding/utils';
import { errorCodes } from '../utils/error';
import DefaultError from '../error/defaultError';

const deleteModuleFromUiConfig = (uiConfig, moduleId) => {
  const editedUiConfig = cloneDeep(uiConfig);
  delete editedUiConfig[moduleId];
  return editedUiConfig;
};
export const updateFontWeight = (workflow, uiConfig, id, fontWeight) => {
  try {
    const editedUiConfig = cloneDeep(uiConfig);
    editedUiConfig.fontWeight[id] = fontWeight;
    const editedWorkflow = cloneDeep(workflow);
    set(editedWorkflow, 'properties.uiConfigSource', 'custom');
    return { editedUiConfig, editedWorkflow };
  } catch (error) {
    const processedError = new DefaultError({
      code: errorCodes.errorUpdatingFontWeight,
      message: 'Failed to update font weight!',
      originalError: error,
      debugInfo: {},
    });
    return { editedUiConfig: uiConfig, editedWorkflow: workflow, errorData: processedError };
  }
};

export const updateFont = (workflow, uiConfig, id, font) => {
  try {
    const editedUiConfig = cloneDeep(uiConfig);
    editedUiConfig.font[id] = font;
    const editedWorkflow = cloneDeep(workflow);
    set(editedWorkflow, 'properties.uiConfigSource', 'custom');
    return { editedUiConfig, editedWorkflow };
  } catch (error) {
    const processedError = new DefaultError({
      code: errorCodes.errorUpdatingFont,
      message: 'Failed to update font!',
      originalError: error,
      debugInfo: {},
    });
    return { editedUiConfig: uiConfig, editedWorkflow: workflow, errorData: processedError };
  }
};

export const updateColor = (workflow, uiConfig, key, colorValue) => {
  try {
    const editedUiConfig = cloneDeep(uiConfig);
    editedUiConfig.colors[key] = colorValue;
    const editedWorkflow = cloneDeep(workflow);
    set(editedWorkflow, 'properties.uiConfigSource', 'custom');
    return { editedUiConfig, editedWorkflow };
  } catch (error) {
    const processedError = new DefaultError({
      code: errorCodes.errorUpdatingColor,
      message: 'Failed to update color!',
      originalError: error,
      debugInfo: {},
    });
    return { editedUiConfig: uiConfig, editedWorkflow: workflow, errorData: processedError };
  }
};
export const unsetComponentFromUiConfig = (uiConfig, moduleId, componentToBeDeleted) => {
  const editedUiConfig = cloneDeep(uiConfig);
  if (componentToBeDeleted.type === 'list') {
    unset(editedUiConfig, `${moduleId}.${componentToBeDeleted.itemsGenerator.id}`);
    unset(editedUiConfig, `${moduleId}.${componentToBeDeleted.itemsGenerator.subComponents[0].id}`);
  }
  unset(editedUiConfig, `${moduleId}.${componentToBeDeleted.id}`);
  return editedUiConfig;
};

export const updateLogoInUiConfig = (uiConfig, logoUrl) => {
  try {
    if (logoUrl === '') {
      const editedUiConfig = cloneDeep(uiConfig);
      unset(editedUiConfig, 'logos');
      return { uiConfig: editedUiConfig, success: true };
    }
    const editedUiConfig = {
      ...cloneDeep(uiConfig),
      logos: {
        clientLogo: logoUrl,
      },
    };
    return { uiConfig: editedUiConfig, success: true };
  } catch (error) {
    const processedError = new DefaultError({
      code: errorCodes.errorUpdatingLogo,
      message: 'Failed to update logo!',
      originalError: error,
      debugInfo: {},
    });
    return { uiConfig, success: false, errorData: processedError };
  }
};
export const updateColorSchemeInUiConfig = (workflow, colorKeys, colors, uiConfig) => {
  try {
    const editedUiConfig = applyColorSchemeToUiConfig(colorKeys, colors, uiConfig);
    const editedWorkflow = cloneDeep(workflow);
    set(editedWorkflow, 'properties.uiConfigSource', 'custom');
    return { editedUiConfig, editedWorkflow };
  } catch (error) {
    const processedError = new DefaultError({
      code: errorCodes.errorUpdatingColors,
      message: 'Failed to update colors!',
      originalError: error,
      debugInfo: {},
    });
    return { editedUiConfig: uiConfig, editedWorkflow: workflow, errorData: processedError };
  }
};

export const updateIconInUiConfig = (uiConfig, iconUrl, iconKey) => {
  try {
    if (iconUrl === '') {
      const editedUiConfig = cloneDeep(uiConfig);
      unset(editedUiConfig, iconKey);
      return { success: true, uiConfig: editedUiConfig };
    }
    const editedUiConfig = {
      ...cloneDeep(uiConfig),
      icons: {
        ...uiConfig.icons,
        [iconKey]: { url: iconUrl },
      },
    };
    return { uiConfig: editedUiConfig };
  } catch (error) {
    const processedError = new DefaultError({
      code: errorCodes.errorUpdatingIcon,
      message: 'Failed to update icon!',
      originalError: error,
      debugInfo: {},
    });
    return { uiConfig, errorData: processedError };
  }
};
export const updateCustomFontStylesheetUrlsInUiConfig = (workflow, uiConfig, customFonts) => {
  try {
    const editedUiConfig = cloneDeep(uiConfig);
    const editedWorkflow = cloneDeep(workflow);
    set(editedWorkflow, 'properties.uiConfigSource', 'custom');
    editedUiConfig.customFontStylesheetUrls = customFonts;
    return { uiConfig: editedUiConfig, editedWorkflow };
  } catch (error) {
    const processedError = new DefaultError({
      code: errorCodes.errorUpdatingCustomFontStylesheetUrls,
      message: 'Failed to update custom font stylesheet urls!',
      originalError: error,
      debugInfo: {},
    });
    return { uiConfig, errorData: processedError };
  }
};

export default deleteModuleFromUiConfig;
