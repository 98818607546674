import { fetchDependencyOfField } from '../../../../../utils/fetchDependencyList';

const handleOutputVariableRemoved = (moduleId, extraData, workflow) => {
  const dependencies = (extraData.removedOrChangedOutputs || []).map((output) => {
    const deps = fetchDependencyOfField(output.name, moduleId, workflow);
    const hasDeps = Object.values(deps).some((dep) => dep.length > 0);
    if (!hasDeps) return null;
    return {
      name: output.name,
      dependencies: deps,
    };
  }).filter((item) => item !== null);

  const hasDependencies = dependencies.length > 0;

  if (hasDependencies) {
    return {
      actionPassed: false,
      workflowUpdateReason: null,
      dataForActionFunction: dependencies,
    };
  }
  return {
    actionPassed: true,
    workflowUpdateReason: null,
    dataForActionFunction: null,
  };
};

export default handleOutputVariableRemoved;
