import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';

function CreateWorkflowButton({
  onButtonClick,
  isLoading,
  loadingButtonText,
  normalButtonText,
  buttonClassName,
  enableUpload,
  progressStyles,
}) {
  return (
    <button type="button" onClick={onButtonClick} className={buttonClassName} disabled={isLoading || !enableUpload}>
      {
        isLoading
          ? (
            <>
              <CircularProgress size={progressStyles.size || '1rem'} sx={progressStyles.sx || { color: 'white' }} />
              {loadingButtonText}
            </>
          )
          : normalButtonText
      }
    </button>
  );
}

CreateWorkflowButton.propTypes = {
  onButtonClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loadingButtonText: PropTypes.string.isRequired,
  normalButtonText: PropTypes.string.isRequired,
  buttonClassName: PropTypes.string.isRequired,
  enableUpload: PropTypes.bool,
  progressStyles: PropTypes.object,
};

CreateWorkflowButton.defaultProps = {
  enableUpload: true,
  progressStyles: {
    size: '1rem', // Default size
    sx: { color: 'white' }, // Default color
  },
};

export default CreateWorkflowButton;
