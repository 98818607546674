import validateWorkflowSchema from './ImportWorkflowOperations/validateWorkflowSchema';
import fetchAndValidateSuperModules from './ImportWorkflowOperations/fetchAndValidateSuperModules';
import decompileWorkflow from './ImportWorkflowOperations/decompileWorkflow';
import compileAndValidateWorkflow from './ImportWorkflowOperations/compileAndValidateWorkflow';
import updateNextNodeType from './ImportWorkflowOperations/updateNextNodeType';

const validateAndProcessWorkflow = (workflow, versionedModules, moduleBuilderMode) => {
  const operationsForWorkflowBuilder = [
    validateWorkflowSchema,
    fetchAndValidateSuperModules,
    updateNextNodeType,
    decompileWorkflow,
    compileAndValidateWorkflow,
  ];
  const operationsForModuleBuilder = [
    validateWorkflowSchema,
    updateNextNodeType,
    decompileWorkflow,
    compileAndValidateWorkflow,
  ];
  let accumulatedErrorData = [];
  let isSuccess = true;
  const operations = moduleBuilderMode ? operationsForModuleBuilder : operationsForWorkflowBuilder;
  const { workflow: finalWorkflow } = operations.reduce(
    (acc, operation) => {
      if (isSuccess) {
        const result = operation(
          acc.workflow,
          versionedModules,
        );
        if (!result.isSuccess) {
          isSuccess = false;
        }
        accumulatedErrorData = [
          ...accumulatedErrorData,
          ...(result.errorData || []),
        ];

        return {
          workflow: result.workflow,
          errorData: accumulatedErrorData,
        };
      }
      return acc;
    },
    { workflow, errorData: [] },
  );

  return {
    errorData: accumulatedErrorData,
    workflow: finalWorkflow,
    isSuccess,
  };
};

export default validateAndProcessWorkflow;
