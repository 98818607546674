import { cloneDeep, set } from 'lodash';
import { flatten, unflatten } from 'flat';
import { fetchCurrentValueFromWorkflow, getSelectedModule } from '../components/ViewWorkflow/InputsToModule/utils/updateWorkflow';
import generateUniqueID from './generateUniqueId';

export const extractComponentIdsForModule = (ruleString, moduleId) => {
  // TODO: Read in an article where backslash is required if '-' is used within brackets
  // eslint-disable-next-line no-useless-escape
  const pattern = /\b[a-zA-Z_\-][a-zA-Z0-9_\-]*(?:\.[a-zA-Z_\-][a-zA-Z0-9_\-]*)+\b/g;
  const fields = `${ruleString}`.match(pattern) || [];
  const extractedFields = fields.map((field) => {
    const [module, component] = field.split('.');
    return {
      module,
      component,
    };
  });
  const filteredExtractedFileds = extractedFields.filter((field) => (field.module === moduleId));
  const filteredFiled = filteredExtractedFileds.map((field) => field.component);
  return filteredFiled;
};

export const getConditionAndModuleItems = (workflowConfig) => {
  const items = workflowConfig?.modules.filter((module) => module.type !== 'countries').map((module) => (
    {
      id: module.id,
      name: module.name || module.id,
      value: module.id,
    }
  ));
  const conditionItems = Object.entries(workflowConfig?.conditions).map(([id, condition]) => (
    {
      id,
      name: condition.name || id,
      value: id,
    }
  ));
  items.push(...conditionItems);
  return items;
};

export const replaceAll = (baseString, match, replace) => baseString.replaceAll(match, replace);

export const convertDurationToMillis = (duration, currentUnits) => {
  if (currentUnits === 'ms') return duration;
  if (currentUnits === 'secs') return duration * 1000;
  if (currentUnits === 'mins') return duration * 60 * 1000;
  if (currentUnits === 'hours') return duration * 60 * 60 * 1000;
  return duration;
};

export const convertDurationInNewUnits = (durationInMillis, newUnits) => {
  if (newUnits === 'ms') return durationInMillis;
  if (newUnits === 'secs') return durationInMillis / 1000;
  if (newUnits === 'mins') return durationInMillis / 60000;
  if (newUnits === 'hours') return durationInMillis / 3600000;
  return durationInMillis;
};

export const evaluateRuleExpression = (inputObject, ruleString) => {
  try {
    if (typeof ruleString !== 'string') return false;
    if (ruleString.trim() === 'yes') return true;
    if (ruleString.trim() === 'no') return false;
    const keys = Object.keys(inputObject);
    // TODO: Look for other alternate
    // eslint-disable-next-line no-new-func
    const ruleFunction = new Function(`{${keys}}`, `return ${ruleString}`);
    const result = ruleFunction(inputObject);
    return !!result;
  } catch (error) {
    // comes here if rule uses a variable key that is undefined
    return false;
  }
};

export const attachUniqueIdToArrayElements = (arrayData) => {
  if (arrayData === null) return null;
  return arrayData.map((data) => ({
    id: generateUniqueID(),
    data,
  }));
};

export const removeUniqueIdFromArrayElements = (arrayData) => {
  if (arrayData === null) return null;
  return arrayData.map(({ data }) => (data));
};

export const reshapeObjectDataToArrayState = (objData, shouldFlatten = false) => {
  if (objData === null) return null;
  const parsedObjData = shouldFlatten ? flatten(objData) : objData;
  const arrayState = Object.keys(parsedObjData || {}).map((key) => ({
    id: generateUniqueID(),
    key,
    value: parsedObjData[key],
  }));
  return arrayState;
};

export const reshapeArrayStateToObjectData = (arrayState, shouldUnFlatten = false) => {
  if (arrayState === null) return null;
  const objData = {};
  (arrayState || []).forEach(({ key, value }) => {
    objData[key] = value;
  });
  return shouldUnFlatten ? unflatten(objData) : objData;
};

export const evaulateRuleForModule = (rule, module) => {
  let updatedRule = rule.slice();

  // Replace all the variables in rule.
  const pattern = /\s*(&&|\(|\)|===|!==|!=|>=|<=|==|>|<|!|\|\|)\s*/;
  const constantsAndVariables = updatedRule.split(pattern).filter((str) => !(str.match(pattern) || str === ''));
  const isConstant = (str) => (str === 'true' || str === 'false' || str.match(/^'.*'$/) || str.match(/^\d+$/));
  const variables = constantsAndVariables.filter((str) => !isConstant(str));
  const uniqueVariables = [...new Set(variables || [])];

  const replacements = uniqueVariables.map((key) => {
    const value = fetchCurrentValueFromWorkflow(
      module,
      key,
    );
    if (typeof value === 'string') return { key, value: `'${value}'` };
    return { key, value };
  });

  // Rule without variables
  replacements.forEach((replacement) => {
    updatedRule = updatedRule.replaceAll(replacement.key, replacement.value);
  });
  return evaluateRuleExpression({}, updatedRule);
};

export const getDefaultValueFromComponentConfigs = (workflowKey, components) => {
  const selectedComponent = (components || [])
    .find((component) => component.workflowKey === workflowKey);
  if (selectedComponent) {
    const { default: defaultValue } = selectedComponent;
    return typeof defaultValue !== 'undefined' ? defaultValue : null;
  }
  return null;
};

export const getAccessibility = (workflow, components, moduleId) => {
  const isAccessible = {};
  const module = getSelectedModule(workflow, moduleId);
  if (module) {
    components.forEach((component) => {
      const { visible: visibleRule, enabled: enabledRule, workflowKey } = component;
      const key = `${moduleId}[*]${workflowKey}`;
      const isVisible = typeof visibleRule === 'string' ? evaulateRuleForModule(visibleRule, module) : true;
      const isEnabled = typeof enabledRule === 'string' ? evaulateRuleForModule(enabledRule, module) : true;
      isAccessible[key] = {
        visible: isVisible,
        enabled: isEnabled,
      };
    });
  }
  return isAccessible;
};
export const getRequiredModuleVersions = (rawWorkflow) => {
  const requiredModuleVersions = {};
  rawWorkflow.modules.forEach((module) => {
    if (module.superModuleId) {
      requiredModuleVersions[module.superModuleId] = {
        moduleType: module.superModuleType,
        moduleVersion: (
          rawWorkflow
            ?.properties
            ?.builder
            ?.superModuleMetaData
            ?.[module.superModuleId]
            ?.version
        ) || 'v1',
      };
    } else {
      requiredModuleVersions[module.id] = {
        moduleType: module.subType,
        moduleVersion: module.version || 'v1',
      };
    }
  });
  return requiredModuleVersions;
};

export const evaluateInitialStepCondition = (condition, module) => {
  const {
    rule,
    if_false: ifFalse,
    if_true: ifTrue,
  } = condition;
  const isTrue = evaulateRuleForModule(rule, module);
  return isTrue ? ifTrue : ifFalse;
};

export const getInitialModuleOfSuperModule = (initialStepId, library, highlevelModule) => {
  // check if it is a condition
  const { conditions } = library;
  const isInitialStepIdCondition = Object.keys(conditions).includes(initialStepId);
  // If no
  if (!isInitialStepIdCondition) {
    return initialStepId;
  }
  // If yes
  const initialStepConditionRes = evaluateInitialStepCondition(
    library.conditions[initialStepId],
    highlevelModule,
  );
  return getInitialModuleOfSuperModule(initialStepConditionRes, library, highlevelModule);
};

export const maskData = (obj, pathsToMask) => {
  const clonedObj = cloneDeep(obj);
  pathsToMask.forEach((path) => {
    set(clonedObj, path, '--masked--');
  });
  return clonedObj;
};
