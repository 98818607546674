/* eslint-disable no-undef */
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import JsonFormatter from 'react-json-formatter';
import * as ReactDom from 'react-dom';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';
import useApiHooks from '../../hooks/api/useApiHooks';
import compile from '../../compilerDecompiler/compiler';
import WorkflowInputsModal from '../../components/ViewWorkflow/WorkflowInputsModal';
import Modal from '../../components/Common/Modal';
import {
  selectDefaultTextConfig,
  selectFontStyleSheets,
  selectAllCustomTextConfigs,
  selectCustomUIConfig,
  selectSelectedLanguage,
} from '../../reducers/editBranding';
import { compileUiConfig } from '../uiConfigOperations';
import LanguageSelectModal from '../../components/ViewWorkflow/LanguageSelectModal';
import { selectSelectedWorkflow, selectVersionedModules } from '../../reducers/workflow';
import languageCodes from '../../components/Branding/utils/languageCodeMapping';
import DesktopIcon from '../../assests/icons/desktopIcon.svg';
import { formComponentList } from '../../constants/dynamicFormComponents';
import { getNewTransactionId } from '../../utils/generateUniqueId';
import ConfirmForceStopSDK from './ConfirmForceStopSDK';
import ForceStopSDK from './ForceStopSDK';

function TryOnWeb({
  workflowId,
}) {
  const jwtToken = useSelector((state) => state.user.appIdKeyToken);
  const versionedModules = useSelector(selectVersionedModules);
  const selectedWorkflow = useSelector(selectSelectedWorkflow);
  const uiConfig = useSelector(selectCustomUIConfig);
  const selectedLanguage = useSelector(selectSelectedLanguage);
  const customStyleSheetsObj = useSelector(selectFontStyleSheets);
  const customStyleSheets = Object.values(customStyleSheetsObj);
  const customTextConfigList = useSelector(selectAllCustomTextConfigs);
  const defaultTextConfigList = useSelector(selectDefaultTextConfig);

  const [SDKResponse, setSDKResponse] = useState({});
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showLanguageSelectModal, setShowLanguageSelectModal] = useState(false);
  const [language, setLanguage] = useState(selectedLanguage || 'en');
  const { fetchAppIdToken } = useApiHooks();
  const [showWorkflowInputModal, setShowWorkflowInputModal] = useState(false);
  const [inputs, setInputs] = useState(null);
  const [displaySDK, setDisplaySDK] = useState(false);
  const [forceCloseSDKClicked, setForceCloseSDKClicked] = useState(false);

  const languagesSupported = useMemo(() => {
    const textConfigKeys = Object.keys(defaultTextConfigList || {})
      .filter((configName) => configName.endsWith('text_config') && languageCodes[configName.split('_')[1] || '']);
    return textConfigKeys.map((key) => {
      const languageCode = key.split('_')[1] || '';
      return {
        id: languageCode,
        name: languageCodes[languageCode],
        value: languageCode,
      };
    });
  }, [defaultTextConfigList]);

  const cleanup = () => {
    setDisplaySDK(false);
    setInputs(null);
  };

  // Handling the response callback after force stopping the SDK
  useEffect(() => {
    if (forceCloseSDKClicked) {
      cleanup();
      return;
    }

    if (SDKResponse && Object.keys(SDKResponse).length > 0) {
      cleanup();
      setShowResponseModal(true);
    }
  }, [SDKResponse, forceCloseSDKClicked]);

  const callback = (HVResponse) => {
    setSDKResponse(HVResponse);
  };

  const displaySDKResponse = () => {
    const jsonStyle = {
      propertyStyle: { color: 'red' },
      stringStyle: { color: 'green' },
      numberStyle: { color: 'darkorange' },
    };
    return <JsonFormatter json={SDKResponse} tabWith={4} jsonStyle={jsonStyle} jsonClassName={{ className: 'jsonStyles' }} />;
  };

  const comileWorkflow = (rawWorkflow) => {
    const compiledWorkflow = compile(rawWorkflow, versionedModules, formComponentList);
    return compiledWorkflow;
  };

  const launchSDK = async (inputsReceived) => {
    const transactionId = getNewTransactionId();
    const hyperKycConfig = new HyperKycConfig(
      jwtToken,
      workflowId,
      transactionId,
      false,
      null,
      true,
      true,
    );
    const workflow = comileWorkflow(selectedWorkflow);
    const customLanguageTextConfig = customTextConfigList?.[language];
    const defaultLanguageTextConfig = defaultTextConfigList[`default_${language}_text_config`];
    const textConfig = (Object.keys(customLanguageTextConfig || {}).length > 0)
      ? customLanguageTextConfig
      : defaultLanguageTextConfig;

    workflow.properties.textConfigSource = {
      ...(workflow.properties?.textConfigSource || {}),
      language: (Object.keys(customLanguageTextConfig || {}).length > 0) ? 'custom' : 'default',
    };
    // TODO: SDK is not doing the cleanup, Remove post the fix in builder SDK
    window.GlobalWebSDKObject = null;
    hyperKycConfig.resetSDK();
    hyperKycConfig.setUserWorkflow(workflow);
    if (inputsReceived) hyperKycConfig.setInputs(inputsReceived);
    if (uiConfig && Object.keys(uiConfig).length > 0) {
      const compiledUiConfig = compileUiConfig(uiConfig, workflow);
      hyperKycConfig.setUiConfig(compiledUiConfig);
    }
    hyperKycConfig.setLanguageUsed(language);
    hyperKycConfig.setTextConfig(textConfig);

    if (customStyleSheets?.length) {
      customStyleSheets.forEach((customStyleSheet) => {
        hyperKycConfig.setCustomFontStylesheet(customStyleSheet);
      });
    }
    if (selectedWorkflow.properties?.enableServerSideResume
      || selectedWorkflow.properties?.serverSideResume?.enable) {
      // eslint-disable-next-line no-undef
      const uniqueID = HyperKYCModule.createUniqueId();
      hyperKycConfig.setUniqueId(uniqueID);
    }

    await HyperKYCModule.launch(hyperKycConfig, callback);
  };

  const onTryWorkflow = async () => {
    setSDKResponse({});
    setForceCloseSDKClicked(false);
    setDisplaySDK(false);
    setIsLoading(true);
    await fetchAppIdToken();
    setShowLanguageSelectModal(true);
  };

  const createForceCloseElement = () => {
    const forceCloseElement = document.createElement('div');
    forceCloseElement.id = 'force-close-div-modal';
    return forceCloseElement;
  };

  const getForceCloseElement = () => document.querySelector('#force-close-div-modal');

  const removeExistingSDKModal = (modalElements) => {
    Array.from(modalElements).forEach((modal) => {
      modal.remove();
    });
  };

  const renderForceCloseButton = (forceCloseElement) => {
    ReactDom.render(<ForceStopSDK />, forceCloseElement);
  };

  const removeForceCloseElement = () => {
    const forceCloseElement = getForceCloseElement();
    if (forceCloseElement) forceCloseElement.remove();
  };

  const addForceCloseButtonListener = (forceCloseElement, modalElements) => {
    forceCloseElement.addEventListener('click', () => {
      ReactDom.render(<ConfirmForceStopSDK
        onProceed={() => {
          setForceCloseSDKClicked(true);
          removeForceCloseElement();
          removeExistingSDKModal(modalElements);
        }}
        onCloseClick={() => { renderForceCloseButton(forceCloseElement); }}
      />, forceCloseElement);
    });
  };

  const insertForceCloseAfterModals = (forceCloseElement, modalElements) => {
    Array.from(modalElements).forEach((modal) => {
      modal.insertAdjacentElement('afterend', forceCloseElement);
    });
    renderForceCloseButton(forceCloseElement);
  };

  const initializeForceCloseElement = () => {
    if (getForceCloseElement()) return;

    const modalElements = document.getElementsByClassName('hv-modal');

    if (modalElements.length > 0) {
      const forceCloseElement = createForceCloseElement();
      addForceCloseButtonListener(forceCloseElement, modalElements);
      insertForceCloseAfterModals(forceCloseElement, modalElements);
    }
  };

  useEffect(() => {
    if (displaySDK) {
      launchSDK(inputs);
      setTimeout(() => {
        initializeForceCloseElement();
      }, 1000);
    } else {
      removeForceCloseElement();
    }
  }, [displaySDK, inputs]);

  // Handling SDK crashes
  window.addEventListener('error', () => {
    if (!document.querySelector('hv-modal') || !document.querySelector('#force-close-div-modal')) {
      const hvModal = document.getElementsByClassName('hv-modal');
      if (hvModal.length > 0) {
        removeExistingSDKModal(hvModal);
      }
      removeForceCloseElement();
      cleanup();
    }
  });

  return (
    <>
      <Modal isOpen={showResponseModal} onClose={() => setShowResponseModal(false)} headerText="SDK Response:">
        {displaySDKResponse()}
      </Modal>
      <LanguageSelectModal
        languagesSupported={languagesSupported}
        onProceed={() => {
          setShowLanguageSelectModal(false);
          const { properties } = selectedWorkflow;
          if (Object.keys(properties?.inputsRequired || {}).length) {
            setShowWorkflowInputModal(true);
          } else {
            setDisplaySDK(true);
          }
          setIsLoading(false);
        }}
        onChangeLanguage={setLanguage}
        showLanguageSelectModal={showLanguageSelectModal}
        selectedLanguage={language}
        onClose={() => {
          setShowLanguageSelectModal(false);
          setIsLoading(false);
        }}
      />

      {
        showWorkflowInputModal
          ? (
            <WorkflowInputsModal
              onClose={() => setShowWorkflowInputModal(false)}
              inputsRequired={selectedWorkflow.properties.inputsRequired}
              onSaveInputs={(inputsReceived) => {
                setShowWorkflowInputModal(false);
                setInputs(inputsReceived);
                setDisplaySDK(true);
              }}
            />
          ) : null
      }
      <div>
        <Button className="try-workflow__option" onClick={onTryWorkflow} disabled={isLoading}>
          <img className="try-workflow__option-icon" src={DesktopIcon} alt="Desktop Icon" />
          <span className="try-workflow__option-text">Web</span>
          {
            isLoading
              ? <CircularProgress size="1rem" sx={{ color: 'rgba(5, 5, 82, 0.8)' }} />
              : null
          }
        </Button>
      </div>
    </>
  );
}

TryOnWeb.propTypes = {
  workflowId: PropTypes.string.isRequired,
};

export default TryOnWeb;
