import { workflowSchema } from '../../../schemas/workflowSchema';

const validateWorkflowSchema = (workflow) => {
  const { error } = workflowSchema.validate(workflow);
  const issues = (error?.details || []).map((detail) => ({
    description: `Workflow Schema error: ${detail?.message}, <<path: ${detail?.path}>>`,
  }));
  const errorData = issues.length > 0 ? [{
    errorCode: 'errorInWorkflowSchema',
    errorDetails: issues,
  }] : [];

  if (errorData.length > 0) {
    return {
      errorData, isSuccess: false,
    };
  }

  return {
    workflow, isSuccess: true,
  };
};

export default validateWorkflowSchema;
