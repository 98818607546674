import { validateWorkflow } from '../../../workflowOperations/updateWorkflow';
import AlertCode from '../../../constants/alertCodes';
import compile from '../../../compilerDecompiler/compiler';
import { formComponentList } from '../../../constants/dynamicFormComponents';
import replaceDeletedStepsWithApprove from './utils/replaceDeletedStepsWithApprove';

const compileAndValidateWorkflow = (currentWorkflow, newVersionedModules) => {
  const { code, validationResults } = validateWorkflow(currentWorkflow);
  let errorDetails = [];
  let errorData = [];

  if (validationResults && validationResults.length > 0) {
    errorDetails = validationResults.map((result) => ({ description: result }));
  } else if (code) {
    errorDetails.push({
      description: AlertCode.error[code] || `Unknown error for code ${code}`,
    });
  }

  const structuredErrorData = code
    ? { errorCode: code, errorDetails }
    : null;
  let isSuccess = true;
  const deletedSteps = code === 'undefinedConditionOrModule' ? validationResults : null;
  if (code && code !== 'undefinedConditionOrModule') {
    isSuccess = false;
  }
  const compiledWorkflow = compile(currentWorkflow, newVersionedModules, formComponentList);
  const { workflow: finalWorkflow, errorData: updatedErrorData } =
      replaceDeletedStepsWithApprove(compiledWorkflow, deletedSteps);
  errorData = [
    ...(structuredErrorData ? [structuredErrorData] : []),
    ...updatedErrorData,
  ];

  return {
    workflow: finalWorkflow,
    errorData,
    isSuccess,
  };
};

export default compileAndValidateWorkflow;
