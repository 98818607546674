import { operateOnWorkflow } from '.';

const operateBulkUpdatesInWorkflow = (workflow, action, uiConfig = {}) => {
  let editedWorkflow = workflow;
  let editedUiConfig = uiConfig;
  const newExtraData = {};

  const { actionData } = action || {};
  const { workflowUpdateActions = [] } = actionData || {};

  const isWorkflowUpdated = workflowUpdateActions.every((actionUnit) => {
    const {
      workflow: updatedWorkflow,
      uiConfig: updatedUiConfig,
      success,
      extraData,
    } = operateOnWorkflow(editedWorkflow, actionUnit, editedUiConfig);
    editedWorkflow = updatedWorkflow;
    editedUiConfig = updatedUiConfig;

    if (actionUnit.operation && extraData) {
      newExtraData[actionUnit.operation] = extraData;
    }

    return success;
  });
  return {
    workflow: editedWorkflow,
    uiConfig: editedUiConfig,
    success: isWorkflowUpdated,
    extraData: newExtraData,
  };
};

export default operateBulkUpdatesInWorkflow;
