const TYPE_TO_SUBTYPE_MAP = {
  api: { subType: 'genericApiV1', version: 'v1' },
  webview: { subType: 'genericWebviewV1', version: 'v1' },
  dynamicForm: { subType: 'form', version: 'v1' },
  dynamicFormV2: { subType: 'formV2', version: 'v1' },
  document: { subType: 'id_card_validation', version: 'v1' },
  face: { subType: 'selfie_validation', version: 'v1' },
};

export default TYPE_TO_SUBTYPE_MAP;
