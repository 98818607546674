/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const eventsSlice = createSlice({
  name: 'events',
  initialState: {
    eventQueue: [],
  },
  reducers: {
    pushEvent: (state, action) => {
      const { event } = action.payload;
      state.eventQueue = [...state.eventQueue || [], event];
    },
    clearEventQueue: (state) => {
      state.eventQueue = [];
    },
  },
});

export const selectEventQueue = (state) => state.events.eventQueue;

export const {
  pushEvent,
  clearEventQueue,
} = eventsSlice.actions;

export default eventsSlice.reducer;
