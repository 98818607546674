const allowedErrorCodes = {
  inValidMappingId: 'INVALID_MAPPING_ID',
  errorCreatingModuleObj: 'ERROR_WHILE_CREATING_MODULE_OBJ',
  exitNodeIdNotFound: 'EXIT_NODE_ID_NOT_FOUND',
  multipleExitPointsFound: 'MULTIPLE_EXIT_POINTS_FOUND',
  exitPointFromInvalidEventHandler: 'EXIT_POINT_FROM_INVALID_EVENT_HANDLER',
  initialStepNotFound: 'INITIAL_STEP_NOT_VALID',
  multipleNextStepsFound: 'MULTIPLE_NEXT_STEPS_FOUND',
  noExitNodeFound: 'NO_EXIT_NODE_FOUND',
};
export default allowedErrorCodes;
