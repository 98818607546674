import { useContext, useEffect, useState } from 'react';
import './NewConditionDrawer.scss';
import { useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import {
  selectSelectedNode,
  selectSelectedWorkflow, selectOrderOfNodes, selectVersionedModules,
} from '../../../reducers/workflow';
import { updateWorkflowInState } from '../../../workflowOperations/updateWorkflow';
import ConditionsDrawer from '../../Conditions/ConditionsDrawer';
import { createNestedMenuData } from '../NestedDropdown/utils';
import {
  getConditionalVariables, getModuleOutputs, getWorkflowInputVariables, getPredefinedValues,
} from '../InputsToModule/utils';
import TextInput from '../../Common/TextInput';
import { workflowOperationsObj } from '../../../workflowOperations';
import { formComponentList as formComponentsConfig } from '../../../constants/dynamicFormComponents';
import { selectModuleBuilderMode } from '../../../reducers/moduleBuilder';
import DropDown from '../DropDown';
import { createParentMap } from '../../../utils/gotoUtils';
import { getParentNodes } from '../../../utils/workflowTraversal';
import createMenuData from './utils';
import ErrorHandlerContext from '../../../context/ErrorHandlerContext';

function NewConditionDrawer() {
  const orderOfNodes = useSelector(selectOrderOfNodes);
  const selectedNodeId = useSelector(selectSelectedNode).id;
  const selectedWorkflow = useSelector(selectSelectedWorkflow);
  const versionedModules = useSelector(selectVersionedModules);
  const moduleBuilderMode = useSelector(selectModuleBuilderMode);
  const existingModuleProperties = selectedWorkflow?.properties?.moduleBuilder?.builderProperties
    || {};
  const [showConditionEditor, setShowConditionEditor] = useState(false);
  const [rule, setRule] = useState(selectedWorkflow?.conditions[selectedNodeId]?.rule);

  const handleError = useContext(ErrorHandlerContext);

  const handleAddConditionClick = () => {
    setShowConditionEditor(true);
  };

  const handleClose = () => {
    setShowConditionEditor(false);
  };

  const onSwapBranch = () => {
    try {
      updateWorkflowInState({}, true, {
        operation: workflowOperationsObj.SWAP_CONDITION_BRANCHES,
        actionData: {
          targetNodeId: selectedNodeId,
        },
      });
    } catch (error) {
      handleError(error);
    }
  };

  const onEditReason = (type, value) => {
    try {
      updateWorkflowInState({}, true, {
        operation: workflowOperationsObj.SET_CONDITION_REASON,
        actionData: {
          targetNodeId: selectedNodeId,
          branch: type,
          reason: value,
        },
      });
    } catch (error) {
      handleError(error);
    }
  };
  const onSelectResumeFrom = (type, selectedParent) => {
    try {
      updateWorkflowInState({}, true, {
        operation: workflowOperationsObj.SET_CONDITION_RESUME_FROM,
        actionData: {
          targetNodeId: selectedNodeId,
          branch: type,
          value: selectedParent,
        },
      });
    } catch (error) {
      handleError(error);
    }
  };
  useEffect(() => {
    try {
      updateWorkflowInState({}, true, {
        operation: workflowOperationsObj.SET_CONDITION_RULE,
        actionData: {
          targetNodeId: selectedNodeId,
          rule,
        },
      });
    } catch (error) {
      handleError(error);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rule]);

  useEffect(() => {
    const condition = selectedWorkflow.conditions[selectedNodeId];
    setRule(condition.rule);
  }, [selectedNodeId, orderOfNodes, selectedWorkflow.conditions]);

  const moduleOutputs = getModuleOutputs(
    orderOfNodes,
    selectedNodeId,
    selectedWorkflow,
    formComponentsConfig,
    versionedModules,
  );

  const workflowConfig = cloneDeep(selectedWorkflow);
  const parentMap = createParentMap(workflowConfig);
  const parents = getParentNodes(selectedNodeId, parentMap, selectedWorkflow);
  const filteredParents = parents.filter((parent) => !parent.includes('condition'));
  const conditionalVariables = getConditionalVariables(selectedWorkflow);
  const { conditions } = selectedWorkflow;
  const selectedCondition = conditions[selectedNodeId];
  const terminalNodeForIfTrueIncludesDecline = selectedCondition.if_true === 'decline' || selectedCondition.if_true === 'auto_declined';
  const terminalNodeForIfFalseIncludesDecline = selectedCondition.if_false === 'decline' || selectedCondition.if_false === 'auto_declined';
  const parentModules = selectedWorkflow.modules
    .filter((module) => filteredParents.includes(module.id));
  const transformedParentModules = parentModules.map((item) => ({
    label: item.name,
    value: item.id,
    displayLabel: item.name,
  }));
  const menuData = createMenuData(transformedParentModules, parentModules);

  const workflowInputs = getWorkflowInputVariables(selectedWorkflow);

  const preDefinedValues = getPredefinedValues(selectedWorkflow, formComponentsConfig);

  const dropDownOptions = createNestedMenuData(
    workflowInputs,
    conditionalVariables,
    moduleOutputs,
    moduleBuilderMode ? existingModuleProperties : null,
  ).items;

  return (
    <div className="condition-properties-div">
      <div className="condition-properties-div__title">Branches</div>
      <button
        type="button"
        onClick={handleAddConditionClick}
        className="condition-properties-div__condition-button"
      >
        if (Branch 1)
      </button>
      <button
        type="button"
        className="condition-properties-div__condition-button"
        disabled
      >
        else (Branch 2)
      </button>

      <button
        type="button"
        className="condition-properties-div__swap-button"
        onClick={onSwapBranch}
      >
        Swap branches
      </button>
      { terminalNodeForIfTrueIncludesDecline && (
      <div className="title-and-dropdowns">
        <h2 className="title-and-dropdowns__title">If true branch is auto_declined resume from the following node</h2>
        <div className="title-and-dropdowns__dropdowns">
          <DropDown
            key={`${selectedNodeId}_if_true`}
            allowCustomInput={false}
            textBoxPlaceholder="value"
            onChange={(value) => onSelectResumeFrom('if_true', value.value)}
            items={menuData}
            noSelectLabel="Select a value"
            maxLength={45}
            defaultValue={selectedWorkflow.conditions[selectedNodeId]?.ifTrueConfigs?.resumeFrom
              || null}
          />
        </div>
      </div>
      )}
      { terminalNodeForIfFalseIncludesDecline && (
      <div className="title-and-dropdowns">
        <h2 className="title-and-dropdowns__title">If false branch is auto_declined resume from the following node</h2>
        <div className="title-and-dropdowns__dropdowns">
          <DropDown
            key={`${selectedNodeId}_if_false`}
            allowCustomInput={false}
            textBoxPlaceholder="value"
            onChange={(value) => onSelectResumeFrom('if_false', value.value)}
            items={menuData}
            noSelectLabel="Select a value"
            maxLength={45}
            defaultValue={selectedWorkflow.conditions[selectedNodeId]?.ifFalseConfigs?.resumeFrom
              || null}
          />
        </div>
      </div>
      )}
      <TextInput
        key={`${selectedNodeId}_if_true`}
        label="If true reason"
        setDefaultValue
        placeholder="Type if true reason"
        onSave={(value) => onEditReason('if_true', value)}
        defaultInput={
          selectedWorkflow.conditions[selectedNodeId]?.ifTrueConfigs?.reason
          || selectedWorkflow.conditions[selectedNodeId].if_true_reason
          || ''
        }
      />
      <TextInput
        key={`${selectedNodeId}_if_false`}
        label="If false reason"
        setDefaultValue
        placeholder="Type if false reason"
        onSave={(value) => onEditReason('if_false', value)}
        defaultInput={
          selectedWorkflow.conditions[selectedNodeId]?.ifFalseConfigs?.reason
          || selectedWorkflow.conditions[selectedNodeId].if_false_reason
          || ''
        }
      />

      {showConditionEditor && (
      <ConditionsDrawer
        onClose={handleClose}
        rule={rule}
        dropDownOptions={dropDownOptions}
        onConditionSave={(value) => {
          setRule(value);
          setShowConditionEditor(false);
        }}
        allowDefaultRules
        preDefinedValues={preDefinedValues}
      />
      )}
    </div>
  );
}

export default NewConditionDrawer;
