import TYPE_TO_SUBTYPE_MAP from '../../../../constants/typeToDefaultSubTypeMap';
import updateModuleSubtypes from '../../../../utils/updateModuleSubtype';

const validateAndUpdateModulesSubtypes = (
  workflow,
  versionedModules,
) => {
  const updatedModules = updateModuleSubtypes(
    workflow.modules,
    versionedModules,
  );
  const subtypeErrorItems = updatedModules
    .map((module) => {
      const originalModule = workflow.modules.find((orig) => orig.id === module.id);
      if (module.subType !== originalModule?.subType) {
        return {
          description: `SubType <<${originalModule?.subType}>> for ${originalModule?.id} is changed to <<${TYPE_TO_SUBTYPE_MAP[module.type].subType}>>`,
        };
      }
      return null;
    })
    .filter(Boolean);
  const updatedWorkflow = {
    ...workflow,
    modules: updatedModules,
  };
  const errorData = [];
  if (subtypeErrorItems.length > 0) {
    errorData.push({
      errorCode: 'moduleSubtypeChange',
      errorDetails: subtypeErrorItems,
    });
  }
  return { workflow: updatedWorkflow, errorData };
};

export default validateAndUpdateModulesSubtypes;
