const findMissingSuperModuleIds =
  (
    modules,
    versionedModules,
    superModuleMetaData,
  ) => {
    const missingSuperModuleErrors = [];
    const missingSuperModuleVersionErrors = [];
    const unfetchedSuperModuleIds = modules
      .filter((module) => {
        if (module.superModuleType) {
          const moduleVersion = superModuleMetaData?.[module.superModuleId]?.version || 'v1';
          const isMissingSuperModuleType = !versionedModules[module.superModuleType];
          const isMissingModuleVersion =
      !isMissingSuperModuleType && !versionedModules[module.superModuleType][moduleVersion];

          if (isMissingSuperModuleType) {
            missingSuperModuleErrors.push({
              description: `SuperModule type '<<${module.superModuleType}>>' with ID '<<${module.superModuleId}>>' is missing.`,
            });
          } else if (isMissingModuleVersion) {
            missingSuperModuleVersionErrors.push({
              description: `Version '<<${moduleVersion}>>' for SuperModule type '<<${module.superModuleType}>>' with ID '<<${module.superModuleId}>>' is missing.`,
            });
          }
          return isMissingSuperModuleType || isMissingModuleVersion;
        }
        return false;
      })
      .map((module) => module.superModuleId);

    return {
      missingSuperModuleErrors,
      missingSuperModuleVersionErrors,
      unfetchedSuperModuleIds,
    };
  };

export default findMissingSuperModuleIds;
