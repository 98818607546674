// TODO: Merge into one object
export const errorCodes = {
  somethingWentWrong: 'SOMETHING_WENT_WRONG',
  errorValidatingSchema: 'ERROR_VALIDATING_SCHEMA',
  invalidModuleConfig: 'INVALID_MODULE_CONFIG',
  unknownSubType: 'UNKNOWN_SUBTYPE',
  copyToClipboard: 'ERROR_COPYING_TO_CLIPBOARD',
  pasteFromClipboard: 'ERROR_PASTING_FROM_CLIPBOARD',
  copyFormV2Component: 'ERROR_COPYING_FORM_V2_COMPONENT_TO_CLIPBOARD',
  pasteFormV2Component: 'ERROR_PASTING_FORM_V2_COMPONENT_FROM_CLIPBOARD',
  invalidClipboardData: 'INVALID_CLIPBOARD_DATA',
  errorReplacingVariables: 'ERROR_REPLACING_VARIABLES',
  inValidMappingId: 'INVALID_MAPPING_ID',
  errorCreatingModuleObj: 'ERROR_WHILE_CREATING_MODULE_OBJ',
  inValidProperty: 'INVALID_SUPER_MODULE_PROPERTY',
  exitNodeIdNotFound: 'EXIT_NODE_ID_NOT_FOUND',
  multipleExitPointsFound: 'MULTIPLE_EXIT_POINTS_FOUND',
  exitPointFromInvalidEventHandler: 'EXIT_POINT_FROM_INVALID_EVENT_HANDLER',
  initialStepNotFound: 'INITIAL_STEP_NOT_VALID',
  multipleNextStepsFound: 'MULTIPLE_NEXT_STEPS_FOUND',
  noExitNodeFound: 'NO_EXIT_NODE_FOUND',
  moduleNotValid: 'MODULE_NOT_FOUND_IN_THE_S3',
  moduleFetchFailed: 'REQUIRED_MODULE_VERSION_IS_NOT_PRESENT_IN_THE_S3',
  joiValidationError: 'JOI_VALIDATION_FAILED',
  failedWorkflowOperation: 'WORKFLOW_OPERATION_FAILED',
  addNewConditionFailed: 'ADD_CONDITION_NODE_FAILED',
  addNewModuleFailed: 'ADD_MODULE_FAILED',
  deleteConditionFailed: 'DELETE_CONDITION_FAILED',
  deleteModuleFailed: 'DELETE_MODULE_FAILED',
  deleteConditionFailedNoEndState: 'DELETE_CONDITION_FAILED_NO_END_STATE',
  deleteFormModuleFailedMoreThanOneBranches: 'DELETE_FORM_MODULE_FAILED_MORE_THAN_ONE_BRANCHES',
  addNewNodeFailed: 'ADD_NEW_NODE_FAILED',
  deleteNodeFailed: 'DELETE_NODE_FAILED',
  errorImportingWorkflow: 'ERROR_IMPORTING_WORKFLOW',
  errorImportingModule: 'ERROR_IMPORTING_MODULE',
  errorValidatingModuleConfig: 'ERROR_VALIDATING_MODULE_CONFIG',
  errorValidatingModuleUiConfig: 'ERROR_VALIDATING_MODULE_UI_CONFIG',
  errorGeneratingWorkflowUiConfigFromModuleConfigs: 'ERROR_GENERATING_WORKFLOW_UI_CONFIG_FROM_MODULE_CONFIGS',
  errorGeneratingWorkflowTextConfigFromModuleConfigs: 'ERROR_GENERATING_WORKFLOW_TEXT_CONFIG_FROM_MODULE_CONFIGS',
  errorGeneratingWorkflowConfigFromModuleConfigs: 'ERROR_GENERATING_WORKFLOW_CONFIG_FROM_MODULE_CONFIGS',
  moduleVersionUpdateFailed: 'MODULE_VERSION_UPDATE_FAILED',
  errorInApplyingModuleVersionUpdate: 'ERROR_IN_APPLYING_MODULE_VERSION_UPDATE',
  orderOfNodesModifiedDuringModuleUpdate: 'ORDER_OF_NODES_MODIFIED_DURING_MODULE_UPDATE',
  errorUpdatingColors: 'ERROR_UPDATING_COLORS',
  errorChangingEndStates: 'ERROR_CHANGING_END_STATES',
  errorUpdatingLogo: 'ERROR_UPDATING_LOGO',
  errorUpdatingIcon: 'ERROR_UPDATING_ICON',
  errorUpdatingLottie: 'ERROR_UPDATING_LOTTIE',
  invalidDemoToken: 'INVALID_DEMO_TOKEN',
  errorLoggingEvent: 'ERROR_LOGGING_EVENT',
  invalidEventStructure: 'INVALID_EVENT_STRUCTURE',
  errorInSettingWorkflowInState: 'ERROR_IN_SETTING_WORKFLOW_IN_STATE',
  errorUpdatingFontWeight: 'ERROR_UPDATING_FONT_WEIGHT',
  errorUpdatingFont: 'ERROR_UPDATING_FONT',
  errorUpdatingColor: 'ERROR_UPDATING_COLOR',
  updateResumeFromFailed: 'UPDATE_RESUME_FROM_FAILED',
  updateNodeNameFailed: 'UPDATE_NODE_NAME_FAILED',
  addFormV2ComponentFailed: 'ADD_FORM_COMPONENT_V2_FAILED',
  deleteFormV2ComponentFailed: 'DELETE_FORM_COMPONENT_V2_FAILED',
  cloneFormV2ComponentFailed: 'CLONE_FORM_COMPONENT_V2_FAILED',
  updateTagNameOfFormV2ComponentFailed: 'UPDATE_TAG_NAME_OF_FORM_COMPONENT_V2_FAILED',
  dragFormV2ComponentFailed: 'DRAG_FORM_COMPONENT_V2_FAILED',
  addFormV2ComponentViaClipboardFailed: 'ADD_FORM_COMPONENT_V2_VIA_CLIPBOARD_FAILED',
  errorUpdatingCustomFontStylesheetUrls: 'ERROR_UPDATING_CUSTOM_FONT_STYLESHEET_URLS',
};

export const errorMessages = {
  errorValidatingSchema: 'Error validating schema',
  somethingWentWrong: 'Something went wrong',
  foundDuplicatesInIds: 'Found duplicates in either of moduleIds, conditionIds or conditionalVariableIds',
  invalidInitialStep: 'Initial step not pointing to a module or condition',
  foundDuplicatesInOutputNames: 'Found duplicates in output names',
  errorGeneratingInputsConfigData: 'Error generating inputs config data',
  errorGeneratingOutputs: 'Error generating outputs',
  errorGeneratingBuilderProperties: 'Error generating builder properties',
  errorGeneratingWorkflowUiConfigFromModuleConfigs: 'Error generating workflow ui config from module configs',
  errorGeneratingWorkflowTextConfigFromModuleConfigs: 'Error generating workflow Text config from module configs',
  errorGeneratingWorkflowConfigFromModuleConfigs: 'Error generating workflow config from module configs',
  errorGeneratingBaseStructureForWorkflowConfig: 'Error generating base structure for workflow config',
  errorCheckingDuplicates: 'Error checking duplicates',
  errorValidatingModuleConfig: 'Error validating module config',
  errorFetchingWorkflow: 'Error fetching workflow',
  errorFetchingModules: 'Error fethcing module',
  unknownSubType: 'Unknown subtype used',
  copyToClipboard: 'Failed to copy to clipboard',
  pasteFromClipboard: 'Failed to paste data from clipboard',
  pasteFormV2Component: 'Failed to paste the form V2 component data from clipboard',
  copyFormV2Component: 'Failed to copy the form V2 component data to clipboard',
  invalidClipboardData: 'Invalid type: Can not paste the copied component',
  errorReplacingVariables: 'Error replacing variables in the workflow',
  inValidMappingId: 'Mapping Id is incorrect or not present',
  errorCreatingModuleObj: 'Error occured while creating module Obj with mappingId as the key and module as value',
  invalidModuleInputs: 'Validation for Module Inputs failed',
  invalidModuleOutputs: 'Validation for Module Outputs failed',
  invalidBuilderProperties: 'Validation for Builder Properties failed',
  multipleExitPointsFound: 'Multiple modules with EXIT_POINT found',
  exitNodeIdNotFound: 'No exit node Id Found',
  moduleNotValid: 'Module not found in the s3',
  moduleFetchFailed: 'Module version not found in the s3',
  errorFetchingModuleUpdates: 'Failed to fetch module update details',
  errorUpdatingModuleVersion: 'Failed to update module version',
  orderOfNodesModifiedDuringModuleUpdate: 'Order of nodes modified during module update',
};
export const specialErrorCodes = new Set([
  'errorInWorkflowSchema',
  'errorInFormComponentSchema',
  'moduleNotValid',
  'moduleFetchFailed',
  'unknownErrorCode',
  'missingModuleSubType',
  'missingModuleVersion',
]);

export const errorCodeToMessageMap = {
  [errorCodes.deleteConditionFailed]: 'Failed to delete condition!',
  [errorCodes.deleteConditionFailedNoEndState]: 'Failed to delete condition since condition doesnot have any endstate!',
  [errorCodes.deleteModuleFailed]: 'Failed to delete module!',
  [errorCodes.deleteFormModuleFailedMoreThanOneBranches]: 'Failed to delete form module since it has more than one branches!',
  [errorCodes.addNewNodeFailed]: 'Failed to add new node!',
  [errorCodes.deleteNodeFailed]: 'Failed to delete node!',
  [errorCodes.errorImportingWorkflow]: 'Failed to import workflow!',
  [errorCodes.errorImportingModule]: 'Failed to import module!',
  [errorCodes.joiValidationError]: 'Failed to validate Joi schema!',
  [errorCodes.errorValidatingModuleConfig]: 'Failed to validate module config!',
  [errorCodes.errorValidatingModuleUiConfig]: 'Failed to validate module ui config!',
  [errorCodes.errorGeneratingWorkflowUiConfigFromModuleConfigs]: 'Failed to generate workflow ui config from module configs!',
  [errorCodes.errorGeneratingWorkflowTextConfigFromModuleConfigs]: 'Failed to generate workflow text config from module configs!',
  [errorCodes.errorGeneratingWorkflowConfigFromModuleConfigs]: 'Failed to generate workflow config from module configs!',
  [errorCodes.moduleVersionUpdateFailed]: 'Failed to update workflow with latest module version!',
  [errorCodes.errorInApplyingModuleVersionUpdate]: 'Failed to apply module version update to workflow!',
  [errorCodes.orderOfNodesModifiedDuringModuleUpdate]: 'Order of nodes modified during module update',
  [errorCodes.errorUpdatingColors]: 'Failed to update colors!',
  [errorCodes.errorChangingEndStates]: 'Failed to change end states!',
  [errorCodes.errorUpdatingLogo]: 'Failed to update logo!',
  [errorCodes.errorUpdatingIcon]: 'Failed to update icon!',
  [errorCodes.errorUpdatingLottie]: 'Failed to update Lottie animation!',
  [errorCodes.invalidDemoToken]: 'Unauthorized demo token',
  [errorCodes.errorInSettingWorkflowInState]: 'Failed to set workflow in state!',
  [errorCodes.errorUpdatingFontWeight]: 'Failed to update font weight!',
  [errorCodes.errorUpdatingFont]: 'Failed to update font!',
  [errorCodes.errorUpdatingColor]: 'Failed to update color!',
  [errorCodes.updateResumeFromFailed]: 'Failed to update resume from in condition!',
  [errorCodes.updateNodeNameFailed]: 'Failed to update node name!',
  [errorCodes.addFormV2ComponentFailed]: 'Failed to add form component!',
  [errorCodes.deleteFormV2ComponentFailed]: 'Failed to delete form component!',
  [errorCodes.cloneFormV2ComponentFailed]: 'Failed to clone form component!',
  [errorCodes.updateTagNameOfFormV2ComponentFailed]: 'Failed to update tag name of form component!',
  [errorCodes.dragFormV2ComponentFailed]: 'Failed to drag form component!',
  [errorCodes.addFormV2ComponentViaClipboardFailed]: 'Failed to add form component via clipboard!',
  [errorCodes.errorUpdatingCustomFontStylesheetUrls]: 'Failed to update custom font stylesheet urls!',
};

class HVError extends Error {
  constructor({
    code = errorCodes.somethingWentWrong,
    message = '',
    originalError = null,
    debugInfo = {},
    logTosentry = true,
    type = '',
  }) {
    super(message);
    this.code = code;
    this.originalError = originalError instanceof Error ? originalError : new Error(message || 'Something went wrong');
    this.debugInfo = debugInfo;
    this.logToSentry = logTosentry;
    this.type = type;
  }
}

export default HVError;
