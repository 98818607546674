const convertSuperModulesToSimpleModules =
(modules, superModuleMetaData) => modules.map((module) => {
  if (superModuleMetaData.includes(module.superModuleId)) {
    const { superModuleId, superModuleType, ...rest } = module;
    return rest;
  }
  return module;
});

export default convertSuperModulesToSimpleModules;
