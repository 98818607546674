import findMissingSuperModuleIds from './findMissingSuperModuleIds';
import findMissingSimpleModuleIds from './findMissingSimpleModuleIds';

const combineErrorData = (
  missingSuperModuleErrors,
  missingSuperModuleVersionErrors,
  missingModuleErrors,
  missingModuleVersionErrors,
) => {
  const errorData = [];

  if (missingSuperModuleErrors.length > 0) {
    errorData.push({
      errorCode: 'missingSuperModuleType',
      errorDetails: missingSuperModuleErrors,
    });
  }
  if (missingSuperModuleVersionErrors.length > 0) {
    errorData.push({
      errorCode: 'missingSuperModuleVersion',
      errorDetails: missingSuperModuleVersionErrors,
    });
  }
  if (missingModuleErrors.length > 0) {
    errorData.push({
      errorCode: 'missingModuleSubType',
      errorDetails: missingModuleErrors,
    });
  }
  if (missingModuleVersionErrors.length > 0) {
    errorData.push({
      errorCode: 'missingModuleVersion',
      errorDetails: missingModuleVersionErrors,
    });
  }
  return errorData;
};

const findMissingModuleIds = (modules, versionedModules, superModuleMetaData) => {
  let isSuccess = true;
  const { missingSuperModuleErrors, missingSuperModuleVersionErrors, unfetchedSuperModuleIds } =
    findMissingSuperModuleIds(
      modules,
      versionedModules,
      superModuleMetaData,
    );
  const { missingModuleErrors, missingModuleVersionErrors, missingSimpleModuleIds } =
    findMissingSimpleModuleIds(
      modules,
      versionedModules,
    );

  // Combine error messages into a single error data structure
  const errorData = combineErrorData(
    missingSuperModuleErrors,
    missingSuperModuleVersionErrors,
    missingModuleErrors,
    missingModuleVersionErrors,
  );
  if (missingSimpleModuleIds.length > 0) {
    isSuccess = false;
  }

  return { unfetchedSuperModuleIds, errorData, isSuccess };
};
export default findMissingModuleIds;
