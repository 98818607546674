import { moduleConfigValidation, moduleUiConfigValidation } from '../../schemas/moduleBuilderSchemas';
import HVError, { errorCodes, errorMessages } from '../../utils/error';
import DefaultError from '../../error/defaultError';

const validateJoiSchema = (schema, data) => {
  const { error = null } = schema.validate(data);
  if (error) {
    throw new DefaultError({
      code: errorCodes.joiValidationError,
      message: error?.message || errorMessages.errorValidatingSchema,
      originalError: error,
      debugInfo: {},
    });
  }
};
export const validateJoiSchemaForModuleConfig = (moduleConfig) => {
  try {
    validateJoiSchema(
      moduleConfigValidation,
      moduleConfig,
    );
  } catch (error) {
    throw new DefaultError({
      code: errorCodes.errorValidatingModuleConfig,
      message: `Module validation error: ${error?.message}` || errorMessages.errorValidatingSchema,
      originalError: error instanceof HVError ? error.originalError : error,
      debugInfo: {},
    });
  }
};

export const validateJoiSchemaForModuleUiConfig = (moduleUiConfig) => {
  try {
    validateJoiSchema(
      moduleUiConfigValidation,
      moduleUiConfig,
    );
  } catch (error) {
    throw new DefaultError({
      code: errorCodes.errorValidatingModuleUiConfig,
      message: `Module UIconfig validation error: ${error?.message}` || errorMessages.errorValidatingSchema,
      originalError: error,
      debugInfo: {},
    });
  }
};
