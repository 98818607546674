const handleBuilderPropertiesRemoved = (moduleId, extraData) => {
  const { removedOrChangedBuilderProperties } = extraData;
  const updateReasons = [];
  if (!removedOrChangedBuilderProperties || [].length > 0) {
    return {
      actionPassed: true,
      workflowUpdateReason: null,
      dataForActionFunction: null,
    };
  }
  removedOrChangedBuilderProperties.forEach((property) => {
    updateReasons.push(
      <span key={property.workflowKey}>
        Builder property
        {' '}
        <b>
          &quot;
          {property.workflowKey.split('[-]')[1]}
          &quot;
        </b>
        {' '}
        will be removed.
      </span>,
    );
  });

  return {
    actionPassed: true,
    workflowUpdateReason: updateReasons,
    dataForActionFunction: null,
  };
};

export default handleBuilderPropertiesRemoved;
