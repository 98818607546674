/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchAppIdToken = createAsyncThunk('user/fetchAppIdToken', async (_, { getState, rejectWithValue }) => {
  const state = getState();
  const { currentAppId } = state.user;
  try {
    const userRes = await axios({
      method: 'GET',
      headers: { appid: currentAppId || '55a3a6' },
      url: `${process.env.REACT_APP_SERVER_URL}/api/v1/user/demoToken`,
    });
    const { token } = userRes.data.result;
    return token;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    appIds: {},
    currentAppId: '',
    permissions: null,
    role: '',
    appIdKeyToken: '',
    email: '',
    currentAppIdType: '',
    useCases: {},
    currentUseCase: '',
    useCaseAppIds: [],
    clientId: '',
  },
  reducers: {
    updateUserInfo: (state, action) => {
      const {
        permissions, role, email, useCases, appIds, clientId,
      } = action.payload;
      state.permissions = permissions;
      state.role = role;
      state.email = email;
      state.useCases = useCases;
      state.appIds = appIds;
      state.clientId = clientId;
      // TODO: Revisit this logic when toggling between appids is supported
      const [currentAppId] = Object.keys(appIds);
      state.currentAppId = currentAppId;
    },
    updateAppIdKeyToken: (state, action) => {
      const { token } = action.payload;
      state.appIdKeyToken = token;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAppIdToken.fulfilled, (state, action) => {
      state.appIdKeyToken = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  updateUserInfo, updateAppIdKeyToken,
} = userSlice.actions;
export default userSlice.reducer;
