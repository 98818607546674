import PropTypes from 'prop-types';
import GenericModal from '../Common/GenericModal';
import closeIcon from '../../assests/icons/closeIcon.svg';
import { errorCodeToMessageMap } from '../../utils/error';

function DefaultAlertModal(props) {
  const { error, resetError } = props;
  return (
    <GenericModal
      isOpen={error !== null}
      onClose={resetError}
    >
      <div className="modal-content">
        <div className="modal-header">
          <div className="modal-header-content">Error Occurred</div>
          <button type="button" className="error-modal-close" onClick={resetError}>
            <img className="modal-close-icon" src={closeIcon} alt="Close" />
          </button>
        </div>
        <div className="modal-body">
          { errorCodeToMessageMap[error?.code] || error?.message || 'Something went wrong'}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="modal-save"
            onClick={resetError}
          >
            OK
          </button>
        </div>
      </div>
    </GenericModal>
  );
}

DefaultAlertModal.propTypes = {
  error: PropTypes.object,
  resetError: PropTypes.func.isRequired,
};

DefaultAlertModal.defaultProps = {
  error: null,
};

export default DefaultAlertModal;
