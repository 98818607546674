import convertToNodesEdges, {
  checkComponentForNextStep,
  getNextStepForModule,
} from '../components/utils';
import { getAllFormComponents } from '../containers/FormModule/helper';
import { endStates } from '../components/constants';

// TODO: Update the test cases for this
export const isNextStepPresentInDynamicForm = (workflow) => {
  const invalidModuleIds = [];

  const isEveryModuleValid = workflow.modules.every((module) => {
    if (module.type === 'dynamicForm') {
      const formComponents = getAllFormComponents(module);
      const hasNextStep = formComponents.some((component) => checkComponentForNextStep(component));
      if (!hasNextStep) invalidModuleIds.push(module.id);
      return hasNextStep;
    }

    // TODO: Fix later
    if (module.type === 'dynamicFormV2') return true;

    const hasNextStep = module.nextStep !== '';
    if (!hasNextStep) invalidModuleIds.push(module.id);
    return hasNextStep;
  });

  return { isValid: isEveryModuleValid, extraData: { issues: invalidModuleIds } };
};

export const isNextStepOfNodeNotPointingToItSelf = (workflow) => {
  const { conditions = {}, modules } = workflow;

  const selfReferencingConditionsPresent = Object.keys(conditions).some((conditionId) => {
    const { if_true: ifTrueId, if_false: ifFalseId } = conditions[conditionId];
    return conditionId === ifFalseId || conditionId === ifTrueId;
  });
  if (selfReferencingConditionsPresent) return { isValid: false };

  const selfReferencingModulesPresent = modules.some((module) => {
    const nextSteps = getNextStepForModule(module);
    const anyNextStepPointingToSelf = nextSteps.some(({ nextStep }) => nextStep === module.id);
    return anyNextStepPointingToSelf;
  });
  if (selfReferencingModulesPresent) return { isValid: false };
  return { isValid: true };
};

export const isDeletedModulesOrConditionsPresentInWorkflow = (workflow) => {
  const { modules, conditions } = workflow;
  const deletedModulesOrConditions = [];
  const removeDuplicates = (array) => Array.from(new Set(array));
  const isStepPresent = (step) => modules.some((mod) => mod.id === step) ||
    Object.keys(conditions).includes(step);

  modules.forEach((module) => {
    const nextSteps = getNextStepForModule(module);
    nextSteps.forEach(({ nextStep }) => {
      if (
        !endStates[nextStep] &&
        !modules.some((mod) => mod.id === nextStep) &&
        !Object.keys(conditions).includes(nextStep)
      ) {
        deletedModulesOrConditions.push(nextStep);
      }
    });
  });
  Object.values(conditions).forEach((condition) => {
    if (condition.next_node_type && typeof condition.next_node_type.if_true === 'string') {
      if (
        condition.next_node_type.if_true.toLowerCase() === 'goto' &&
        !isStepPresent(condition.if_true)
      ) {
        deletedModulesOrConditions.push(condition.if_true);
      }
    }
    if (condition.next_node_type && typeof condition.next_node_type.if_false === 'string') {
      if (
        condition.next_node_type.if_false.toLowerCase() === 'goto' &&
        !isStepPresent(condition.if_false)
      ) {
        deletedModulesOrConditions.push(condition.if_false);
      }
    }
  });
  const uniqueDeletedModulesOrConditions = removeDuplicates(deletedModulesOrConditions);
  if (uniqueDeletedModulesOrConditions.length > 0) {
    return { isValid: false, extraData: { issues: uniqueDeletedModulesOrConditions } };
  }
  return { isValid: true };
};

const isTerminalPresentAsNextStep = (components) => {
  if (components?.length === 0) return false;
  let isPresent = false;
  (components || []).forEach((component) => {
    if (component.onClick?.nextStep) {
      const nextStep = component.onClick?.nextStep;
      if (endStates.includes(nextStep)) isPresent = true;
    } else if (component?.subComponents?.length) {
      const subComponents = component?.subComponents || [];
      if (!isPresent) isPresent = isTerminalPresentAsNextStep(subComponents);
    }
  });
  return isPresent;
};

export const terminalNotAllowedInDynamicForm = (workflow) => {
  let isValid = true;
  (workflow?.modules || []).forEach((module) => {
    if (module.type === 'dynamicForm') {
      const { components = [] } = module.properties.sections[0];
      if (isValid) isValid = !isTerminalPresentAsNextStep(components);
    }
  });
  return isValid;
};

export const anyEndStateReachable = (workflow) => {
  const { isEndStateReachable } = convertToNodesEdges(workflow);
  return { isValid: isEndStateReachable };
};
