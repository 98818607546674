const findMissingSimpleModuleIds =
  (
    modules,
    versionedModules,
  ) => {
    const missingModuleErrors = [];
    const missingModuleVersionErrors = [];
    const missingSimpleModuleIds = modules
      .filter((module) => {
        if (!module.superModuleType) {
          const moduleVersion = module?.version || 'v1';
          const isMissingModuleType = !versionedModules[module.subType];
          const isMissingModuleVersion =
      !isMissingModuleType && !versionedModules[module.subType][moduleVersion];

          if (isMissingModuleType) {
            missingModuleErrors.push({
              description: `Module subType '<<${module.subType}>>' is missing.`,
            });
          } else if (isMissingModuleVersion) {
            missingModuleVersionErrors.push({
              description: `Version '<<${moduleVersion}>>' for module subType '<<${module.subType}>>' is missing.`,
            });
          }
          return isMissingModuleType || isMissingModuleVersion;
        }
        return false;
      })
      .map((module) => module.id);

    return {
      missingModuleErrors,
      missingModuleVersionErrors,
      missingSimpleModuleIds,
    };
  };

export default findMissingSimpleModuleIds;
