import Joi from 'joi-browser';
import endStates from '../constants/endStates';

export const workflowKeys = {
  properties: Joi.object({
    // TODO: Make it required
    name: Joi.string(),
    builtOnBuilder: Joi.boolean(),
    builder: Joi.object(),
    builderProperties: Joi.object(),
    description: Joi.string().allow(''),
    sdkVersions: Joi.object({
      mobile: Joi.object(),
      web: Joi.object(),
    }),
    moduleBuilder: Joi.object(),
    enableServerSideResume: Joi.boolean(),
    serverSideResume: Joi.object({
      enable: Joi.boolean(),
      exitOnEndStates: Joi.array().items(Joi.string().valid(...endStates)),
    }),
    uiConfigSource: Joi.string().valid('default', 'custom'),
    textConfigSource: Joi.object().pattern(
      /^[a-zA-Z]{2}$/, // Language code must be 2 letters
      Joi.string().valid('default', 'custom'),
    ),
    inputsRequired: Joi.object().pattern(
      Joi.string(),
      Joi.alternatives().try(
        Joi.string(),
        Joi.boolean(),
        Joi.object({ image: Joi.string() }),
      ), // Values can be string, boolean, or object (for image)
    ),
    enableResumeWorkflow: Joi.boolean(),
    resumeWorkflowDuration: Joi.number().integer(),
    returnPartialSDKResponse: Joi.boolean(),
    secure: Joi.boolean(),
    useWebForm: Joi.boolean(),
    redirectToMobile: Joi.string().valid('optional', 'yes', 'no'),
    enforceCameraCheck: Joi.string().valid('yes', 'no'),
    web: Joi.object({
      // TODO: Check if it should be here
      redirectToMobile: Joi.string().valid('optional', 'yes', 'no'), // Optional string with values 'optional', 'yes', 'no'
      qrRedirectBaseURL: Joi.string().regex(
        /^qr-redirect-hv\.[a-zA-Z0-9-]+\.com$/,
      ), // Optional string matching URL pattern
      enforceCameraCheck: Joi.string().valid('yes', 'no'), // Optional string with values 'yes', 'no'
      showPermissionsScreen: Joi.boolean(), // Optional boolean
      returnPartialSDKResponse: Joi.boolean(),
    }),
    mobile: Joi.object({
      exitIfRooted: Joi.boolean(),
    }),
  }).required(),
  modules: Joi.array().items(Joi.object()).required(),
  conditionalVariables: Joi.object().pattern(
    Joi.string(),
    Joi.object({
      name: Joi.string().allow(''),
      rule: Joi.string().required(),
      parent: Joi.string(),
      if_true: Joi.string().allow('').required(),
      if_false: Joi.string().allow('').required(),
    }),
  ),
  conditions: Joi.object()
    .pattern(
      Joi.string(),
      Joi.object({
        name: Joi.string(),
        next_node_type: Joi.alternatives().try(
          Joi.object(),
          Joi.string().empty(''),
        ),
        rule: Joi.string().required(),
        if_true: Joi.string().required(),
        if_false: Joi.string().required(),
        if_true_reason: Joi.string(),
        if_false_reason: Joi.string(),
        if_true_ignore_results_onwards: Joi.string(),
        if_false_ignore_results_onwards: Joi.string(),
        ifFalseConfigs: Joi.object({
          resumeFrom: Joi.string(),
          reason: Joi.string(),
        }),
        ifTrueConfigs: Joi.object({
          resumeFrom: Joi.string(),
          reason: Joi.string(),
        }),
      }),
    )
    .required(),
  sdkResponse: Joi.object().pattern(
    Joi.string(), // keys are of type string
    Joi.string().allow(''),
  ),
  dashboardData: Joi.object().pattern(
    Joi.string(),
    Joi.string(),
  ),
};

export const workflowSchema = Joi.object({
  name: Joi.string(),
  description: Joi.string(),
  updatedAt: Joi.string(),
  createdAt: Joi.string(),
  properties: workflowKeys.properties,
  modules: workflowKeys.modules,
  conditions: workflowKeys.conditions,
  conditionalVariables: workflowKeys.conditionalVariables,
  sdkResponse: workflowKeys.sdkResponse,
  dashboardData: workflowKeys.dashboardData,
});
