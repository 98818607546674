import store from '../store';

const getProcessedEvent = (event) => {
  const state = store.getState();
  const { email, currentAppId, clientId } = state.user;
  const { enabled: builderMode } = state.moduleBuilder;
  // get the current url
  const url = window.location.search;
  const urlParams = new URLSearchParams(url);
  const workflowId = urlParams.get('id');
  return {
    userEmail: email,
    appId: currentAppId,
    env: process.env.REACT_APP_ENVIRONMENT,
    workflowId,
    url,
    clientId,
    builderMode: builderMode ? 'module-builder' : 'workflow-builder',
    timestamp: new Date().toISOString(),
    eventType: event?.eventType || '',
    properties: event?.properties || {},
    hvEventName: event?.hvEventName || '',
  };
};

const pushEvent = (event) => {
  if (process.env.REACT_APP_ENABLE_EVENT_TRACKING !== 'true') return;
  const processedEvent = getProcessedEvent(event);
  store.dispatch({ type: 'events/pushEvent', payload: { event: processedEvent } });
};

export default pushEvent;
