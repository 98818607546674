import PropTypes from 'prop-types';
import Modal from '../../Common/Modal';
import './WorkflowUpdateConfirmationModal.scss';

function WorkflowUpdateConfirmationModal(props) {
  const { workflowUpdates, onWorkflowUpdateModalClose, onConfirmWorkflowUpdate } = props;
  return (
    <Modal
      isOpen={workflowUpdates !== null}
      onClose={onWorkflowUpdateModalClose}
      headerContent={<div className="workflow-update-confirmation-modal__header">Confirm Workflow Updates?</div>}
      onSave={onConfirmWorkflowUpdate}
      buttonText="I agree"
    >
      <div className="workflow-update-confirmation-modal__content">
        The following updates will be made to the workflow to update the module version.
        Do you agree?
        <ul className="workflow-update-confirmation-modal__content__updates">
          {workflowUpdates.map((update, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li className="workflow-update-confirmation-modal__content__updates__item" key={`${update}-${index}`}>{update}</li>
          ))}
        </ul>
      </div>
    </Modal>
  );
}

WorkflowUpdateConfirmationModal.propTypes = {
  workflowUpdates: PropTypes.array.isRequired,
  onWorkflowUpdateModalClose: PropTypes.func.isRequired,
  onConfirmWorkflowUpdate: PropTypes.func.isRequired,
};

export default WorkflowUpdateConfirmationModal;
