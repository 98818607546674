import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material/styles';
import { clarity } from 'clarity-js';
import * as Sentry from '@sentry/react';
import Main from './containers/Main';
import PrivateRoute from './utils/PrivateRoute';
import ViewWorkflow from './containers/ViewWorkflow';
import WorkflowList from './components/WorkflowList';
import NotFound from './containers/NotFound';
import WorkflowRouter from './pages/WorkflowRouter';
import DrawerLayout from './containers/DrawerLayout';
import 'reactflow/dist/style.css';
import Branding from './pages/Branding';
import { selectModuleBuilderMode } from './reducers/moduleBuilder';
import useHandleError from './hooks/error/useHandleError';
import ErrorHandlerContext from './context/ErrorHandlerContext';

function App() {
  const email = useSelector((state) => state.user.email);
  const currentAppId = useSelector((state) => state.user.currentAppId);
  const builderMode = useSelector(selectModuleBuilderMode) ? 'module_builder' : 'workflow_builder';
  const projectId = process.env.REACT_APP_CLARITY_PROJECT_ID;

  const { error, handleError, resetError } = useHandleError();

  useEffect(() => {
    if (projectId) {
      clarity.set('user_name', email);
      clarity.set('currentAppId', currentAppId);
      clarity.start({
        projectId,
        upload: 'https://m.clarity.ms/collect',
        track: true,
        content: true,
      });
    }
  }, [email, currentAppId, projectId]);

  useEffect(() => {
    Sentry.setUser({ email });
    Sentry.setTag('app_id', currentAppId);
    Sentry.setTag('builder_mode', builderMode);
  }, [currentAppId, builderMode, email]);

  return (
    <StyledEngineProvider injectFirst>
      <ErrorHandlerContext.Provider value={handleError}>
        <div className="App">
          <Routes>
            <Route
              path="/"
              element={(
                <PrivateRoute>
                  <Main
                    resetError={resetError}
                    error={error}
                  />
                </PrivateRoute>
            )}
            >
              <Route path="/" element={<DrawerLayout />}>
                <Route path="/" element={<WorkflowList />} />
              </Route>
              <Route path="view" element={<WorkflowRouter />}>
                <Route index element={<ViewWorkflow />} />
                <Route path="ui-screens" element={<Branding />} />
              </Route>
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </ErrorHandlerContext.Provider>
    </StyledEngineProvider>
  );
}

export default App;
