import HVError from '../utils/error';

class DependencyError extends HVError {
  constructor(dependencies) {
    super({
      message: 'Dependency Error',
      type: 'dependencyError',
      logToSentry: false,
    });
    this.dependencies = dependencies;
  }
}

export default DependencyError;
