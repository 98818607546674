import HVError from '../utils/error';

class DefaultError extends HVError {
  constructor({
    code,
    message = 'Default Error',
    originalError = null,
    debugInfo = {},
    logToSentry = true,
  }) {
    super({
      code,
      message,
      type: 'defaultError',
      logToSentry,
      debugInfo,
      originalError,
    });
  }
}

export default DefaultError;
