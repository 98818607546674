import { cloneDeep, set } from 'lodash';
import DefaultError from '../../error/defaultError';
import { errorCodes } from '../../utils/error';

const setLottie = ({
  lottieUrl, lottieType, uiConfig,
}) => {
  try {
    const lottieKeyMap = {
      failure: 'endStateFailure',
      success: 'endStateSuccess',
      loading: 'endStateProcessing',
    };
    const lottieKey = lottieKeyMap[lottieType];
    let editedUiConfig = {};
    if (lottieUrl) {
      editedUiConfig = {
        ...cloneDeep(uiConfig),
        animation: {
          ...uiConfig.animation,
          [lottieKey]: lottieUrl,
        },
      };
    } else {
      editedUiConfig = {
        ...cloneDeep(uiConfig),
      };
      set(editedUiConfig, `animation.${lottieKey}`, '');
    }
    return { uiConfig: editedUiConfig };
  } catch (error) {
    const processedError = new DefaultError({
      code: errorCodes.errorUpdatingLottie,
      message: 'Failed to update Lottie animation!',
      originalError: error,
      debugInfo: {},
    });
    return { uiConfig, errorData: processedError };
  }
};
export default setLottie;
