import PropTypes from 'prop-types';
import GenericModal from '../Common/GenericModal';
import ErrorModalContent from './ErrorModalContent';

function ErrorModal({
  isOpen,
  onClose,
  errorData,
  onConfirm,
  isWorkflowValidationSuccessful,
}) {
  return (
    <GenericModal
      isOpen={isOpen}
      onClose={onClose}
    >
      <ErrorModalContent
        errorData={errorData}
        onConfirm={onConfirm}
        onClose={onClose}
        isWorkflowValidationSuccessful={isWorkflowValidationSuccessful}
      />
    </GenericModal>
  );
}

ErrorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isWorkflowValidationSuccessful: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  errorData: PropTypes.arrayOf(
    PropTypes.shape({
      errorCode: PropTypes.string.isRequired,
      errorDetails: PropTypes.arrayOf(
        PropTypes.shape({
          description: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ErrorModal;
