import {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { diff } from 'deep-object-diff';
import moduleUpdateIcon from '../../../assests/icons/moduleUpdateIcon.svg';

import './ModuleVersionUpdate.scss';
import Modal from '../../Common/Modal';
import VersionUpdateModalContent from './VersionUpdateModalContent';
import Button from '../../Common/Button';
import useShowErrorAlert from '../../../hooks/custom/useCustomAlert';
import HVError, { errorCodes, errorMessages } from '../../../utils/error';
import { updateWorkflowInState } from '../../../workflowOperations/updateWorkflow';
import { workflowOperationsObj } from '../../../workflowOperations';
import DefaultError from '../../../error/defaultError';
import ErrorHandlerContext from '../../../context/ErrorHandlerContext';
import pushEvent from '../../../events/pushEvents';

function ModuleVersionUpdate(props) {
  const {
    modulesTobeUpdated,
    versionedModules,
    workflow,
    countryDocMapping,
    localOrderOfNodes,
    defaultFormSections,
  } = props;

  const [showModuleUpdateModal, setShowModuleUpdateModal] = useState(false);
  const [moduleToBeUpdatedIndex, setModuleToBeUpdatedIndex] = useState(null);
  const [hasFetchModuleUpdatesError, setHasFetchModuleUpdatesError] = useState(false);
  const showErrorAlert = useShowErrorAlert();

  const prevOrderOfNodesRef = useRef(null);
  const handleError = useContext(ErrorHandlerContext);

  const openModuleUpdateModal = (modulesToUpdate, versionedModuleList) => {
    setShowModuleUpdateModal(true);
    const availableUpdates = modulesToUpdate.map((module) => ({
      id: module.id,
      currentVersion: module.version,
      latestVersion: versionedModuleList?.[module?.subType]?.latestVersion,
    }));
    pushEvent({
      eventType: 'MODULE_VERSION_UPDATES_VIEWED',
      properties: {
        availableUpdates,
      },
    });
  };

  const onModuleUpdateButtonClick = (modulesToUpdate, versionedModuleList) => {
    openModuleUpdateModal(modulesToUpdate, versionedModuleList);
    prevOrderOfNodesRef.current = localOrderOfNodes;
  };

  const handleCloseModuleUpdateModal = () => {
    setShowModuleUpdateModal(false);
    setModuleToBeUpdatedIndex(null);
    const lhs = {
      orderOfNodes: prevOrderOfNodesRef.current,
    };
    const rhs = {
      orderOfNodes: localOrderOfNodes,
    };
    const change = diff(lhs, rhs);
    if (Object.keys(change || {}).length > 0) {
      handleError(
        new HVError({
          code: errorCodes.orderOfNodesModifiedDuringModuleUpdate,
          message: errorMessages.orderOfNodesModifiedDuringModuleUpdate,
          originalError: new Error('Order of nodes modified during module update'),
          debugInfo: {
            change,
          },
        }),
      );
    }
    prevOrderOfNodesRef.current = null;
  };

  useEffect(() => {
    if (hasFetchModuleUpdatesError) {
      // TODO: to be handled using global error handler
      showErrorAlert({
        error: new Error('Failed to fetch module update details'),
        message: errorMessages.errorFetchingModuleUpdates,
      });
      handleCloseModuleUpdateModal();
      setHasFetchModuleUpdatesError(false);
    }
  }, [hasFetchModuleUpdatesError]);

  const updateWorkflow = (moduleUpdateDetails) => {
    const changesToLog = (moduleUpdateDetails?.versionChangeDetails || [])
      .map((change) => change.comparisonName);
    try {
      const {
        moduleId,
        moduleSubType,
        latestVersion,
        versionChangeDetails,
      } = moduleUpdateDetails;
      const workflowUpdateActions = [];

      const { config: moduleConfig, uiConfig } = versionedModules[moduleSubType][latestVersion];
      const currentNode = {
        version: latestVersion,
        ...moduleConfig,
        ...uiConfig,
      };
      const moduleInWorkflow = workflow.modules.find((node) => node.id === moduleId);

      // simulate deletion and addition of module
      workflowUpdateActions.push({
        operation: workflowOperationsObj.UPDATE_SUPER_MODULE_VERSION,
        actionData: {
          moduleId,
          node: currentNode,
          countryDocMapping,
          localOrderOfNodes,
          defaultFormSections,
        },
      });

      // retain the name of the module
      workflowUpdateActions.push({
        operation: workflowOperationsObj.SET_NODE_NAME,
        actionData: {
          targetNodeId: moduleId,
          name: moduleInWorkflow.name,
        },
      });

      // retain the previous step of the module
      if (moduleInWorkflow.previousStep !== undefined) {
        workflowUpdateActions.push({
          operation: workflowOperationsObj.SET_PREVIOUS_STEP,
          actionData: {
            targetNodeId: moduleId,
            previousStep: moduleInWorkflow.previousStep,
          },
        });
      }

      // retain the builder properties of the module
      if (versionChangeDetails.some((changeDetails) => changeDetails.comparisonName === 'builderPropertiesRemoved')) {
        const { extraData } = versionChangeDetails.find(
          (changeDetails) => changeDetails.comparisonName === 'builderPropertiesRemoved',
        );
        const { removedOrChangedBuilderProperties } = extraData;

        Object.keys(moduleInWorkflow.builderProperties).forEach((property) => {
          const workflowKeyOfProperty = `builderProperties[-]${property}`;
          if (!removedOrChangedBuilderProperties.some(
            (removedProperty) => removedProperty.workflowKey === workflowKeyOfProperty,
          )) {
            workflowUpdateActions.push({
              operation: workflowOperationsObj.SET_MODULE_PROPERTY,
              actionData: {
                targetNodeId: moduleId,
                workflowKey: workflowKeyOfProperty,
                value: moduleInWorkflow.builderProperties[property],
                moduleConfig,
              },
            });
          }
        });
      }

      // retain the inputs of the module
      if (versionChangeDetails.some((changeDetails) => changeDetails.comparisonName === 'inputsRemoved')) {
        const { extraData } = versionChangeDetails.find(
          (changeDetails) => changeDetails.comparisonName === 'inputsRemoved',
        );
        const { removedOrChangedModuleInputs } = extraData;
        const preservedInputs = Object.keys(moduleInWorkflow.properties).filter(
          (input) => !removedOrChangedModuleInputs.some(
            (removedInput) => removedInput.workflowKey === input,
          ),
        );
        preservedInputs.forEach((input) => {
          workflowUpdateActions.push({
            operation: workflowOperationsObj.SET_MODULE_PROPERTY,
            actionData: {
              targetNodeId: moduleId,
              workflowKey: input,
              value: moduleInWorkflow.properties[input],
              moduleConfig,
            },
          });
        });
      }

      // update the workflow
      const { success } = updateWorkflowInState({}, true, {
        operation: 'WORKFLOW_BULK_UPDATE',
        actionData: {
          workflowUpdateActions,
        },
      });

      if (!success) {
        throw new DefaultError({
          code: errorCodes.errorInApplyingModuleVersionUpdate,
          message: errorMessages.errorUpdatingModuleVersion,
          originalError: new Error('Failed to update workflow with latest module version'),
          debugInfo: {
            workflowUpdateActions,
            moduleUpdateDetails,
          },
        });
      }

      pushEvent({
        eventType: 'MODULE_VERSION_UPDATED_SUCCESSFULLY',
        properties: {
          moduleId: moduleUpdateDetails?.moduleId,
          currentVersion: moduleUpdateDetails?.currentVersion,
          latestVersion: moduleUpdateDetails?.latestVersion,
          changes: changesToLog,
        },
      });
    } catch (error) {
      pushEvent({
        eventType: 'MODULE_VERSION_UPDATE_FAILED',
        properties: {
          moduleId: moduleUpdateDetails?.moduleId,
          currentVersion: moduleUpdateDetails?.currentVersion,
          latestVersion: moduleUpdateDetails?.latestVersion,
          changes: changesToLog,
          failureReason: error.message,
        },
      });
      if (error instanceof HVError) handleError(error);
      else {
        handleError(new DefaultError({
          code: errorCodes.moduleVersionUpdateFailed,
          message: errorMessages.errorUpdatingModuleVersion,
          originalError: error,
          debugInfo: {
            moduleUpdateDetails,
          },
        }));
      }
    }
    handleCloseModuleUpdateModal();
  };

  const getModuleUpdateHeaderContent = () => {
    if (moduleToBeUpdatedIndex === null) {
      return (
        <div className="module-update-modal__header">
          <h1 className="module-update-modal__header-title">Module Release Updates</h1>
          <p className="module-update-modal__header-subtitle">
            Update the following modules to get latest versions,
            bug fixes and additional functionality
          </p>
        </div>
      );
    }

    const moduleDetails = modulesTobeUpdated[moduleToBeUpdatedIndex];
    const moduleName = moduleDetails.name;
    const currentVersion = moduleDetails.version || 'v1';
    const { latestVersion } = versionedModules[moduleDetails.subType];

    return (
      <h1 className="module-update-modal__header-title">
        {`Update ${moduleName} from ${currentVersion} to ${latestVersion}?`}
      </h1>
    );
  };

  return (
    <div className="module-update">
      {(modulesTobeUpdated || []).length > 0 && (
        <div className="module-update__notification" />
      )}
      <Button
        buttonType="secondary"
        className="module-update__button"
        onClick={() => onModuleUpdateButtonClick(modulesTobeUpdated, versionedModules)}
      >
        <div className="module-update__content">
          <img className="module-update__icon" src={moduleUpdateIcon} alt="Updates" />
          <span>
            Updates (
            {(modulesTobeUpdated || []).length}
            )
          </span>
        </div>
      </Button>
      {showModuleUpdateModal && (
      <Modal
        isOpen={showModuleUpdateModal}
        onClose={() => handleCloseModuleUpdateModal()}
        headerContent={getModuleUpdateHeaderContent()}
        className="module-update-modal"
      >
        <VersionUpdateModalContent
          modulesTobeUpdated={modulesTobeUpdated}
          versionedModules={versionedModules}
          selectedModuleIndex={moduleToBeUpdatedIndex}
          selectModuleToBeUpdated={(index) => setModuleToBeUpdatedIndex(index)}
          handleCloseModuleUpdateModal={handleCloseModuleUpdateModal}
          setHasFetchModuleUpdatesError={setHasFetchModuleUpdatesError}
          workflow={workflow}
          updateWorkflow={updateWorkflow}
        />
      </Modal>
      )}
    </div>
  );
}

ModuleVersionUpdate.propTypes = {
  modulesTobeUpdated: PropTypes.array.isRequired,
  versionedModules: PropTypes.object.isRequired,
  workflow: PropTypes.object.isRequired,
  countryDocMapping: PropTypes.object.isRequired,
  localOrderOfNodes: PropTypes.array.isRequired,
  defaultFormSections: PropTypes.array.isRequired,
};

export default ModuleVersionUpdate;
