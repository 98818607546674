/* eslint-disable no-param-reassign */
import { cloneDeep, get, unset } from 'lodash';
import * as Sentry from '@sentry/react';
import removeUnvisitedNodesAndConditions from '../workflowOperations/utils';
import { fetchCurrentValueFromWorkflow as getModuleProperty } from '../components/ViewWorkflow/InputsToModule/utils/updateWorkflow';
import replaceNextStepId from './utils/replaceNextStepId';
import { getAllFormComponentsObj, getComponentFromPath } from '../containers/FormModule/helper';
import HVError, { errorCodes, errorMessages } from '../utils/error';
import { getNextStepForModule } from '../components/utils';
import addPrevStepToHighLevelWorkflowModules from './utils/addPrevSteptohighLevelWorkflowModules';
import getPreviousStepMap from './utils/getPreviousStepMap';
import getResumeFromMap from './utils/getResumeFromMap';
import { convertModuleBuilderConfigurationsToArray } from './utils/transformModuleBuilderConfigurations';
import addResumeFromToHighLevelWorkflowConditions from './utils/addResumeFromToHighLevelWorkflowConditions';
import allowedErrorCodes from '../constants/errorCodesForDecompiler';
import matchAndReplace from './utils/replaceVariables';

const findExitStatePositionInModules = (library) => {
  let moduleId = null;

  library.modules.forEach((module) => {
    // TODO: What if next step is in the form module of library, Case not handled
    if (module.nextStep === 'EXIT_POINT') {
      moduleId = module.id;
    }
  });

  return moduleId;
};

const findExitStatePositionInConditions = (library) => {
  let conditionId = null;
  const conditionsIdArr = Object.keys(library.conditions);
  conditionsIdArr.forEach((id) => {
    const condition = library.conditions[id];
    if (condition.if_false === 'EXIT_POINT') {
      conditionId = `${id}[+]if_false`;
    } else if (condition.if_true === 'EXIT_POINT') {
      conditionId = `${id}[+]if_true`;
    }
  });
  return conditionId;
};

// TODO: Write tests for the below function
// TOOD: use event handlers
const findPositionOfExitPointComponentInArray = (components, currentPathArray = []) => {
  let result = null;
  (components || []).every((component, index) => {
    if (component?.onClick?.nextStep === 'EXIT_POINT') {
      result = {
        componentPathArray: [...currentPathArray, index],
        position: 'onClick.nextStep',
      };
      return false;
    }
    if (component?.onValidated?.nextStep === 'EXIT_POINT') {
      result = {
        componentPathArray: [...currentPathArray, index],
        position: 'onValidated.nextStep',
      };
      return false;
    }
    if (component?.onComplete?.nextStep === 'EXIT_POINT') {
      result = {
        componentPathArray: [...currentPathArray, index],
        position: 'onComplete.nextStep',
      };
      return false;
    }
    if (component?.onChange?.nextStep === 'EXIT_POINT') {
      result = {
        componentPathArray: [...currentPathArray, index],
        position: 'onChange.nextStep',
      };
      return false;
    }
    if (component?.dynamicHandlers?.handlers?.length) {
      // Dynamic form handler
      let position = null;
      (component?.dynamicHandlers?.handlers || []).forEach((handler, handlerIndex) => {
        if (handler?.nextStep === 'EXIT_POINT') position = `dynamicHandlers.handlers[${handlerIndex}].nextStep`;
      });
      if (position) {
        result = {
          componentPathArray: [...currentPathArray, index],
          position,
        };
        return false;
      }
    }
    if (component?.subComponents?.length) {
      // Can't return from forEach
      const exitPointData = findPositionOfExitPointComponentInArray(component?.subComponents, [
        ...currentPathArray,
        index,
      ]);
      if (exitPointData) {
        result = exitPointData;
        return false;
      }
    }
    return true;
  });
  return result;
};

const findPositionOfExitPointInDynamicForm = (library) => {
  let path = null;
  library.modules.forEach((module) => {
    if (path === null) {
      if (module?.type === 'dynamicForm') {
        const formComponentsObj = getAllFormComponentsObj(module);
        const basePathKeys = Object.keys(formComponentsObj);

        basePathKeys.forEach((basePathKey) => {
          const components = formComponentsObj[basePathKey];
          const exitPointData = findPositionOfExitPointComponentInArray(components, []);
          if (exitPointData) path = { id: module?.id, ...exitPointData, basePathKey };
        });
      }
    }
  });
  return path;
};

const findPositionOfExitPointInDynamicFormV2 = (library) => {
  let path = null;
  library.modules.forEach((module) => {
    if (module?.type === 'dynamicFormV2') {
      const nextSteps = getNextStepForModule(module);
      const nextStepWithExitPoint = nextSteps.find(({ nextStep }) => nextStep === 'EXIT_POINT');
      if (nextStepWithExitPoint) {
        const { path: pathForNextStep } = nextStepWithExitPoint;
        path = {
          moduleId: module.id,
          path: pathForNextStep,
        };
      }
    }
  });
  return path;
};

export const findExitNodeId = (library, superModuleId, moduleMap, lowLevelWorkflow) => {
  // Try fetching EXIT_POINT from module next step
  const nextSteps = new Set();
  const moduleIdForNextStep = findExitStatePositionInModules(library);
  if (moduleIdForNextStep) {
    const nextStep = moduleMap[moduleIdForNextStep]?.nextStep;
    if (nextStep) {
      nextSteps.add(nextStep);
    }
  }
  const conditionsIdForNextStep = findExitStatePositionInConditions(library);
  // Try fetching EXIT_POINT from condition
  if (conditionsIdForNextStep) {
    const [conditionId, trueFalseCase] = conditionsIdForNextStep.split('[+]');
    const conditionNextStep = lowLevelWorkflow.conditions?.[`condition_${superModuleId}_${conditionId}`]?.[trueFalseCase];
    if (conditionNextStep) nextSteps.add(conditionNextStep);
  }
  const exitPointInForm = findPositionOfExitPointInDynamicForm(library);
  if (exitPointInForm) {
    const {
      id, componentPathArray, position, basePathKey,
    } = exitPointInForm;
    const actualLowLevelFormModule = moduleMap[id];
    const formComponentsObj = getAllFormComponentsObj(actualLowLevelFormModule);
    const formComponents = formComponentsObj[basePathKey];
    const componentWithNextStep = getComponentFromPath(formComponents, componentPathArray);
    // Try fetching EXIT_POINT from dynamic form
    const formNextStep = get(componentWithNextStep, position, null);
    if (formNextStep) nextSteps.add(formNextStep);
  }
  const exitPointInFormV2 = findPositionOfExitPointInDynamicFormV2(library);
  if (exitPointInFormV2) {
    const { moduleId: id, path } = exitPointInFormV2;
    const actualLowLevelFormModule = moduleMap[id];
    const actualNextStep = get(actualLowLevelFormModule?.properties?.componentConfigs, path, null);
    if (actualNextStep) nextSteps.add(actualNextStep);
  }
  if (nextSteps.size > 1) {
    throw new HVError({
      code: errorCodes.multipleNextStepsFound,
      message: `Inconsistent next steps found across exit points in superModule ${superModuleId}`,
    });
  }

  if (nextSteps.size === 0) {
    throw new HVError({
      code: errorCodes.noExitNodeFound,
      message: `No next steps found across exit points in superModule ${superModuleId}`,
    });
  }
  return nextSteps.values().next().value;
};

const extractMiddlePart = (fullId, supermoduleId, subtype) => {
  if (typeof fullId !== 'string' || typeof supermoduleId !== 'string' || typeof subtype !== 'string') {
    return '';
  }
  // Trim inputs to avoid any leading or trailing spaces
  fullId = fullId.trim();
  supermoduleId = supermoduleId.trim();
  subtype = subtype.trim();

  // Ensure the lengths are appropriate before slicing
  if (fullId.length < supermoduleId.length + subtype.length) {
    return '';
  }
  let middlePart = fullId
    .slice(supermoduleId.length)
    .slice(0, -subtype.length)
    .trim();
  middlePart = middlePart.replace(/^_+|_+$/g, '');
  return middlePart;
};

const createSuperModule = (
  superModuleArray,
  superModuleConditions,
  versionedModuleConfigs,
  lowLevelWorkflow,
  nextNodeType = {},
  moduleName = null,
  superModuleVersion = 'v1',
  patchedProperties = {},
) => {
  // Extract important information
  const { superModuleId, superModuleType } = superModuleArray[0];
  const moduleConfig = versionedModuleConfigs[superModuleType][superModuleVersion]?.config;
  const { library, initialStep } = moduleConfig;

  // STEP 1: Create an empty json object with module config structure
  const { id, properties, ...rest } = moduleConfig;
  const module = { ...rest, ...(moduleName ? { name: moduleName } : {}) };

  // Setting Id
  module.id = superModuleId;
  module.next_node_type = nextNodeType;
  module.version = superModuleVersion;
  module.patchedProperties = patchedProperties;

  const mappingIdArr = library.modules.map((moduleList) => moduleList.id);

  // Create module Obj with mappingId as the key and module as value
  const moduleMap = {};
  try {
    superModuleArray.forEach((moduleItem) => {
      if (!moduleItem.mappingId) {
        /* eslint-disable no-console */
        console.error(`${moduleItem.id} mappingId is not present`);
      }
      const originalId = moduleItem.id;
      const expectedMappingId =
      extractMiddlePart(originalId, moduleItem.superModuleId, moduleItem.subType);
      const isValidMappingId = mappingIdArr.includes(expectedMappingId);
      if (moduleItem.mappingId !== expectedMappingId && isValidMappingId) {
        /* eslint-disable no-console */
        console.error(`${moduleItem.id} mappingId is incorrect, please change it with ${expectedMappingId}`);
      }
      moduleMap[moduleItem.mappingId] = moduleItem;
    });

    Object.keys(superModuleConditions || {}).forEach((conditionId) => {
      moduleMap[conditionId] = superModuleConditions[conditionId];
    });
  } catch (error) {
    if (error instanceof HVError) {
      // Re-throw HVError without modification
      Sentry.captureException(error);
      throw error;
    } else {
      // Wrap other errors in HVError
      Sentry.captureException(error);
      throw new HVError({
        code: errorCodes.errorCreatingModuleObj,
        message: errorMessages.errorCreatingModuleObj,
        originalError: error,
      });
    }
  }
  // Generating properties
  const propertiesArr = Object.keys(properties);
  const propertiesObj = {};
  propertiesArr.forEach((property) => {
    let mappingId; let
      propertyKey;

    try {
      [mappingId, propertyKey] = property.split('[+]');
      if (!mappingId || !propertyKey) {
        throw new Error(`Invalid property format: ${property}`);
      }
    } catch (error) {
      Sentry.captureException(error);
      throw new HVError({
        code: errorCodes.mappingId,
        message: `Failed to split property: ${property}`,
        originalError: error,
      });
    }
    const extractedValue = getModuleProperty(moduleMap[mappingId], propertyKey);
    propertiesObj[property] = extractedValue;
  });

  module.properties = propertiesObj;

  // Generating builderProperties
  const builderProperties = lowLevelWorkflow.properties?.builderProperties?.[superModuleId];
  if (builderProperties) {
    module.builderProperties = builderProperties;
  }

  try {
    const exitNodeActualId = findExitNodeId(
      library,
      superModuleId,
      moduleMap,
      lowLevelWorkflow,
    );
    module.nextStep = exitNodeActualId;
  } catch (error) {
    if (error instanceof HVError) {
      throw error;
    } else {
      throw new HVError({
        code: errorCodes.exitNodeIdNotFound,
        message: errorMessages.exitNodeIdNotFound,
        originalError: error,
      });
    }
  }
  const variableReplacements = [];
  try {
    moduleConfig.variables.forEach((variable) => {
      const { name, path } = variable;
      const [mappingId, ...keyNames] = path.split('.');
      const key = keyNames.join('.');
      if (mappingId === 'conditionalVariables') {
        variableReplacements.push({
          key: `conditionalVariables.${superModuleId}_${key}`,
          value: `${superModuleId}.${name}`,
        });
      } else {
        variableReplacements.push({
          key: `${moduleMap[mappingId].id}.${key}`,
          value: `${superModuleId}.${name}`,
        });
      }
    });
  } catch (error) {
    throw new HVError({
      code: errorCodes.inValidMappingId,
      message: `Error creating variables replacement, please check the mapping id for ${superModuleId} `,
      originalError: error,
    });
  }

  // Finding the id of the first node for referencing from the parent of the superModule

  let mappingIdOfFirstStep = initialStep;
  if (!moduleMap[mappingIdOfFirstStep]) {
    mappingIdOfFirstStep = library.modules[0].id;
  }
  const idToBeReplaced = moduleMap[mappingIdOfFirstStep].id;
  const replaceNextStepIds = {
    key: idToBeReplaced,
    value: superModuleId,
  };
  return {
    module,
    variableReplacements,
    replaceNextStepIds,
  };
};

const replaceModuleVariables = (workflow, replacements) => {
  const workflowString = JSON.stringify(workflow);
  let finalWorkflowString = workflowString;
  replacements.forEach((replacement) => {
    finalWorkflowString = matchAndReplace(finalWorkflowString, replacement.key, replacement.value);
  });
  const finalWorkflowObj = JSON.parse(finalWorkflowString);
  return finalWorkflowObj;
};

const removeLibrariesFromWorkflow = (workflow) => {
  const editedWorkflow = cloneDeep(workflow);
  editedWorkflow.modules.forEach((module) => {
    unset(module, 'library');
  });
  return editedWorkflow;
};

const decompile = (lowLevelWorkflow, versionedModuleConfigs, breakSuperModules = false) => {
  let highLevelWorkflow = {};
  const errors = [];

  // STEP 1: COPY ALL THE PROPERTIES EXCEPT MODULES
  const {
    modules: originalModules,
    conditions: originalConditions,
    conditionalVariables: originalConditionalVariables,
    properties: originalProperties,
    ...rest
  } = lowLevelWorkflow;
  const previousStepMap = getPreviousStepMap(originalModules);
  const resumeFromMap = getResumeFromMap(originalConditions, originalModules);

  // Exclude builderProperties from properties of the workflow
  const { builderProperties, ...otherProperties } = originalProperties;
  highLevelWorkflow = { properties: otherProperties, ...rest };
  // Exclude conditions of the super module
  const conditions = {};
  Object.keys(originalConditions).forEach((condition) => {
    if (!condition.includes('[+]')) conditions[condition] = originalConditions[condition];
  });
  highLevelWorkflow.conditions = conditions;

  // STEP 2: CREATE A MODULES ARRAY BY COPYING ALL THE SIMPLE MODULES AND KEEP SUPER MODUELS ASIDE
  const modules = [];
  const superModulesMap = {};
  const superModuleConditionsMap = {};
  originalModules.forEach((module) => {
    if (module?.superModuleId && typeof module?.superModuleId === 'string') {
      // complex module
      const superModuleId = module?.superModuleId;
      if (superModulesMap[superModuleId]) superModulesMap[superModuleId].push(module);
      else superModulesMap[superModuleId] = [module];
    } else {
      // simple module
      modules.push(module);
    }
  });
  highLevelWorkflow.modules = modules;
  const superModuleIdArr = Object.keys(superModulesMap);
  // Include the conditions from supermodules in the superModulesMap
  // Assumption: superModule will contain at least one module
  Object.entries(originalConditions).forEach(([conditionId, condition]) => {
    const superModuleId = superModuleIdArr.find((moduleId) => conditionId.startsWith(`condition_${moduleId}`));
    if (superModuleId) {
      const [, originalConditionName] = conditionId.split(`condition_${superModuleId}_`);
      superModuleConditionsMap[superModuleId] = {
        ...superModuleConditionsMap[superModuleId],
        [originalConditionName]: { id: conditionId, ...condition },
      };
    }
  });

  // Exclude conditionalVariables from the super module
  const conditionalVariables = {};
  Object.keys(originalConditionalVariables || {}).forEach((condVariable) => {
    const isSuperModuleVar = superModuleIdArr.reduce(
      (acc, superModuleId) => acc || condVariable.startsWith(`${superModuleId}_`),
      false,
    );
    if (!isSuperModuleVar) {
      conditionalVariables[condVariable] = originalConditionalVariables[condVariable];
    }
  });
  highLevelWorkflow.conditionalVariables = conditionalVariables;
  // moduleId.variables that should be replaced
  let replacements = [];
  const simpleModules = [];
  const nextStepReplacements = [];
  // STEP 3: GROUPING PHASE
  const rawSuperModules = superModuleIdArr.map((superModuleId) => {
    const superModuleMetaData = lowLevelWorkflow?.properties?.builder?.superModuleMetaData || {};
    const superModuleName = superModuleMetaData?.[superModuleId]?.moduleName || null;
    const superModuleVersion = superModuleMetaData?.[superModuleId]?.version || 'v1';
    const nextNodeType = superModuleMetaData?.[superModuleId]?.nextNodeType || {};
    const patchedProperties = superModuleMetaData?.[superModuleId]?.patchedProperties || {};
    try {
      const { module, variableReplacements, replaceNextStepIds } = createSuperModule(
        superModulesMap[superModuleId],
        superModuleConditionsMap[superModuleId],
        versionedModuleConfigs,
        lowLevelWorkflow,
        nextNodeType,
        superModuleName,
        superModuleVersion,
        patchedProperties,
      );
      replacements = replacements.concat(variableReplacements);
      nextStepReplacements.push(replaceNextStepIds);
      return module;
    } catch (error) {
      if (error instanceof HVError && Object.values(allowedErrorCodes).includes(error.code)
        && breakSuperModules) {
        // Log individual properties of HVError
        errors.push({
          description: `There is an issue with the superModuleId <<${superModuleId}>> - ${error.message}`,
        });
        const superModulesArr = superModulesMap[superModuleId];
        const updatedModules = superModulesArr.map((items) => {
          const { superModuleId: innerSuperModuleId, superModuleType, ...restOfProps } = items;
          return restOfProps; // Return the remaining properties
        });
        simpleModules.push(...updatedModules);
        return null;
      }
      throw error;
    }
  });
  const superModules = rawSuperModules.filter((module) => module !== null);
  modules.push(...simpleModules);
  highLevelWorkflow.modules = modules.concat(superModules);
  highLevelWorkflow = replaceModuleVariables(highLevelWorkflow, replacements);

  // TODO: Next step for form module is not yet handled
  highLevelWorkflow = replaceNextStepId(highLevelWorkflow, nextStepReplacements);
  highLevelWorkflow = removeLibrariesFromWorkflow(highLevelWorkflow);
  highLevelWorkflow = removeUnvisitedNodesAndConditions(highLevelWorkflow);
  const errorData = [];
  if (errors.length > 0) {
    errorData.push({
      errorCode: 'invalidSuperModules',
      errorDetails: errors,
    });
  }

  highLevelWorkflow.modules = addPrevStepToHighLevelWorkflowModules(
    highLevelWorkflow.modules,
    versionedModuleConfigs,
    previousStepMap,
  );
  highLevelWorkflow.conditions = addResumeFromToHighLevelWorkflowConditions(
    highLevelWorkflow.conditions,
    resumeFromMap,
  );

  highLevelWorkflow = convertModuleBuilderConfigurationsToArray(highLevelWorkflow);

  return {
    workflow: highLevelWorkflow,
    errors: errorData,
  };
};

export default decompile;
