/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */

import { cloneDeep } from 'lodash';
import { selectVersionedModules, selectSelectedWorkflow } from '../reducers/workflow';
import updateGotoTagsInWorkflow from '../utils/updateGotoTags';
import updateModuleNamesInWorkflow from '../utils/updateModuleNamesInWorkflow';
import store, { getStateValue } from '../store';
import {
  isNextStepPresentInDynamicForm,
  isNextStepOfNodeNotPointingToItSelf,
  isDeletedModulesOrConditionsPresentInWorkflow,
  anyEndStateReachable,
} from '../validations/workflowValidation';
import removeUnvisitedNodesAndConditions from './utils';
import {
  operateOnWorkflow,
  supportedWorkflowOperations,
  workflowOperations,
  workflowOperationsObj,
} from '.';
import { clubCssInFormV2, setDomIdsInFormV2 } from './operations/preOperations';
import { checkNodesGettingDeletedFromDynamicForm } from './warnings/form';
import { selectCustomUIConfig, selectDefaultUIConfig, updateCustomUiConfig } from '../reducers/editBranding';
import operateBulkUpdatesInWorkflow from './operateBulkUpdatesInWorkflow';
import HVError from '../utils/error';
import { maskData } from '../utils/helper';
import pushEvent from '../events/pushEvents';

const preProcessor = (workflow, preOperations) => preOperations.reduce((accumulator, currFn, index) => {
  const updatedWorkflow = currFn(accumulator);
  return updatedWorkflow;
}, workflow);

const validate = (workflow, validators) => {
  let code = null;
  const validationResults = {};
  let isValid = true;
  validators.forEach(({ code: errorCode, fn: validator }) => {
    const { isValid: result, extraData } = validator(workflow);
    if (extraData?.issues?.length > 0) {
      validationResults[errorCode] = extraData.issues;
    }
    isValid = isValid && result;
    if (!code && !isValid) code = errorCode;
  });
  return { isValid, code, validationResults: validationResults[code] };
};

const updateModuleNames = (workflow) => {
  const versionedModules = selectVersionedModules(getStateValue());
  return updateModuleNamesInWorkflow(workflow, versionedModules);
};

const updateLastModifiedTimestamp = (workflow) => {
  const clonedWorkflow = cloneDeep(workflow);
  const { moduleBuilder = {} } = clonedWorkflow?.properties || {};
  if (Object.keys(moduleBuilder).length > 0) {
    const updatedModuleBuilder = { ...moduleBuilder, updatedAt: new Date().toISOString() };
    clonedWorkflow.properties.moduleBuilder = updatedModuleBuilder;
  }
  return clonedWorkflow;
};

export const validateCountryModule = (workflow) => {
  const state = store.getState();
  const issues = [];
  const { countryIdNameMapping } = state.workflow;
  const isPresent = workflow.modules?.[0].type === 'countries';
  if (!isPresent) {
    issues.push('Country module is not present in the workflow.');
  }
  const isNotDuplicate =
    (workflow.modules?.filter((module) => module.type === 'countries') || []).length === 1;
  if (!isNotDuplicate) {
    issues.push('More than one country module found in the workflow.');
  }
  const { countriesSupported } = workflow.modules?.[0].properties || {};
  const allowedCountries = countriesSupported?.filter((country) => Boolean(countryIdNameMapping[country]));
  const notEmpty = allowedCountries?.length >= 1 || 0;
  if (!notEmpty) {
    issues.push('No valid countries supported in the workflow.');
  }
  const allCountriesSupported = allowedCountries?.length === countriesSupported?.length;
  if (!allCountriesSupported) {
    issues.push('Some countries are not supported in the countryIdNameMapping.');
  }
  return { isValid: !(issues.length > 0), extraData: { issues } };
};

export const validateWorkflow = (workflow) => {
  const validators = [
    { code: 'noEndStateReachable', fn: anyEndStateReachable },
    { code: 'nextStepNotPresentinDynamaicForm', fn: isNextStepPresentInDynamicForm },
    { code: 'nextStepOfNodePointingToSelf', fn: isNextStepOfNodeNotPointingToItSelf },
    { code: 'undefinedConditionOrModule', fn: isDeletedModulesOrConditionsPresentInWorkflow },
    // Commenting the below check because its not consistent as we are allowing terminal nodes
    // as next step from other modules
    // { code: 'noTerminalsInDynamicForm', fn: terminalNotAllowedInDynamicForm },
    { code: 'countryModuleNotCorrect', fn: validateCountryModule },
  ];
  return validate(workflow, validators);
};

const getFirstWarning = (oldWorkflow, updatedWorkflow, warnings, sourceType) => {
  if (!oldWorkflow?.modules?.length) return { showWarning: false, message: '' };
  let flagged = false;
  let warningMessage = '';
  warnings.some((warningFn) => {
    const { showWarning, message } = warningFn(oldWorkflow, updatedWorkflow, sourceType);
    if (showWarning) {
      flagged = true;
      warningMessage = message;
      return true;
    }
    return false;
  });
  return { showWarning: flagged, message: warningMessage };
};

const getUiConfig = (customUiConfig, defaultUiConfig) => (
  customUiConfig && Object.keys(customUiConfig).length > 0 ?
    customUiConfig :
    defaultUiConfig
);

const updateWorkflowInState = (updatedInputWorkflow, isEdited = true, action = { }) => {
  let workflow = updatedInputWorkflow;
  let extraData = {};
  let uiConfig = null;
  const { sourceType = null } = action || {};
  const oldWorkflow = selectSelectedWorkflow(getStateValue());
  const oldUiConfig = getUiConfig(
    selectCustomUIConfig(getStateValue()),
    selectDefaultUIConfig(getStateValue()),
  );

  let response = null;
  if (action?.operation === 'WORKFLOW_BULK_UPDATE') {
    response = operateBulkUpdatesInWorkflow(oldWorkflow, action, oldUiConfig);
  } else if (supportedWorkflowOperations.includes(action?.operation)) {
    response = operateOnWorkflow(oldWorkflow, action, oldUiConfig);
  }

  if (response) {
    const {
      workflow: updatedWorkflow,
      uiConfig: updatedUiConfig,
      success,
      extraData: data = {},
      errorData,
    } = response;
    extraData = data;

    if (!success) {
      if (errorData) {
        if (errorData instanceof HVError) {
          throw errorData;
        }
        throw new HVError(errorData);
      }
      // Done temporarily until all the operating functions are updated to send errorData
      return { success: false, extraData };
    }
    workflow = updatedWorkflow;
    uiConfig = updatedUiConfig;
  }

  const preOperations = [updateModuleNames, updateGotoTagsInWorkflow, removeUnvisitedNodesAndConditions, setDomIdsInFormV2, clubCssInFormV2, updateLastModifiedTimestamp];
  const warnings = [checkNodesGettingDeletedFromDynamicForm];
  const operatingWorkflow = cloneDeep(workflow);
  const processedWorkflow = preProcessor(operatingWorkflow, preOperations);
  const { isValid: isValidOperation, code, validationResults } = validateWorkflow(processedWorkflow);
  const { showWarning, message } = getFirstWarning(oldWorkflow, processedWorkflow, warnings, sourceType);
  if (isValidOperation) {
    // eslint-disable-next-line no-restricted-globals
    if (!showWarning || confirm(message)) {
      store.dispatch({
        type: 'workflow/updateSelectedWorkflow',
        payload: { workflow: processedWorkflow, isEdited },
      });
      if (uiConfig) store.dispatch(updateCustomUiConfig({ uiConfig }));
      return { success: true, extraData };
    }
  } else {
    let alertMessage = `Operation leading to invalid workflow\n${code || 'Something went wrong'}\nTerminated !!!\n`;
    if (validationResults) {
      alertMessage += `\n${validationResults.join('\n')}`;
    }
    alert(alertMessage);
  }
  // To be removed
  return { success: false, extraData };
};

const logWorkflowUpdates = ({
  action, startTime, endTime, response, err,
}) => {
  const { operation, actionData } = action || {};
  if (!workflowOperationsObj[operation]) return;

  const actionDataPathsToMask = workflowOperations[operation]?.maskingConfig?.actionDataPathsToMask || [];
  const maskedActionData = maskData(actionData, actionDataPathsToMask);

  const eventType = err ? `ERROR_WORKFLOW_OPERATION_${operation}` : `WORKFLOW_OPERATION_${operation}`;

  const properties = {
    operation,
    actionData: maskedActionData,
    duration: parseFloat((endTime - startTime).toFixed(2)),
  };

  if (err) {
    properties.response = {
      success: false,
      errorCode: err?.code,
      errorMessage: err?.message,
    };
  } else {
    const responseDataPathsToMask = workflowOperations[operation]?.maskingConfig?.responseDataPathsToMask || [];
    properties.response = maskData(response, responseDataPathsToMask);
  }

  pushEvent({ eventType, properties });
};

const loggedUpdateWorkflowInState = (updatedInputWorkflow, isEdited, action) => {
  const startTime = performance.now();
  try {
    const response = updateWorkflowInState(updatedInputWorkflow, isEdited, action);
    const endTime = performance.now();
    logWorkflowUpdates({
      action, startTime, endTime, response,
    });
    return response;
  } catch (err) {
    const endTime = performance.now();
    logWorkflowUpdates({
      action, startTime, endTime, err,
    });
    throw err;
  }
};

export { loggedUpdateWorkflowInState as updateWorkflowInState };
