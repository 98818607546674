import PropTypes from 'prop-types';
import { useState } from 'react';
import { Tooltip } from '@mui/material';
import ListFormModuleComponentsV2 from './ListFormModuleComponentsV2';
import addImg from '../../assests/icons/addIconButton.svg';
import pasteImg from '../../assests/icons/pasteIcon.svg';

function ListFormModuleV2(props) {
  const {
    componentLists,
    getComponentName,
    selectedComponentLocation,
    handleOnClick,
    handleOnDelete,
    handleOnCopy,
    handleOnAdd,
    handleOnDrag,
    handleOnPaste,
    handleOnClone,
  } = props;
  const {
    componentId: selectedComponentId,
    basePath: selectedComponentRootPath,
  } = selectedComponentLocation;
  const [openStates, setOpenStates] = useState({});
  const [dragState, setDragState] = useState({
    draggedComponentId: null,
    draggedOverComponentId: null,
    fromSection: null,
  });
  const toggleDropdown = (componentId) => {
    setOpenStates((prevState) => ({
      ...prevState,
      [componentId]: !prevState[componentId],
    }));
  };
  const [contextMenu, setContextMenu] = useState(null);
  const handleDragStateChange = (newDragState) => {
    setDragState((prevState) => ({
      ...prevState,
      ...newDragState,
    }));
  };
  return (
    <>
      {componentLists.map(({
        id: basePath,
        title,
        components,
        enableAddButton,
        enableCopyButton,
        enableDeleteButton,
        enablePasteButton,
        enableDrag,
      }) => (
        <>
          <div className="component-heading">
            <div className="component-list-div__heading">
              {title}
            </div>
            <div className="component-list__btnContainer">
              {
                enablePasteButton ? (
                  <button
                    type="button"
                    className="component-add-btn"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleOnPaste(basePath);
                    }}
                  >
                    <Tooltip
                      title={(
                        <span>
                          Paste Element
                        </span>
              )}
                      arrow
                    >
                      <img src={pasteImg} alt="paste" />
                    </Tooltip>
                  </button>
                ) : null

            }
              <button
                disabled={!enableAddButton}
                type="button"
                className="component-add-btn"
                onClick={(event) => {
                  event.stopPropagation();
                  handleOnAdd(null, basePath);
                }}
              >
                {' '}
                <Tooltip
                  title={(
                    <span>
                      Add Element
                    </span>
          )}
                  arrow
                >
                  <img src={addImg} alt="add" />
                </Tooltip>

              </button>
            </div>
          </div>
          <ListFormModuleComponentsV2
            formComponents={components}
            getComponentName={getComponentName}
            handleOnClick={(data) => handleOnClick(data, basePath)}
            handleOnDelete={(data) => handleOnDelete(data, basePath)}
            handleOnCopy={(data) => handleOnCopy(data, basePath)}
            handleOnClone={(data) => handleOnClone(data, basePath)}
            handleOnAdd={(data) => handleOnAdd(data, basePath)}
            handleOnDrag={(pickId, dropId) => handleOnDrag(pickId, dropId, basePath)}
            selectedComponentPath={
                        selectedComponentRootPath === basePath ? selectedComponentId : []
                      }
            enableCopyButton={enableCopyButton}
            enableDeleteButton={enableDeleteButton}
            enableAddButton={enableAddButton}
            enableDrag={enableDrag}
            dragState={dragState}
            onDragStateChange={handleDragStateChange}
            openStates={openStates}
            toggleDropdown={toggleDropdown}
            contextMenu={contextMenu}
            setContextMenu={setContextMenu}
            level={0}
            parentContainerInfo={{
              isInsideContainer: false,
              isContainerActive: false,
            }}
          />
        </>
      ))}
    </>
    // </div>
  );
}

export default ListFormModuleV2;

ListFormModuleV2.propTypes = {
  componentLists: PropTypes.array.isRequired,
  getComponentName: PropTypes.func.isRequired,
  selectedComponentLocation: PropTypes.object.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  handleOnDelete: PropTypes.func.isRequired,
  handleOnCopy: PropTypes.func.isRequired,
  handleOnAdd: PropTypes.func.isRequired,
  handleOnDrag: PropTypes.func.isRequired,
  handleOnPaste: PropTypes.func.isRequired,
  handleOnClone: PropTypes.func.isRequired,
};
