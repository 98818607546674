import { cloneDeep } from 'lodash';
import convertToNodesEdges, { getNextStepForModule } from '../components/utils';
import { eventHandlers, endStateKeys as endStates } from '../components/constants';
import { getAllFormComponents, getAllNextSteps } from '../containers/FormModule/helper';
import { fetchDependencyList } from '../utils/fetchDependencyList';
import DependencyError from '../error/dependencyError';
import DefaultError from '../error/defaultError';
import { errorCodes } from '../utils/error';

const removeUnvisitedNodesAndConditions = (workflow) => {
  const startNode = workflow?.modules[0]?.id;
  if (typeof startNode !== 'string') return workflow;
  const clonnedWorkflow = cloneDeep(workflow);
  const { nodes } = convertToNodesEdges(clonnedWorkflow);
  const visitedIds = (nodes || []).map((node) => node.id).filter((id) => !!id);
  // Removing conditions
  const allConditions = Object.keys(clonnedWorkflow.conditions || {});
  allConditions.forEach((conditionId) => {
    if (!visitedIds.includes(conditionId)) { delete clonnedWorkflow.conditions[conditionId]; }
  });
  // Removing nodes
  const newModuleList = clonnedWorkflow.modules.filter((module) => visitedIds.includes(module.id));
  clonnedWorkflow.modules = newModuleList;
  return clonnedWorkflow;
};

export const canDeleteNode = (id, workflow, nodeType, nodeToDelete) => {
  // Checking if node can be deleted ?
  const dependencies = fetchDependencyList(id, workflow);
  const hasDependencies = Object.values(dependencies).some((value) => value.length);
  if (hasDependencies) {
    return {
      success: false,
      errorData: new DependencyError(dependencies),
    };
  }

  if (
    nodeType === 'condition' &&
    !endStates.includes(nodeToDelete.if_false) &&
    !endStates.includes(nodeToDelete.if_true)
  ) {
    return {
      success: false,
      errorData: new DefaultError({
        code: errorCodes.deleteConditionFailedNoEndState,
        message: 'Failed to delete condition since none of the children are end states',
        originalError: new Error('Condition node is not in end state'),
        logToSentry: false,
      }),
    };
  }
  if (nodeType === 'module' && nodeToDelete.type === 'dynamicForm') {
    const allComponents = getAllFormComponents(nodeToDelete);
    const allNextSteps = getAllNextSteps(allComponents, eventHandlers);
    if (allNextSteps.length !== 1) {
      return {
        success: false,
        errorData: new DefaultError({
          code: errorCodes.deleteFormModuleFailedMoreThanOneBranches,
          message: 'Can not delete form module as it has more than one branches',
          originalError: new Error('Form module has more than one branches'),
          logToSentry: false,
        }),
      };
    }
  }
  if (nodeType === 'module' && nodeToDelete.type === 'dynamicFormV2') {
    const allNextSteps = getNextStepForModule(nodeToDelete);
    if (allNextSteps.length !== 1) {
      return {
        success: false,
        errorData: new DefaultError({
          code: errorCodes.deleteFormModuleFailedMoreThanOneBranches,
          message: 'Can not delete formV2 module as it has more than one branches',
          originalError: new Error('FormV2 module has more than one branches'),
          logToSentry: false,
        }),
      };
    }
  }
  return {
    workflow,
    success: true,
    errorData: null,
  };
};

export default removeUnvisitedNodesAndConditions;
