import PropTypes from 'prop-types';
import Modal from '../../Common/Modal';
import WarningIcon from '../../../assests/icons/warningIcon.svg';
import './UiActionModal.scss';

function UiActionModal(props) {
  const { uiActionForModuleUpdateFailure, onModalClose } = props;

  const hasDependencies = (dependencies) => Object.values(dependencies || {})
    .some((dependency) => dependency?.length > 0);

  const getUiActionModalContent = () => {
    switch (uiActionForModuleUpdateFailure.actionName) {
      case 'failure':
        return <div className="ui-action-modal__content">Something went wrong due to which the module update has failed!</div>;
      case 'dependencyModal':
        return (
          <div className="ui-action-modal__content">
            <div className="ui-action-modal__content__message">
              The following outputs are being used in your workflow,
              and have been updated in the new version of the module.
              Please remove the dependencies first and then update the module.
            </div>
            <ul className="ui-action-modal__content__dependencies">
              {
                uiActionForModuleUpdateFailure.data.map((output) => (
                  hasDependencies(output?.dependencies) ? (
                    <div key={output?.name}>
                      <div>
                        Output
                        {' '}
                        <b>
                          &quot;
                          {output?.name}
                          &quot;
                        </b>
                        {' '}
                        is used in the following places:
                      </div>
                      {
                      Object.entries(output?.dependencies || {}).map(([type, dependency]) => (
                        dependency?.length > 0 ? (
                          <li key={type} className="ui-action-modal__content__dependencies__item">
                            {dependency.map((dep) => <div key={dep}>{dep}</div>)}
                          </li>
                        )
                          : null
                      ))
                    }
                    </div>
                  )
                    : null
                ))
            }
            </ul>
          </div>
        );
      default:
        return null;
    }
  };

  const getUiActionModalHeaderContent = () => (
    <div className="ui-action-modal__header">
      <img className="ui-action-modal__header__warning-icon" src={WarningIcon} alt="Results API integration warning" />
      Version Update Failed!
    </div>
  );

  return (
    <Modal
      isOpen={uiActionForModuleUpdateFailure !== null}
      onClose={onModalClose}
      headerContent={getUiActionModalHeaderContent()}
      onSave={onModalClose}
      buttonText="Close"
    >
      {
          getUiActionModalContent()
      }
    </Modal>
  );
}

UiActionModal.propTypes = {
  uiActionForModuleUpdateFailure: PropTypes.object.isRequired,
  onModalClose: PropTypes.func.isRequired,
};

export default UiActionModal;
