import TYPE_TO_SUBTYPE_MAP from '../constants/typeToDefaultSubTypeMap';

const updateModuleSubtypes = (
  modules,
  versionedModules,
) => {
  const updatedModules = modules.map((module) => {
    const updatedModule = { ...module };
    // Check the conditions for updating the module
    const isValidSubtype = versionedModules[updatedModule.subType];
    const isMappingForTheSubtypePresent = TYPE_TO_SUBTYPE_MAP[updatedModule.type];

    const subtypeCheckFailureForSimpleModules = !updatedModule.superModuleType && !isValidSubtype
    && isMappingForTheSubtypePresent;

    const subtypeCheckFailureForSuperModules =
    updatedModule.superModuleType && !updatedModule.superModuleId
    && !isValidSubtype && isMappingForTheSubtypePresent;

    if (subtypeCheckFailureForSimpleModules || subtypeCheckFailureForSuperModules) {
      // Push the error item to the subtypeErrorItems array
      updatedModule.subType = TYPE_TO_SUBTYPE_MAP[updatedModule.type].subType;
      updatedModule.version = TYPE_TO_SUBTYPE_MAP[updatedModule.type].version;
    }
    return updatedModule;
  });
  return updatedModules;
};

export default updateModuleSubtypes;
