import { cloneDeep } from 'lodash';
import { getNextStepForModule, updateNextStepForModule } from '../../components/utils';

const getModulesIdWithGivenEndState = (modules, oldEndState) => modules.flatMap(
  (module) => getNextStepForModule(module)
    .filter((step) => step.nextStep === oldEndState)
    .map((step) => ({ moduleId: module.id, path: step.path })),
);
const changeEndState = (workflow, oldEndState, newEndState) => {
  const updatedWorkflow = cloneDeep(workflow);
  const { modules, conditions } = updatedWorkflow;
  const matchedModulesIds = getModulesIdWithGivenEndState(modules, oldEndState);
  let success = true;
  matchedModulesIds.forEach(({ moduleId, path }) => {
    const moduleIndex = modules.findIndex((mod) => mod.id === moduleId);
    if (moduleIndex !== -1) {
      const updatedModule = updateNextStepForModule(modules[moduleIndex], path, newEndState);
      updatedWorkflow.modules[moduleIndex] = updatedModule;
    } else {
      success = false;
    }
  });

  if (!success) {
    return { success: false, workflow: updatedWorkflow };
  }

  const updatedConditions = Object.entries(conditions).reduce((acc, [key, condition]) => {
    acc[key] = {
      ...condition,
      if_true: condition.if_true === oldEndState ? newEndState : condition.if_true,
      if_false: condition.if_false === oldEndState ? newEndState : condition.if_false,
    };
    return acc;
  }, {});
  updatedWorkflow.conditions = updatedConditions;

  return { success: true, workflow: updatedWorkflow };
};

export default changeEndState;
