import { cloneDeep } from 'lodash';
import { endStateKeys } from '../../constants';
import convertToNodesEdges, { isCountryPickerModule } from '../../utils';
import { createParentMap } from '../../../utils/gotoUtils';
import {
  detachGoto, hasGoto, checkForWhichNodeNotToAttachGoTo, attachGoto,
} from '../../../utils/updateGotoTags';

const checkForValidNextNodeType = (workflow) => {
  let workflowConfig = cloneDeep(workflow);

  const { conditions } = workflowConfig;
  Object.keys(conditions).forEach((conditionKey) => {
    const condition = conditions[conditionKey];

    if (condition.next_node_type?.if_true === 'goto') {
      if (endStateKeys.includes(condition.if_true)) {
        condition.next_node_type.if_true = '';
      }
    }

    if (condition.next_node_type?.if_false === 'goto') {
      if (endStateKeys.includes(condition.if_false)) {
        condition.next_node_type.if_false = '';
      }
    }
  });
  const { nodes: orderOfNodes } = convertToNodesEdges(workflowConfig);

  // Get nextSteps

  // Populating goto
  const parentMap = createParentMap(workflowConfig);

  const nodes2 = Object.keys(parentMap);
  nodes2.forEach((nodeId) => {
    const parents = parentMap[nodeId];
    // leave one and attach goto to all execpt for country as its parent is start
    // Node occuring first should be the real node
    // TODO: Ensure that this existing logic is should make the nodes goto
    // which occur in the deeper part of the tree
    const skipNodeIndex = checkForWhichNodeNotToAttachGoTo(orderOfNodes, parents);
    parents.forEach((parent) => {
      const { id, path } = parent;
      const isNotCountryModule = !isCountryPickerModule(nodeId);
      const isNotFirstNode = orderOfNodes[skipNodeIndex]?.id !== id;
      const doesNotHaveGoto = !hasGoto(workflowConfig, id, path);
      const isValidIndex = skipNodeIndex !== -1;

      if (isNotCountryModule && isNotFirstNode && doesNotHaveGoto && isValidIndex) {
        workflowConfig = attachGoto(workflowConfig, id, path);
      }
      if (orderOfNodes[skipNodeIndex]?.id === id && !doesNotHaveGoto) {
        workflowConfig = detachGoto(workflowConfig, id, path);
      }
    });
  });
  return { workflow: workflowConfig, isSuccess: true };
};
export default checkForValidNextNodeType;
