import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import ColorPicker from '../../../components/Branding/ColorPicker/ColorPicker';
import {
  selectAccentColorDisabledButtonKeys,
  selectAccentColorHoverButtonKeys,
  selectAccentColorKeys,
  selectCustomUIConfig,
  selectDefaultUIConfig,
  selectPrimaryColorKeys,
  selectPrimaryColorSubsetKeys,
  selectSdkBackgroundColorKeys,
} from '../../../reducers/editBranding';
import downIcon from '../../../assests/icons/dropDownIcon.svg';
import sideIcon from '../../../assests/icons/sideIcon.svg';
import { updateReloadSdk } from '../../../reducers/dynamicForm';
import { updateWorkflowInState } from '../../../workflowOperations/updateWorkflow';
import useApiHooks from '../../../hooks/api/useApiHooks';
import { workflowOperationsObj } from '../../../workflowOperations';
import FileUpload from '../../../components/Common/FileUpload';
import { handleOnLottieUpload } from '../helper';
import ErrorHandlerContext from '../../../context/ErrorHandlerContext';
import DebouncedJsonInput from '../../../components/FormModule/Common/DebouncedJsonInput';
import infoIcon from '../../../assests/icons/infoIcon.svg';

function EditSDKBranding() {
  const dispatch = useDispatch();

  const defaultUiConfig = useSelector(selectDefaultUIConfig);
  const primaryColorKeys = useSelector(selectPrimaryColorKeys);
  const primaryColorSubsetKeys = useSelector(selectPrimaryColorSubsetKeys);
  const accentColorDisabledButtonKeys = useSelector(selectAccentColorDisabledButtonKeys);
  const accentColorHoverButtonKeys = useSelector(selectAccentColorHoverButtonKeys);
  const accentColorKeys = useSelector(selectAccentColorKeys);
  const sdkBackgroundColorKeys = useSelector(selectSdkBackgroundColorKeys);
  const customUiConfig = useSelector(selectCustomUIConfig);
  const imageUrl = customUiConfig.logos?.clientLogo;
  const { customFontStylesheetUrls } = customUiConfig;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLottieDropdownOpen, setIsLottieDropdownOpen] = useState(false);
  const [isCustomFontsDropdownOpen, setIsCustomFontsDropdownOpen] = useState(false);
  const [lottieNames, setLottieNames] = useState({
    failure: '',
    success: '',
    loading: '',
  });
  const handleError = useContext(ErrorHandlerContext);
  // Function to toggle the dropdown
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  // TODO: we can define the sections configuration in a separate constant
  // to make it more maintainable
  const toggleLottieDropdown = () => {
    setIsLottieDropdownOpen(!isLottieDropdownOpen);
  };
  const toggleCustomFontsDropdown = () => {
    setIsCustomFontsDropdownOpen(!isCustomFontsDropdownOpen);
  };
  const handleCustomFontUrlsUpdate = (updatedValue) => {
    try {
      updateWorkflowInState({}, true, {
        operation: workflowOperationsObj.UPDATE_CUSTOM_FONTS_STYLESHEET_URLS_IN_UI_CONFIG,
        actionData: {
          customFontStylesheetUrls: updatedValue,
        },
      });
    } catch (error) {
      handleError(error);
    }
  };
  const { search } = useLocation();
  const workflowId = new URLSearchParams(search).get('id');

  const uiConfig = Object.keys(customUiConfig).length > 0 ? customUiConfig : defaultUiConfig;
  useEffect(() => {
    const extractFileName = (url) => {
      if (!url) return '';
      const parts = url.split('/');
      return parts[parts.length - 1] || '';
    };

    setLottieNames({
      failure: extractFileName(uiConfig.animation?.endStateFailure),
      success: extractFileName(uiConfig.animation?.endStateSuccess),
      loading: extractFileName(uiConfig.animation?.endStateProcessing),
    });
  }, [uiConfig.animation]);
  const [primaryColor, setPrimaryColor] = useState((() => {
    if (uiConfig.colors.primaryColor) return uiConfig.colors.primaryColor;
    const existingColorKey = primaryColorKeys.find((colorKey) => !!uiConfig.colors[colorKey]);
    return uiConfig.colors[existingColorKey];
  })());
  const [accentColor, setAccentColor] = useState((() => {
    const existingColorKey = accentColorKeys.find((colorKey) => !!uiConfig.colors[colorKey]);
    return uiConfig.colors.accentColor || uiConfig.colors[existingColorKey];
  })());

  const [sdkBackgroundColor, setSdkBackgroundColor] = useState((() => {
    const existingColorKey = sdkBackgroundColorKeys.find((colorKey) => !!uiConfig.colors[colorKey]);
    return uiConfig.colors.sdkBackgroundColor || uiConfig.colors[existingColorKey];
  })());

  const updateUiConfigColors = () => {
    try {
      updateWorkflowInState({}, true, {
        operation: workflowOperationsObj.UPDATE_COLORS_IN_UI_CONFIG,
        actionData: {
          primaryColor,
          accentColor,
          sdkBackgroundColor,
          primaryColorKeys,
          primaryColorSubsetKeys,
          accentColorDisabledButtonKeys,
          accentColorHoverButtonKeys,
          accentColorKeys,
          sdkBackgroundColorKeys,
        },
      });
    } catch (error) {
      handleError(error);
    }
  };

  const handleColorChange = (colorType, colorValue) => {
    if (colorType === 'primary') setPrimaryColor(colorValue);
    else if (colorType === 'accent') setAccentColor(colorValue);
    else if (colorType === 'background') setSdkBackgroundColor(colorValue);
  };

  const {
    getLogoUrl, uploadLogo, uploadIcon, uploadLottie,
  } = useApiHooks();

  // upload logo to s3 and get the url for ui config
  const updateLogo = async (logoBlob) => {
    try {
      if (logoBlob) {
        const uploadResponse = await uploadLogo(workflowId, logoBlob);
        let logoUrl = uploadResponse?.fileUrl;
        if (!logoUrl) {
          logoUrl = await getLogoUrl(workflowId);
        }
        if (!logoUrl) return null;
        updateWorkflowInState({}, true, {
          operation: workflowOperationsObj.UPDATE_LOGO,
          actionData: {
            logoUrl,
          },
        });
        dispatch(updateReloadSdk());
        return logoUrl;
      }
      updateWorkflowInState({}, true, {
        operation: workflowOperationsObj.UPDATE_LOGO,
        actionData: {
          logoUrl: '',
        },
      });
      dispatch(updateReloadSdk());
      return null;
    } catch (error) {
      handleError(error);
      return null;
    }
  };
  const updateIconImage = async (iconBlob, iconType) => {
    try {
      if (iconBlob) {
        const uploadResponse = await uploadIcon(workflowId, iconBlob);
        const iconUrl = uploadResponse?.fileUrl;
        if (!iconUrl) return null;

        const iconKey = iconType === 'back' ? 'backButtonIcon' : 'closeButtonIcon';
        updateWorkflowInState({}, true, {
          operation: workflowOperationsObj.UPDATE_ICON_IN_UI_CONFIG,
          actionData: {
            iconUrl,
            iconKey,
          },
        });
        dispatch(updateReloadSdk());
        return iconUrl;
      }
      const iconKey = iconType === 'back' ? 'icons.backButtonIcon.url' : 'icons.closeButtonIcon.url';
      updateWorkflowInState({}, true, {
        operation: workflowOperationsObj.UPDATE_ICON_IN_UI_CONFIG,
        actionData: {
          iconUrl: '',
          iconKey,
        },
      });
      dispatch(updateReloadSdk());
      return null;
    } catch (error) {
      handleError(error);
      return null;
    }
  };
  const updateLottie = async (lottieBlob, lottieType) => {
    try {
      let lottieUrl = '';
      if (lottieBlob) {
        const uploadResponse = await uploadLottie(workflowId, lottieBlob);
        lottieUrl = uploadResponse?.fileUrl;
      }
      const { success } = handleOnLottieUpload({
        lottieUrl, lottieType,
      });
      if (success) {
        dispatch(updateReloadSdk());
      }
      return null;
    } catch (error) {
      handleError(error);
      return null;
    }
  };

  useEffect(() => {
    updateUiConfigColors();
  }, [primaryColor, accentColor, sdkBackgroundColor]);

  return (
    <div className="edit-branding">
      <ColorPicker
        type="primary"
        name="Text Color"
        color={primaryColor}
        onChange={(colorType, colorValue) => {
          handleColorChange(colorType, colorValue);
        }}
      />
      <ColorPicker
        type="accent"
        name="Accent Color"
        color={accentColor}
        onChange={(colorType, colorValue) => {
          handleColorChange(colorType, colorValue);
        }}
      />
      <ColorPicker
        type="background"
        name="Background Color"
        color={sdkBackgroundColor}
        onChange={(colorType, colorValue) => {
          handleColorChange(colorType, colorValue);
        }}
      />
      <FileUpload
        type="logo"
        label="Logo"
        onUpload={updateLogo}
        initialPreview={imageUrl}
        acceptedFileTypes=".jpeg, .png, .jpg"
        uploadLabel="Upload File"
      />
      <div className="edit-properties-div__section">
        <button
          className="edit-properties-div__title"
          onClick={toggleDropdown}
          type="button"
        >
          Close Button and Back Button Customization
          {isDropdownOpen
            ? <img src={downIcon} alt="down" />
            : <img src={sideIcon} alt="side" />}
        </button>
        {/* Dropdown section */}
        {isDropdownOpen && (
          <div className="dropdown-section">
            <FileUpload
              type="icon"
              label="Close Button Image"
              onUpload={(iconBlob) => updateIconImage(iconBlob, 'close')}
              initialPreview={uiConfig.icons?.closeButtonIcon?.url}
              acceptedFileTypes=".jpeg, .png, .jpg"
              uploadLabel="Upload File"
            />
            <FileUpload
              type="icon"
              label="Back Button Image"
              onUpload={(iconBlob) => updateIconImage(iconBlob, 'back')}
              initialPreview={uiConfig.icons?.backButtonIcon?.url}
              acceptedFileTypes=".jpeg, .png, .jpg"
              uploadLabel="Upload File"
            />
          </div>
        )}
      </div>
      <div className="edit-properties-div__section">
        <button
          className="edit-properties-div__title"
          onClick={toggleLottieDropdown}
          type="button"
        >
          Lottie Customization
          {isLottieDropdownOpen
            ? <img src={downIcon} alt="down" />
            : <img src={sideIcon} alt="side" />}
        </button>
        {/* Dropdown section */}
        {isLottieDropdownOpen && (
          <div className="dropdown-section">
            <FileUpload
              type="lottie"
              label="End State Failure Lottie"
              onUpload={(lottieBlob) => updateLottie(lottieBlob, 'failure')}
              initialPreview={lottieNames.failure}
              acceptedFileTypes=".lottie"
              uploadLabel="Upload File"
            />
            <FileUpload
              type="lottie"
              label="End State Success Lottie"
              onUpload={(lottieBlob) => updateLottie(lottieBlob, 'success')}
              initialPreview={lottieNames.success}
              acceptedFileTypes=".lottie"
              uploadLabel="Upload File"
            />
            <FileUpload
              type="lottie"
              label="End State Processing Lottie"
              onUpload={(lottieBlob) => updateLottie(lottieBlob, 'loading')}
              initialPreview={lottieNames.loading}
              acceptedFileTypes=".lottie"
              uploadLabel="Upload File"
            />
          </div>
        )}
      </div>
      <div className="edit-properties-div__section">
        <button
          className="edit-properties-div__title"
          onClick={toggleCustomFontsDropdown}
          type="button"
        >
          <div className="edit-properties-div__title__container">
            Custom Fonts
            <Tooltip
              title={(
                <span>
                  Supports custom font stylesheets (e.g., [&quot;https://fonts.googleapis.com/...&quot;]). Works on webSDK and webcore for forms, APIs, and country picker. Not supported for native modules (Selfie/ID) on mobileSDK; it should be added as a client asset during integration
                </span>
              )}
              arrow
              className="import_curl_modal__drawer__heading_container__tooltip"
            >
              <img src={infoIcon} alt="info" />
            </Tooltip>
          </div>
          {isCustomFontsDropdownOpen
            ? <img src={downIcon} alt="down" />
            : <img src={sideIcon} alt="side" />}
        </button>
        {isCustomFontsDropdownOpen && (
          <div className="dropown-section">
            <DebouncedJsonInput
              formatAsJson
              onChange={handleCustomFontUrlsUpdate}
              defaultValue={customFontStylesheetUrls || []}
              readOnly={false}
              placeholder="Enter a JSON array"
              isDisabled={false}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default EditSDKBranding;
