import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { cloneDeep } from 'lodash';
import { useNavigate } from 'react-router-dom';
import TextInput from './TextInput';
import createWorkflowId from './helper';
import basicStandardFlow from './basicStandartWorkflow';
import useShowErrorAlert from '../../hooks/custom/useCustomAlert';
import AlertCode from '../../constants/alertCodes';
import CreateWorkflowButton from './CreateWorkflowButton';
import useApiHooks from '../../hooks/api/useApiHooks';
import closeIcon from '../../assests/icons/closeIcon.svg';

function CreateWorkflow({ currentAppId, moduleBuilderMode = false }) {
  const [showDrawer, setShowDrawer] = useState(false);
  const [workflowData, setWorkflowData] = useState({});
  const [enableUpload, setEnableUpload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const showErrorAlert = useShowErrorAlert();
  const { publishWorkflow } = useApiHooks();

  const handleTextChange = (value, key) => {
    setWorkflowData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleImportButton = () => {
    setWorkflowData({});
    setIsLoading(false);
    setShowDrawer((status) => !status);
  };

  const handleUploadButton = async () => {
    if (currentAppId
      && workflowData?.name?.length) {
      setIsLoading(true);
      const { name, description } = workflowData;
      const workflowId = createWorkflowId();
      const basicFlow = cloneDeep(basicStandardFlow);
      basicFlow.properties.name = name;
      basicFlow.properties.description = description || '-';
      if (moduleBuilderMode) {
        basicFlow.properties.moduleBuilder = {
          subType: workflowData?.moduleBuilderSubType,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        };
      }
      const status = await publishWorkflow(currentAppId, workflowId, basicFlow);
      setIsLoading(false);
      if (status.isSuccessful) {
        handleImportButton();
        navigate(`/view?id=${workflowId}`);
      } else {
        showErrorAlert({ error: status.error, message: AlertCode.error.errorCreatingWorkflow });
      }
    }
  };

  const gridLabelStyle = {
    padding: '0px',
    fontFamily: 'Inter',
    fontSize: '10px',
    color: 'rgba(5, 5, 82, 0.6)',
  };

  const gridItemStyle = {
    paddingTop: '4px',
    fontFamily: 'Inter',
    fontSize: '10px',
  };

  useEffect(() => {
    if (moduleBuilderMode) {
      setEnableUpload(Boolean(currentAppId
        && workflowData?.name?.length
        && workflowData?.moduleBuilderSubType?.length));
    } else {
      setEnableUpload(Boolean(currentAppId && workflowData?.name?.length));
    }
  }, [workflowData, currentAppId]);

  return (
    <>
      <div className="import_workflow__item">
        <button type="button" onClick={handleImportButton} className="import_workflow__item_button">
          Create Workflow
        </button>
      </div>
      {showDrawer
        && (
          <div className="create_workflow__drawer_background">
            <div className="create_workflow__drawer">
              <div className="create_workflow__drawer__heading_container">
                <div>
                  Workflow Details
                </div>
                <button type="button" className="edit_workflow__drawer__heading_container__close_button" onClick={handleImportButton}>
                  <img src={closeIcon} alt="close" />
                </button>
              </div>
              <div className="create_workflow__drawer__body">
                <TextInput
                  label="Name of Workflow"
                  placeholder="PayBook - Liveness + Facematch"
                  onChange={(value) => handleTextChange(value, 'name')}
                  gridItemStyle={gridItemStyle}
                  gridLabelStyle={gridLabelStyle}
                />
                <TextInput
                  label="Description (Optional)"
                  placeholder="Describe what is this workflow about"
                  onChange={(value) => handleTextChange(value, 'description')}
                  gridItemStyle={gridItemStyle}
                  gridLabelStyle={gridLabelStyle}
                />
                {moduleBuilderMode ? (
                  <TextInput
                    label="Sub Type"
                    placeholder="Describe the subtype of the module"
                    onChange={(value) => handleTextChange(value, 'moduleBuilderSubType')}
                    gridItemStyle={gridItemStyle}
                    gridLabelStyle={gridLabelStyle}
                  />
                ) : ''}
              </div>
              <div className="create_workflow__bottom_content">
                <div className="create_workflow__bottom_content__button">
                  <button type="button" onClick={handleImportButton} className="create_workflow__bottom_content__button__secondary_button">Cancel</button>
                  <CreateWorkflowButton
                    onButtonClick={handleUploadButton}
                    normalButtonText="Create Workflow"
                    loadingButtonText="Creating Workflow"
                    isLoading={isLoading}
                    buttonClassName="create_workflow__bottom_content__button__primary_button"
                    enableUpload={enableUpload}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
    </>
  );
}

export default CreateWorkflow;

CreateWorkflow.propTypes = {
  currentAppId: PropTypes.string.isRequired,
  moduleBuilderMode: PropTypes.bool.isRequired,
};
