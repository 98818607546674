import decompile from '../../../compilerDecompiler/decompiler';
import validateAndUpdateModulesSubtypes from './utils/validateAndUpdateModuleSubtypes';

const decompileWorkflow = (currentWorkflow, versionedModules) => {
  const { workflow: decompiledWorkflow, errors: decompilationErrorData } =
      decompile(currentWorkflow, versionedModules, true);
  const { workflow: newWorkflow, errorData: updatedErrorData } =
  validateAndUpdateModulesSubtypes(decompiledWorkflow, versionedModules);

  const errorData = [...updatedErrorData, ...decompilationErrorData];

  return {
    workflow: newWorkflow,
    errorData,
    isSuccess: true,
  };
};

export default decompileWorkflow;
