import { useState } from 'react';
import PropTypes from 'prop-types';
import CustomizableToolTip from '../Common/CustomizableToolTip';
import closeIcon from '../../assests/icons/closeIcon.svg';
import fileIcon from '../../assests/icons/fileIcon.svg';

const customStyles = {
  tooltip: {
    backgroundColor: 'rgba(34, 34, 34, 1)',
    color: 'white',
    padding: '2px 6px',
    fontSize: '12px',
    fontWeight: '500',
    borderRadius: '4px',
    boxShadow: '0px 8px 24px 0px rgba(0, 0, 0, 0.16)',
  },
  arrow: {
    backgroundColor: 'white',
    border: '1px solid #dadde9',
  },
};

function FileUpload({ title, onChange, onClose }) {
  const [fileName, setFileName] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const handleChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const contents = e.target.result;
        try {
          const jsonData = JSON.parse(contents);
          onChange(jsonData);
          setFileName(file.name);
          setErrorMessage(null);
        } catch (err) {
          setErrorMessage('Error while parsing the file. Try again or upload a different file.');
          setFileName(null);
        }
      };
      reader.readAsText(file);
      setFileName(file.name);
    }
  };
  const handleRemoveFile = () => {
    setFileName(null);
    setErrorMessage(null);
    onClose();
  };
  const uniqueId = `fileInput_${title.replace(/\s+/g, '_')}`;
  return (
    <>
      <div className={`import_workflow__file_upload_container${fileName ? '_clicked' : ''}`}>
        <div className={`${fileName ? '' : 'import_workflow__file_upload_container_inner'}`}>
          <div className="import_workflow__label">
            {title}
          </div>
          {!fileName ? (
            <div className="import_workflow__action_button">
              <label htmlFor={uniqueId} className="import_workflow__action_button__label">
                Select File
                <input
                  type="file"
                  id={uniqueId}
                  name={`${title}`}
                  onChange={handleChange}
                  accept="application/json"
                  aria-label={`Upload ${title}`}
                  style={{ display: 'none' }}
                />
              </label>
            </div>
          ) : (
            <div className="import_workflow__file_container">
              <div className="import_workflow__file_info_container">
                <img src={fileIcon} alt="file icon" className="file-icon" />
                <span className="import_workflow__file_info">{fileName}</span>
              </div>
              <CustomizableToolTip customStyles={customStyles} title="Remove File" placement="bottom-end">
                <button type="submit" onClick={handleRemoveFile} className="import_workflow__remove_file_button">
                  <img className="import_workflow__remove_file_icon" src={closeIcon} alt="close-icon" />
                </button>
              </CustomizableToolTip>

            </div>
          )}
        </div>
        {errorMessage && (
          <div className="import_workflow__error_message">
            {errorMessage}
          </div>
        )}
      </div>
    </>
  );
}
export default FileUpload;

FileUpload.propTypes = {
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
