import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { cloneDeep, set } from 'lodash';
import { useMemo, useContext } from 'react';
import {
  selectCustomUIConfig,
  selectDefaultUIConfig,
  selectSelectedModuleState,
  selectSelectedModuleSubType,
  selectSelectedScreenState,
  updateCustomTextConfig,
  updateFontStyleSheets,
  selectDefaultTextConfig,
  selectCustomTextConfig,
  selectSupportedFonts,
  selectSupportedFontWeights,
  selectSelectedLanguage,
  selectSelectedModuleType,
} from '../../reducers/editBranding';
import DebouncedTextEditor from '../../components/Branding/DebouncedTextEditor';
import ColorPicker from '../../components/Branding/ColorPicker/ColorPicker';
import { updateWorkflowInState } from '../../workflowOperations/updateWorkflow';
import FontPicker from '../../components/Branding/FontPicker';
import SingleSelectDropdown from '../../components/Common/SingleSelectDropdown';
import { workflowOperationsObj } from '../../workflowOperations';
import ErrorHandlerContext from '../../context/ErrorHandlerContext';

function EditIndividualScreens(props) {
  const { selectedElement, onElementUpdate } = props;
  const dispatch = useDispatch();
  const defaultUiConfig = useSelector(selectDefaultUIConfig);
  const customUiConfig = useSelector(selectCustomUIConfig);
  const defaultTextConfigs = useSelector(selectDefaultTextConfig);
  const customTextConfig = useSelector(selectCustomTextConfig);
  const supportedFonts = useSelector(selectSupportedFonts);
  const supportedFontWeights = useSelector(selectSupportedFontWeights);
  const selectedModuleSubType = useSelector(selectSelectedModuleSubType);
  const selectedLanguage = useSelector(selectSelectedLanguage);
  const selectedModuleType = useSelector(selectSelectedModuleType);
  const selectedModule = useSelector(selectSelectedModuleState);
  const selectedScreen = useSelector(selectSelectedScreenState);

  const handleError = useContext(ErrorHandlerContext);

  const defaultTextConfig = useMemo(() => defaultTextConfigs[`default_${selectedLanguage}_text_config`], [selectedLanguage]);
  const handleTextConfigChange = (textConfig) => {
    try {
      updateWorkflowInState({}, true, {
        operation: workflowOperationsObj.SET_WORKFLOW_ATTRIBUTE,
        actionData: {
          path: `properties.textConfigSource.${selectedLanguage}`,
          value: 'custom',
        },
      });
      dispatch(updateCustomTextConfig({ textConfig, language: selectedLanguage }));
    } catch (error) {
      handleError(error);
    }
  };

  const getUiConfig = () => (
    customUiConfig && Object.keys(customUiConfig).length > 0 ?
      customUiConfig :
      defaultUiConfig
  );

  const getTextConfig = () => (
    customTextConfig && Object.keys(customTextConfig).length > 0 ?
      customTextConfig :
      defaultTextConfig
  );

  const handleFontChange = (selectedFont) => {
    const url = process.env.REACT_APP_CUSTOM_FONT_URL.replace('<SELECTED_FONT>', selectedFont);
    const id = selectedElement.font;
    dispatch(updateFontStyleSheets({ fontStyleSheets: { [selectedFont]: url } }));
    try {
      updateWorkflowInState({}, true, {
        operation: workflowOperationsObj.UPDATE_FONT_IN_UI_CONFIG,
        actionData: {
          id,
          font: selectedFont,
        },
      });
    } catch (error) {
      handleError(error);
    }
  };

  const handleFontWeightChange = (selectedFontWeight) => {
    try {
      const id = selectedElement.fontWeight;
      updateWorkflowInState({}, true, {
        operation: workflowOperationsObj.UPDATE_FONT_WEIGHT_IN_UI_CONFIG,
        actionData: {
          id,
          fontWeight: selectedFontWeight,
        },
      });
    } catch (error) {
      handleError(error);
    }
  };

  const handleTextChange = (updatedText, element, moduleId, moduleSubType, moduleType) => {
    if (updatedText) {
      const editedTextConfig = cloneDeep(getTextConfig());
      const textConfigKey = element.textConfig;
      let updatedTextConfig;
      if (editedTextConfig[`${moduleId}_${moduleSubType}`]) {
        // If the specific module configuration exists, create the updated config based on it
        updatedTextConfig = {
          ...editedTextConfig[`${moduleId}_${moduleSubType}`],
          [textConfigKey]: updatedText.trim(),
        };
      } else if (moduleType === 'face') {
        updatedTextConfig = {
          ...editedTextConfig[moduleSubType],
          [textConfigKey]: updatedText.trim(),
        };
      } else {
        // Otherwise, create a new config with just the updated property
        updatedTextConfig = {
          [textConfigKey]: updatedText.trim(),
        };
      }
      // TODO: Remove this hardcoded behavior once the fix is taken up in SDK.
      const keyToSet = moduleType === 'face' ? moduleSubType : `${moduleId}_${moduleSubType}`;
      set(editedTextConfig, keyToSet, updatedTextConfig);
      handleTextConfigChange(editedTextConfig);
    }
  };

  const updateColor = (colorValue, color) => {
    try {
      const element = color.key;
      updateWorkflowInState({}, true, {
        operation: workflowOperationsObj.UPDATE_COLOR_IN_UI_CONFIG,
        actionData: {
          key: element,
          colorValue,
        },
      });
    } catch (error) {
      handleError(error);
    }
  };

  const getDefaultColor = (colorElement) => {
    if (colorElement && Object.keys(colorElement).length > 0) {
      const uiConfig = customUiConfig && Object.keys(customUiConfig).length > 0
        ? customUiConfig : defaultUiConfig;
      return uiConfig.colors[colorElement] || '#000000';
    }
    return '#000000';
  };

  const onSelectError = (error, selectedElementParam) => {
    const newBrandingElement = cloneDeep(selectedElementParam);
    newBrandingElement.textConfig = error;
    onElementUpdate(newBrandingElement);
  };

  const uiConfig = getUiConfig();
  const textConfig = getTextConfig();
  const {
    font: elementFont,
    fontWeight: elementFontWeight,
    type: elementType,
    textConfig: elementTextConfig,
    textConfigList: elementTextConfigList = [],
    colors: elementColors = [],
  } = selectedElement;

  const fontFamily = uiConfig.font?.[elementFont] || 'Inter';
  const fontWeight = uiConfig.fontWeight?.[elementFontWeight] || 'Normal';
  const text =
    textConfig?.[`${selectedModule}_${selectedModuleSubType}`]?.[elementTextConfig] ||
    textConfig?.[selectedModuleSubType]?.[elementTextConfig] ||
    textConfig?.[selectedModule]?.[elementTextConfig] ||
    defaultTextConfig[selectedModuleSubType]?.[elementTextConfig] || '';
  const errorList = useMemo(() => {
    if (!elementTextConfigList || !elementTextConfigList.length) return [];
    return elementTextConfigList
      ?.map((errorCode) => ({
        id: errorCode,
        value: errorCode,
        name: textConfig?.[selectedModuleSubType]?.[errorCode],
      }));
  }, [elementTextConfigList, selectedModuleSubType, textConfig]);
  if (elementType !== 'text' && elementType !== 'button' && elementType !== 'errorDropdown') return null;

  return (
    <div className="branding-properties-div">
      <div key={`${selectedModule}_${selectedScreen}_${elementTextConfig}`} className="branding-element">
        <div className="main-div">
          <div className="input-field">
            {
              (elementType === 'errorDropdown') && (
                <SingleSelectDropdown
                  items={errorList}
                  onElementSelect={(error) => onSelectError(error, selectedElement)}
                  defaultValue={elementTextConfig}
                />
              )
            }
            <div className="input-field__options">
              {(elementType === 'text' || elementFont) && (
                <FontPicker
                  supportedFontValues={supportedFonts}
                  fontValue={fontFamily}
                  onFontValueChange={handleFontChange}
                  pickerLabel="Font Style:"
                />
              )}
              {(elementType === 'text' || elementFontWeight) && (
                <FontPicker
                  supportedFontValues={supportedFontWeights}
                  fontValue={fontWeight}
                  onFontValueChange={handleFontWeightChange}
                  pickerLabel="Font Weight:"
                />
              )}
            </div>
            {(elementType === 'text' || elementTextConfig) && (
              <DebouncedTextEditor
                text={text}
                onTextChange={
                  (updatedText) => handleTextChange(
                    updatedText,
                    selectedElement,
                    selectedModule,
                    selectedModuleSubType,
                    selectedModuleType,
                  )
                }
              />
            )}
            {elementType === 'button' && elementColors.map((color) => (
              <ColorPicker
                key={color.name}
                name={color.name}
                color={getDefaultColor(color.key)}
                onChange={(_, colorValue) => updateColor(colorValue, color)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditIndividualScreens;

EditIndividualScreens.propTypes = {
  selectedElement: PropTypes.object.isRequired,
  onElementUpdate: PropTypes.func.isRequired,
};
