/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-param-reassign */
import { cloneDeep } from 'lodash';
import convertToNodesEdges, { isCountryPickerModule } from '../components/utils';
import { createParentMap } from './gotoUtils';

// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line arrow-body-style, no-unused-vars
export const attachGoto = (workflowConfig, id, path) => {
  if (workflowConfig.conditions[id]) {
    const existingNextNodeConfig = workflowConfig.conditions[id]?.next_node_type || {};
    workflowConfig.conditions[id].next_node_type = { ...existingNextNodeConfig, [path]: 'goto' };
  } else {
    const filteredModule = workflowConfig.modules?.filter((module) => module.id === id)[0];
    const existingNextNodeConfig = filteredModule.next_node_type || {};
    filteredModule.next_node_type = { ...existingNextNodeConfig, [path]: 'goto' };
  }
  return workflowConfig;
};
export const detachGoto = (workflowConfig, id, path) => {
  if (workflowConfig.conditions[id]) {
    const existingNextNodeConfig = workflowConfig.conditions[id]?.next_node_type || {};
    if (existingNextNodeConfig[path]) {
      existingNextNodeConfig[path] = '';
    }
    workflowConfig.conditions[id].next_node_type = { ...existingNextNodeConfig };
  } else {
    const filteredModule = workflowConfig.modules?.filter((module) => module.id === id)[0];
    if (filteredModule) {
      const existingNextNodeConfig = filteredModule.next_node_type || {};

      if (existingNextNodeConfig[path]) {
        existingNextNodeConfig[path] = '';
      }

      filteredModule.next_node_type = { ...existingNextNodeConfig };
    }
  }
  return workflowConfig;
};

export const hasGoto = (workflowConfig, id, path) => {
  if (workflowConfig.conditions[id]) {
    // Its an id of condition
    return workflowConfig.conditions[id]?.next_node_type?.[path] === 'goto';
  }
  // Its a module
  const filteredModule = workflowConfig.modules?.filter((module) => module.id === id)[0];
  return filteredModule?.next_node_type?.[path] === 'goto';
};

// TODO: Add tests and try removing this function for update pipeline, instead it should be
// called only once at the time of first decompilation.
export const checkForWhichNodeNotToAttachGoTo = (orderOfNodes, parents) => {
  let smallestIndex = -1;
  parents.forEach((node) => {
    const index = orderOfNodes.findIndex((parent) => parent.id === node.id);
    if (index !== -1 && (smallestIndex === -1 || index < smallestIndex)) {
      smallestIndex = index;
    }
  });
  return smallestIndex;
};
const updateGotoTagsInWorkflow = (workflow) => {
  // Update goto tags if they aren't present for some or all branches
  let workflowConfig = cloneDeep(workflow);
  const { nodes: orderOfNodes } = convertToNodesEdges(workflowConfig);

  // Get nextSteps

  // Populating goto
  const parentMap = createParentMap(workflowConfig);

  const nodes2 = Object.keys(parentMap);
  nodes2.forEach((nodeId) => {
    const parents = parentMap[nodeId];
    // leave one and attach goto to all execpt for country as its parent is start
    // Node occuring first should be the real node
    const havingGoto = parents.map(({ id, path }) => hasGoto(workflowConfig, id, path));
    const totalGotos = havingGoto.reduce((acc, curr) => acc + (curr ? 1 : 0), 0);
    const countryModuleHasMoreThanOneParent = isCountryPickerModule(nodeId)
    && parents.length > 0 ? true : false;
    if (totalGotos !== parents.length - 1 || countryModuleHasMoreThanOneParent) {
      // TODO: Ensure that this existing logic is should make the nodes goto
      // which occur in the deeper part of the tree
      const skipNodeIndex = checkForWhichNodeNotToAttachGoTo(orderOfNodes, parents);
      let attachGoTo = false;
      parents.forEach((parent) => {
        const { id, path } = parent;
        const isNotCountryModule = !isCountryPickerModule(nodeId);
        const isNotFirstNode = orderOfNodes[skipNodeIndex]?.id !== id;
        const doesNotHaveGoto = !hasGoto(workflowConfig, id, path);
        const isValidIndex = skipNodeIndex !== -1;

        if ((isNotCountryModule && isNotFirstNode && doesNotHaveGoto && isValidIndex)
          || attachGoTo) {
          workflowConfig = attachGoto(workflowConfig, id, path);
        }
        if (orderOfNodes[skipNodeIndex]?.id === id) {
          attachGoTo = true;
        }
      });
    }
  });
  return workflowConfig;
};

export default updateGotoTagsInWorkflow;
