const matchAndReplace = (inputString, key, value) => {
  try {
    const escapedKey = key.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    // Create a regex pattern to treat non-alphanumeric and non-underscore characters as boundaries
    const regex = new RegExp(`(?<=[^a-zA-Z0-9_])(${escapedKey})(?=[^a-zA-Z0-9_])`, 'g');
    // Replace all matches using replaceAll
    const replacedString = inputString.replaceAll(regex, value);
    return replacedString;
  } catch (error) {
    return inputString; // Return the input as-is if there's an error
  }
};

export default matchAndReplace;
